/* @flow */
import PropTypes from 'prop-types';

import React from 'react';
import Url from "@lib/base/Url";
// import pseudo from './pseudo';
// import { Base } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';
import { trackAction } from "@lib/base/tracker";

export class Link extends React.PureComponent {

	static propTypes = {
		onClick: PropTypes.func,
		bold: PropTypes.bool,
		exactly: PropTypes.bool,
		// inverted: React.PropTypes.bool,
		// pseudo: PropTypes.object,
		// --- use either to or href
		to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

		// --- ga
		gaCategory: PropTypes.string,
		gaAction: PropTypes.string,
		gaLabel: PropTypes.string,
		gaValue: PropTypes.any,

		gTagEnable: PropTypes.bool, // gtag_report_conversion

		adwordsLeadType: PropTypes.string, // adwords_lead_track
		adwordsLeadOptions: PropTypes.any,

		pixelOptions: PropTypes.any,
	}

	gaAction() {
		const { gaCategory , gaAction, gaLabel, gaValue, gTagEnable,
			adwordsLeadType, adwordsLeadOptions, pixelOptions }  = this.props

		// console.log("gaLog : ",gaCategory + " " + gaAction + " " + gaLabel)

		trackAction({ category:gaCategory, action:gaAction, label:gaLabel, value:gaValue, gTagEnable,
			adwordsLeadType, adwordsLeadOptions, pixelOptions
		})

// 		if(gaAction){
// 			const GA = require('react-ga').default
// 			GA.event({
// 				category: gaCategory,
// 				action: gaAction,
// 				label: gaLabel,
// 				...(gaValue ? { value: parseInt(gaValue) } : {})
// 			});
// 		}
//
// 		// gtag
// 		if(gTagEnable && typeof window != 'undefined' && window.gtag_report_conversion){
// 			window.gtag_report_conversion()
// 		}
//
// 		// adword lead
// 		if(adwordsLeadType && typeof window != 'undefined' && window.adwords_lead_track){
// //		lead_types = [
// //			'contactus',
// //			'phone',
// //			'facebook',
// //			'twitter',
// //			'instagram',
// //			'line',
// //		];
// 			window.adwords_lead_track(adwordsLeadType, adwordsLeadOptions)
// 		}
//
// 		// facebook pixel
// 		if(typeof window != 'undefined' && window.fbq){
// 			window.fbq('trackCustom', gaAction, {
// 				category: gaCategory,
// 				action: gaAction,
// 				label: gaLabel,
// 				...(gaValue ? { value: parseInt(gaValue) } : {})
// 			});
// 		}
	}

	render(){
		let { bold, exactly, to, href,
			gaCategory = "DEFAULT", gaAction = null, gaLabel = null, gaValue = null, gTagEnable = false,
			adwordsLeadType, adwordsLeadOptions = {},
			...props } = this.props

		const baseStyle = {
			// color: inverted ? rebass.inverted : rebass.link.color,
			...(bold), //&& rebass && rebass.link.bold),
			// ...(rebass && rebass.link.link),
			// ...(pseudo.hover), // && rebass && rebass.link.hover),
		};
		let linkProps = {
			style: baseStyle,
			className: 'Link',
			...props,
		};
		to = (to && to.trim() || href && href.trim() || '#')
		if (linkProps.target == '_blank' && !linkProps.rel) linkProps.rel = 'noopener noreferrer'
		let isExternalLink = global.EXPORT_MODE || (typeof to == 'string' && (to.includes('://') || to === '#'));
		if(to && typeof to == 'string' && to.indexOf && ( to.indexOf('tel:') == 0 || to.indexOf('mailto:') == 0)) isExternalLink = true
		if(to && typeof to == 'string' && to.indexOf && ( to.indexOf('/app/') != -1 )){
			linkProps.target = "_blank" //แก้ชั่วคราวให้ /app/ เปิด new tab
		}
		if(isExternalLink && Url){
			const baseUrl = Url.base("/")
			if(to.includes( baseUrl )) {
				to = "/" + to.replace(baseUrl, "")
				isExternalLink = false;
			}
		}
		// if (to === '#' && !linkProps.onClick) linkProps.onClick = () => false

		if(props.disabled){
			to = '#'
			linkProps.onClick = null
		}

		if (to === '#') to = 'javascript:void(0)' // eslint-disable-line


		const self = this
		if(gaAction || gTagEnable || adwordsLeadType){
			// override onclick
			if(linkProps.onClick){
				const _onClick = linkProps.onClick
				linkProps.onClick = (e) => {
					this.gaAction.bind(self)()
					return _onClick.bind(self)(e)
				}
			}else{
				linkProps.onClick = this.gaAction.bind(this)
			}
		}

		return isExternalLink ? (
			<a
				{...linkProps}
				href={to}
			/>
		) : (
			<RouterLink
				{...linkProps}
				to={to}
			/>
			/*

			 activeOnlyWhenExact={exactly}
			 activeStyle={rebass && rebass.link.active}
			*/
		);
	}
}

// Link.contextTypes = {
// 	rebass: PropTypes.object,
// };

export default Link
// exports.Link = pseudo(Link)
// export default pseudo(Link)
