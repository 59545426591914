/* @flow */
import PropTypes from 'prop-types';

import React from 'react';
import linksMessages from 'common/app/messages/linksMessages';
import { FormattedMessage } from 'react-intl';
import { Link, Toolbar, View, SequenceMap, Space } from 'browser/components';
//import { connect } from 'react-redux';


const styles = {
	toolbar: {
		flexWrap: 'wrap',
	},
};

const Header = ({ viewer }, context) => {
	return (
		<View>

		</View>
	);
};

Header.propTypes = {
	viewer: PropTypes.object,
	location: PropTypes.object,
};


Header.contextTypes = {
	location: PropTypes.object,
	// router: React.PropTypes.object,
};

//export default connect(state => ({
//  viewer: state.users.viewer,
//}))(Header);

export default Header;
