import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'browser/components';
import { connect } from 'react-redux';
import { Table } from 'recruitment/browser/components';

class History extends React.Component {

	componentDidMount() {
		// this.setState({ test: false })
	}

	render() {
		const { manager, updateResult } = this.props
		const { contact_list } = manager.department
		const caption = "รายชื่อผู้สมัคร";
		const header = [ 'ชื่อ - นามสกุล', 'ชื่อเล่น', 'ตำแหน่ง', 'เงินเดือนที่ต้องการ', 'resume', 'ความคิดเห็นจากการตอบคำถาม', 'ความคิดเห็นจากการสัมภาษณ์', 'หมายเหตุ' ];
		const data = contact_list[ 'history' ].map((each, i) => <tr key={i}
																																class={each[ 'warning_deathline' ] ? 'warning' : ''}>
			<td>{each[ 'fullname' ]}</td>
			<td>{each[ 'nickname' ]}</td>
			<td>{manager.department.info[ each[ 'code' ] ].position}</td>
			<td>{each[ 'salary' ]}</td>
			<td><a href={each[ 'resume' ]} target="_blank"><img src="/assets/recruitment/img/Adobe_PDF_file_icon_32x32.png"/></a>
			</td>
			<td>{each[ 'answer_opinion' ]}</td>
			<td>{each[ 'interview_opinion' ]}</td>
			<td>{each[ 'memo' ]}</td>
		</tr>)

		return <div id="history">
			<Table data={data} caption={caption} header={header}/>
		</div>
	}
}

export default connect(state => ({
	manager: state.recruitment.manager
}), { /* action1, action2 */ })(History);
