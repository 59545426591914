import Helmet from 'react-helmet';

/* @flow */
import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Title = ({ message, values }: any) => {
	if (typeof message === 'string') {
		return (
			<Helmet title={message}/>
		);
	}
	return (
		<FormattedMessage {...message} values={values}>
			{message => <Helmet title={message}/>}
		</FormattedMessage>
	);
};

Title.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
	]).isRequired,
	values: PropTypes.object,
};

export default Title;
