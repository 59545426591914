// export { default as users } from './users/reducer';
// export { default as auth } from './auth/reducer';
export { default as config } from './config/reducer';
export { default as device } from './device/reducer';
export { default as intl } from './intl/reducer';
export { default as themes } from './themes/reducer';


// TODO:
// export ui from './UIRedux'
// export modal from './ModalRedux'

// merge to root path
// export global from './GlobalRedux'
