import PropTypes from 'prop-types';
import React from 'react';

class ButtonLogout extends React.Component {

	static propTypes = {
		label: PropTypes.string,
	}

	render() {
		const { label, classname, handler } = this.props
		return <div class="logout">
			<button type="button" class={classname} onClick={handler}>{label}</button>
		</div>
	}
}

export default ButtonLogout;