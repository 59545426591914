import React from 'react';

import { manageActions } from 'recruitment/common/actions';

const { logout } = manageActions;
import { connect } from 'react-redux';

import { ButtonLogout } from 'recruitment/browser/components';
import ApplicationView from 'recruitment/browser/manage/ApplicationView';
import Interview from 'recruitment/browser/manage/Interview';
import Decision from 'recruitment/browser/manage/Decision';
import History from 'recruitment/browser/manage/History';

class Manager extends React.Component {

	constructor(props) {
		super(props)
		this.state = { mode: "debug" }
	}

	componentWillMount() {
		const { contact_list } = this.props.manager.department
		if (contact_list) {
			if (contact_list.application && contact_list.application.length > 0) {
				this.setState({ mode: "application" })
			} else if (contact_list.interview && contact_list.interview.length > 0) {
				this.setState({ mode: "interview" })
			} else if (contact_list.interview && contact_list.decision.length > 0) {
				this.setState({ mode: "decision" })
			} else if (contact_list.history && contact_list.history.length > 0) {
				this.setState({ mode: "history" })
			}
		}
	}

	buttonMode(mode, label) {
		const { contact_list } = this.props.manager.department
		if (contact_list) {
			const count = contact_list[ mode ] ? `(${contact_list[ mode ].length})` : null
			return <button disabled={this.state.mode == mode || !contact_list[ mode ]} onClick={() => {
				this.setState({ mode })
			}}>{
				label}{count}</button>
		}
	}

	menu() {
		const { mode } = this.state
		const { contact_list } = this.props.manager.department
		const onClickLogout = () => {
			this.props.logout()
		}
		return <div id="menu">
			{this.buttonMode("application", "พิจารณาใบสมัคร")}
			{this.buttonMode("interview", "ผลการสัมภาษณ์")}
			{this.buttonMode("decision", "คัดเลือกผู้สมัคร")}
			{this.buttonMode("history", "ดูข้อมูลเก่า")}
			<button type="button" onClick={onClickLogout}>ออกจากระบบ</button>
		</div>
	}

	switchMode() {
		const { manager } = this.props
		const { mode } = this.state
		switch (mode) {
			case "application":
				return <ApplicationView/>
			case "interview":
				return <Interview/>
			case "decision":
				return <Decision/>
			case "history":
				return <History/>
			default:
				const style = { color: 'white' }
				return <div style={style}>
					{JSON.stringify(manager.department)}
				</div>
		}
	}

	render() {
		return <div id="Manager">
			{this.menu()}
			{this.switchMode()}
		</div>
	}
}

export default connect(state => ({ manager: state.recruitment.manager }), { logout })(Manager);