// Merge all reducer from all module

export { default as app } from 'common/app/reducer';

export * from 'base/common/reducers';

export * from '../../common/reducers';

// --- [REDUCERS]
// --- [/REDUCERS]

