/* @flow weak */

import * as actions from './actions';

import { default as manage } from './manage/reducer';
// import { Map, List } from '/common/immutable';
// import {} from 'recruitment/common/helpers'
const reducer = (state, action) => {
//  console.log(action.type,state)
  switch (action.type) {
// --- [ACTION]
	case actions.FETCH_POSITION:
	  return state.set('position', null).set('error', null).setIn(['application', 'position'], '')
	case actions.FETCH_POSITION + '_SUCCESS':
	  return state.set('position', action.payload)
	case actions.FETCH_POSITION + '_ERROR':
	  return state.set('error', action.payload)
	case actions.UPDATE_FIELD:
	  return state.setIn(['application', action.payload.field], action.payload.value)
	case actions.UPDATE_ANSWER:
	  return state.setIn(['application', 'answers', action.payload.key], action.payload.value)
	case actions.SUBMIT_DATA + '_SUCCESS':
	  return state.set('submited', action.payload).set('error', null)
	case actions.SUBMIT_DATA + '_ERROR':
	  return state.set('error', action.payload)
	case actions.CLEAR_DATA:
	  return state.set('application', application).set('submited', null).set('error', null)
// --- [/ACTION]
  }
//  console.error('undefined case:'+action.type)
  return state;
};

import reduceReducers from 'reduce-reducers';
import State, {application} from './records/State';

// import { default as feature1Reducer } from './feature1/reducer';
// --- [SUB_REDUCER_IMPORT]
// --- [/SUB_REDUCER_IMPORT]

// Module root reducer = Merge reducer as sequence
export const recruitment = reduceReducers(
		(state = new State()) => state, // init state
		reducer,
// feature1Reducer
// --- [SUB_REDUCER]
		manage
// --- [/SUB_REDUCER]
		)
