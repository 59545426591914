import PropTypes from 'prop-types';
import React from 'react';

import { manageActions } from 'recruitment/common/actions';

const { changeAppointment, updateAppointment } = manageActions;

import { connect } from 'react-redux';

class Appointment extends React.Component {

	static propTypes = {
		code: PropTypes.string,
		index: PropTypes.number,
		zone: PropTypes.string
	}

	handleChange(code, index, event) {
		this.props.changeAppointment(code, index, event.target.value)
	}

	handleBlur(code, index, event) {
		const { contact, manager, zone, updateAppointment } = this.props
		const { data_row } = contact[ code ][ 'list' ][ zone ][ index ]
		updateAppointment(manager.login.google_id, code, data_row, event.target.value)
	}

	render() {
		const { code, zone, index, contact, step, manager, changeAppointment, updateAppointment } = this.props
		const { appoint_date } = contact[ code ][ 'list' ][ zone ][ index ]
		if(step){
			// console.log(step)
			return <input type="datetime-local"  step={step} value={appoint_date}
									onChange={this.handleChange.bind(this, code, index)}
									onBlur={this.handleBlur.bind(this, code, index)}/>
		}else{
			return <input type="datetime-local" value={appoint_date}
									onChange={this.handleChange.bind(this, code, index)}
									onBlur={this.handleBlur.bind(this, code, index)}/>
		}
		
	}

}

export default connect(state => ({
	contact: state.recruitment.manager.department.contact,
	manager: state.recruitment.manager,
}), { changeAppointment, updateAppointment })(Appointment);