import PropTypes from 'prop-types';
import React from 'react';

class WaitPlease extends React.Component {

  static propTypes = {
		label: PropTypes.string
	}

	render() {
    const { label } = this.props
		return <div class="content">
			<h2>กรุณารอสักครู่นะคะ</h2>
			{label}
    </div>
	}
}

export default WaitPlease;
