import { setPlatformDep } from 'common/helpers/platformDeps';


setPlatformDep('Dialog', async () => {

	import(/* webpackChunkName: "vex-dialog" */ 'vex-js/dist/css/vex.css')
	import(/* webpackChunkName: "vex-dialog" */ 'vex-js/dist/css/vex-theme-default.css')


	const vex = typeof window !== 'undefined' ? (await import(/* webpackChunkName: "vex-dialog" */ 'vex-js/src/vex')).default : null

	if (vex) {
		//!vex.dialog && vex.registerPlugin(require('vex-dialog/src/vex.dialog'))
		!vex.dialog && vex.registerPlugin((await import(/* webpackChunkName: "vex-dialog" */  'vex-dialog/dist/vex.dialog')).default)
		vex.defaultOptions.className = 'vex-theme-default';
	}
	return vex
})
