import './App.css';
import tracker from "@lib/base/tracker";
import * as themes from './themes';
import Footer from './Footer';
import Header from './Header';
import Helmet from 'react-helmet';

/* @flow */
import PropTypes from 'prop-types';

import React from 'react';
import favicon from 'common/app/favicon';
import start from 'common/app/start';
//import { Container } from 'browser/components';
//import { ThemeProvider } from 'common/components';

export { default as ExtraHeader } from './ExtraHeader'

import { connect } from 'react-redux';


// import { Miss } from 'react-router';
import NotFound from 'base/browser/notfound/NotFoundPage';

// Route
// import { default as AppRoute } from './Route'
import { default as BrowserRoute } from '_config/browser/Route'


// v4-alpha.getbootstrap.com/getting-started/introduction/#starter-template
const bootstrap4Metas: any = [
	{ charset: 'utf-8' },
	{
		name: 'viewport',
		content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
	},
	{
		'http-equiv': 'x-ua-compatible',
		content: 'ie=edge',
	},
];

tracker({ ANALYTIC_ID: 'UA-36726233-2' })

let App = ({ currentLocale, currentTheme }) => (
	<div>
		<Helmet
			title={"Lnw Recruit"}
			htmlAttributes={{ lang: currentLocale }}
			meta={[
				...bootstrap4Metas,
				{
					name: 'description',
					content: '',
				},
				...favicon.meta,
			]}
			link={[
				...favicon.link,
			]}
		/>
		{/*<Header/>*/}
		<BrowserRoute/>
	</div>
);

App.propTypes = {
	currentLocale: PropTypes.string.isRequired,
	currentTheme: PropTypes.string,
};

App = connect(state => ({
	currentLocale: state.intl.currentLocale,
	currentTheme: state.themes.currentTheme,
}))(App);

export default start(App);
