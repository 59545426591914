import React from 'react';

import {
	manageActions
}
	from 'recruitment/common/actions';

const { updateResult } = manageActions;

import { Table } from 'recruitment/browser/components';

import {
	connect
}
	from 'react-redux';

class Decision extends React.Component {
	constructor(props) {
		super(props)
		this.state = { decision: {}, sent: false }
	}

	componentWillMount() {
		const { manager } = this.props
		this.state
	}

	handleDecisionChange(code, row, event) {
		let decision = this.state.decision
		decision[ code + '#' + row ] = event.target.value
		this.setState({ decision })
	}

	handleSubmit() {
		const { updateResult, manager } = this.props
		if (confirm("ต้องการส่งข้อมูลการคัดเลือกใช่หรือไม่")) {
			updateResult(manager.login.google_id, JSON.stringify(this.state.decision))
			this.setState({ sent: true })
		}
	}

	submitArea(label) {
		const { decision, sent } = this.state
		let total_accept = 0
		let total_refuse = 0
		let total_next_time = 0
		let disable = true
		for (var i in decision) {
			if (decision[ i ] == 'รับเข้าทำงาน') {
				total_accept++
				disable = false
			} else if (decision[ i ] == 'ไม่รับเข้าทำงาน') {
				total_refuse++
				disable = false
			} else if (decision[ i ] == 'ไว้พิจารณาคราวหน้า') {
				total_next_time++
				disable = false
			}
		}
		return <div id="submit">
			<p>จำนวนที่รับเข้าทำงาน {total_accept} คน</p>
			<p>จำนวนที่ไม่รับเข้าทำงาน {total_refuse} คน</p>
			<p>จำนวนที่ไว้พิจารณาคราวหน้า {total_next_time} คน</p>
			<button disabled={disable} onClick={this.handleSubmit.bind(this)}>{label}{sent ? 'ซ้ำ' : ''}</button>
			<p>* แถวที่เน้นหมายถึงผู้สมัครที่ถึงกำหนดแจ้งผล</p>
		</div>
	}

	render() {
		const { manager, updateResult } = this.props
		const { contact_list } = manager.department
		const caption = "รายชื่อผู้สมัคร";
		const header = [ 'ชื่อ - นามสกุล', 'ชื่อเล่น', 'ตำแหน่ง', 'เงินเดือนที่ต้องการ', 'ความคิดเห็นจากการตอบคำถาม', 'ความคิดเห็นจากการสัมภาษณ์', 'หมายเหตุ', 'ผลการคัดเลือก' ];
		const data = contact_list[ 'decision' ].map((each, i) => <tr key={i}
																																 class={each[ 'warning_deathline' ] ? 'warning' : ''}>
			<td>{each[ 'fullname' ]}</td>
			<td>{each[ 'nickname' ]}</td>
			<td>{manager.department.info[ each[ 'code' ] ].position}</td>
			<td>{each[ 'salary' ]}</td>
			<td>{each[ 'answer_opinion' ]}</td>
			<td>{each[ 'interview_opinion' ]}</td>
			<td>{each[ 'memo' ]}</td>
			<td class="center"><select value={this.state.decision[ each[ 'code' ] + '#' + each[ 'row' ] ] || ""}
																 onChange={this.handleDecisionChange.bind(this, each[ 'code' ], each[ 'row' ])}>
				<option value="" disabled></option>
				<option value="รับเข้าทำงาน">รับเข้าทำงาน</option>
				<option value="ไม่รับเข้าทำงาน">ไม่รับเข้าทำงาน</option>
				<option value="ไว้พิจารณาคราวหน้า">ไว้พิจารณาคราวหน้า</option>
			</select>
			</td>
		</tr>)

		return <div id="decision">
			<Table data={data} caption={caption} header={header}/>
			{this.submitArea('ส่งผลการคัดเลือก')}
		</div>
	}
}

export default connect(state => ({ manager: state.recruitment.manager }), { updateResult })(Decision);