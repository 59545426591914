/* @flow weak */
import * as actions from './actions';
import { Map } from '@lib/base/immutable';

const fieldsReducer = (state = Map(), action) => {
	switch (action.type) {

		case actions.FIELDS_RESET_FIELDS: {
			const { path } = action.payload;
			return state.without(path);// state.deleteIn(path);
		}

		case actions.FIELDS_SET_FIELD: {
			const { path, value } = action.payload;
			return state.setIn(path, Map(value));
		}

		default:
			return state;
	}
};

export default fieldsReducer;
