/**
 *
 * ExtraHeader
 *
 */

import React from 'react';
// import { View } from 'browser/components'
// import {} from 'recruitment/common/helpers'
// import {} from 'recruitment/browser/components'


// Fix: Don't refresh css when page change

// TODO: build + min + merge file

const ExtraHeader = [
	// <link rel="stylesheet" href="/assets/styles/font.css"/>,
	// <link rel="stylesheet" href="/assets/styles/font-awesome.min.css"/>,
	// <link rel="stylesheet" href="/assets/styles/style.css"/>,
	// <link rel="stylesheet" href="/assets/styles/normalize.css"/>,
	// <link rel="stylesheet" href="/assets/styles/skeleton.css"/>,
	// <link rel="stylesheet" href="/assets/styles/animate.css"/>,
	// <link rel="stylesheet" href="/assets/styles/lnwbar-white2_style.min.css"/>,
	// <link rel="stylesheet" href="/assets/styles/main.css"/>,
	// <link rel="stylesheet" href="/assets/styles/editable.css"/>,
	// <link rel="stylesheet" href="/assets/styles/react.css"/>,
	<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js" type="text/javascript"/>,
	// <script src="/assets/scripts/lib/jquery.inview.min.js" type="text/javascript"/>,
	// <script src="/assets/scripts/ui-main.js" type="text/javascript"/>,
	<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"/>,
	<script type="text/javascript" dangerouslySetInnerHTML={{__html:`
		window._lckd = window._lckd || [];
		function _lck(){window._lckd.push(arguments);}
		_lck('config',[]);
	`}}>
	</script>,
	<script async src="https://www.lnwshop.com/system/application/modules/lnwshopweb/_js/cookie_policy.js?t=202206101703"></script>
]


export default ExtraHeader;
