/* @flow */
//NOTE: update ./$babel_transform.internals.js
// jxnblk.com/writing/posts/patterns-for-style-composition-in-react

export * from 'common/components';

// App components
export { default as Button } from './Button';
export { default as FieldError } from './FieldError';
export { default as Form } from './Form';
export { default as Image } from './Image';
export { default as Link } from './Link';
export { default as LoadingWithTimeout } from './Loading';
export { default as SwitchTheme } from './SwitchTheme';
export { default as Title } from './Title';
export { default as View } from './View';
export { default as Space } from './Space';
export { default as focus } from './focus';
export { default as Waypoint } from './Waypoint';

// export { default as fxbml } from './fxbml';

export { Helmet } from 'react-helmet';

export { ModalContainer, ModalDialog, ModalBackground } from '@lib/react-modal-dialog';

export { LazyLoad } from './LazyLoad';


// Rebass components
// github.com/jxnblk/rebass
// export {
//   Base,
//   Arrow,
//   Avatar,
//   Badge,
//   Banner,
//   Block,
//   Blockquote,
//   Breadcrumbs,
//   // Button,
//   ButtonCircle,
//   ButtonOutline,
//   Card,
//   CardImage,
//   Checkbox,
//   Close,
//   Container,
//   Divider,
//   Donut,
//   DotIndicator,
//   Drawer,
//   Dropdown,
//   DropdownMenu,
//   Embed,
//   Fixed,
//   Footer,
//   Heading,
//   HeadingLink,
//   Input,
//   InlineForm,
//   Label,
//   LinkBlock,
//   Media,
//   Menu,
//   Message,
//   NavItem,
//   Overlay,
//   PageHeader,
//   Panel,
//   PanelFooter,
//   PanelHeader,
//   Pre,
//   Progress,
//   Radio,
//   Rating,
//   Section,
//   SectionHeader,
//   Select,
//   SequenceMap,
//   SequenceMapStep,
//   Slider,
//   Space,
//   Stat,
//   Switch,
//   Table,
//   Text,
//   Textarea,
//   Toolbar,
//   Tooltip,
// } from 'rebass';

// Reflexbox components
// github.com/jxnblk/reflexbox
// export {
//   Reflex,
//   Flex,
//   Box,
//   Grid,
// } from 'reflexbox';

// TODO: replace
// Icon
// http://geomicons.com/
// https://github.com/jxnblk/react-geomicons
// export { default as Icon } from 'react-geomicons';
