/**
 *
 * Application
 *
 */

import React from 'react';
import styles from './Application.css';
import { View } from 'browser/components';
import { reactCommon } from 'common/helpers';
import { WaitPlease } from 'recruitment/browser/components';
// import {} from 'recruitment/common/helpers' import {} from
// 'recruitment/browser/components'
import { fetchPosition, updateField, updateAnswer, submitData, clearData } from 'recruitment/common/actions';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';

const GA = require('react-ga').default
// GA.event({
// 	category: gaCategory,
// 	action: gaAction,
// 	label: gaLabel
// });

class Application extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			warning: {
				phone: false,
				salary: false
			},
			focus: {
				fullname: false,
				nickname: false,
				email: false,
				telephone: false,
				salary: false
			},
			disableSubmit: false,
			agreepolicy: false
		}
	}

	componentWillMount() {
		this
			.props
			.fetchPosition()
	}

	renderSubmit(label) {
		const { data } = this.props
		if (data.position !== '') {
			return <button
				id="submitButton"
				onClick={this
					.handleSubmit
					.bind(this)}
				disabled={this.state.disableSubmit}>{this.state.disableSubmit ? "กำลังส่งข้อมูล..." : label}</button>
		}
	}

	renderMessageToApplicant(){
		const { position, data } = this.props

		let message = ""
		message += "ทางเราได้รับข้อมูลการสมัครของคุณ " + (data.fullname) + " ในตำแหน่งงาน " + (position[ data.position ].name) + " แล้ว มีรายละเอียดดังนี้<br/><br/>"
		message += (data.fullname) + "<br/>"
		message += (data.nickname) + " - " + (data.phone_number) + "<br/>"
		message += (data.e_mail) + "<br/><br/>"

		{position[ data.position ] && position[ data.position ].quiz.map((quiz, i) => {
			message += "Q" + (i+1) + " : " + (quiz) + "<br/>"
			message += "A : " + (data.answers[ i ] || '') + "<br/><br/>"
			return (message)
		})}

		message += "กรณีต้องการสอบถามข้อมูลเพิ่มเติมจาก Lnw Recruitment <br/>"
		message += "โทร : 089-240-0801 หรือ อีเมล : RECRUIT@LNW.CO.TH ได้ทุกวันจันทร์ - ศุกร์ เวลา 09.00 - 18.00 น. ยกเว้นวันหยุดนักขัตฤกษ์"

		return message
	}

	handleSubmit() {
		const { submitData, position, data } = this.props
		const error = this.checkSubmit(position, data)
		if (error) {
			alert(error)
		} else {
			this.setState({ disableSubmit: true })//prevent double click
			// console.log("pass")
			submitData(position[ data.position ].code, data, this.renderMessageToApplicant())
		}
	}

	checkSubmit(position, data) {
		for (var k in data) {
			if (data[ k ] === '') {
				return 'กรุณากรอกข้อมูลให้ครบถ้วน'
			}
		}

		for (var k in position[ data.position ].quiz) {
			if (!data.answers[ k ]) {
				return 'กรุณากรอกข้อมูลให้ครบถ้วน'
			}
		}

		if (!data.e_mail.includes("@"))
			return 'ข้อมูล e-mail ไม่ถูกต้อง'
		if (!data.e_mail.includes("."))
			return 'ข้อมูล e-mail ไม่ถูกต้อง'
		if (data.phone_number.length != 10)
			return 'ข้อมูลเบอร์โทรศัพท์ไม่ครบ 10 หลัก'
		if (!data.resume)
			return 'กรุณาแนบแฟ้มข้อมูล resume เป็น PDF'
		if (!data.resume.name)
			return 'กรุณาแนบแฟ้มข้อมูล resume เป็น PDF'
		if (!data.resume.name.includes('.pdf'))
			return 'แฟ้มข้อมูล resume ต้องเป็น pdf เท่านั้น'

		if (!this.state.agreepolicy){ //check checkbox agreepolicy
			return 'กรุณากดยินยอมและรับทราบ ก่อนส่งใบสมัคร'
		}

		return null
	}

	renderRequire(require) {
		if (require) {
			return <span class="requiredstar">*</span>
		} else {
			return null
		}
	}

	handleFocus(event, name) {
		this.setState({
			focus: {
				[ event ]: true
			}
		})
		console.log(event + ' focused');
	}

	handleBlur(event, name) {
		this.setState({
			focus: {
				[ event ]: false
			}
		})
		console.log(event + ' blured');
	}

	renderTextField(label, name, value, limit = 50, require = true) {
		return <div
			class={(this.props.data[ value ].length > 0 || this.state.focus[ name ])
				? "profile " + name + " focused"
				: "profile " + name}>
			<label for={name}>
				{label}
				{this.renderRequire(require)}</label>
			<input
				class="textbox"
				type="text"
				id={name}
				value={this.props.data[ value ]}
				onFocus={this
					.handleFocus
					.bind(this, name)}
				onBlur={this
					.handleBlur
					.bind(this, name)}
				onChange={this
					.handleText
					.bind(this, value, limit)}/></div>

	}

	handleText(target, limit, event) {
		event.preventDefault()
		const val = event.target.value
		if (val.length < limit) {
			this
				.props
				.updateField(target, val)
		} else {
			console.error('text overflow')
		}
	}

	renderEmailField(label, require = true) {
		const name = "email";
		return <div
			class={(this.props.data.e_mail.length > 0 || this.state.focus[ name ])
				? "profile " + name + " focused"
				: "profile " + name}>
			<label for={name}>
				{label}
				{this.renderRequire(require)}</label>
			<input
				class="textbox"
				id={name}
				value={this.props.data.e_mail}
				onFocus={this
					.handleFocus
					.bind(this, name)}
				onBlur={this
					.handleBlur
					.bind(this, name)}
				onChange={this
					.handleEmail
					.bind(this)}/>
		</div>
	}

	handleEmail(event) {
		if (event.target.value.split("@").length < 3) {
			this
				.props
				.updateField('e_mail', event.target.value)
		} else {
			console.error('multiple @')
		}
	}

	renderPhoneField(label, require = true) {
		const name = "telephone";
		return <div
			class={(this.props.data.phone_number.length > 0 || this.state.focus[ name ])
				? "profile " + name + " focused"
				: "profile " + name}>
			<label for={name}>
				{label}
				{this.renderRequire(require)}{this.state.warning.phone
				? <span class="required">เฉพาะตัวเลข 10 หลักเท่านั้น</span>
				: ''}</label>
			<input
				class="textbox"
				id={name}
				type="tel"
				value={this.props.data.phone_number}
				onFocus={this
					.handleFocus
					.bind(this, name)}
				onBlur={this
					.handleBlurPhone
					.bind(this, name)}
				onChange={this
					.handlePhone
					.bind(this)}/>
		</div>
	}

	handleBlurPhone(event, name) {
		if (this.props.data.phone_number.length != 10){
			this.setState({
				warning: {
					phone: true
				}
			})
			console.error('invalid phone length')
		}else{
			this.setState({
				focus: {
					[ event ]: false
				}
			})
			console.log(event + ' blured');
		}
	}

	handlePhone(event) {
		event.preventDefault()
		const { value } = event.target
		if (value < 999999999) {
			this
				.props
				.updateField('phone_number', value)
			this.setState({
				warning: {
					phone: false
				}
			})
		} else {
			this.setState({
				warning: {
					phone: true
				}
			})
			console.error('invalid phone number')
		}
	}

	renderNumberField(label, name, value, limit = 1000000, require = true) {
		return <div
			class={(this.props.data.phone_number.length > 0 || this.state.focus[ name ])
				? "profile " + name + " focused"
				: "profile " + name}>
			<label for={name}>{label}</label>
			<input
				class="textbox"
				id={name}
				value={this.props.data[ value ]}
				onFocus={this
					.handleFocus
					.bind(this, name)}
				onBlur={this
					.handleBlur
					.bind(this, name)}
				onChange={this
					.handelNumber
					.bind(this, value, limit)}/>{this.renderRequire(require)}{this.state.warning[ value ]
						? <div class="required">เฉพาะตัวเลขเท่านั้น</div>
						: ''}
		</div>
	}

	handelNumber(target, limit, event) {
		event.preventDefault()
		const { value } = event.target
		var update = {
			warning: {}
		}
		if (value < limit) {
			this
				.props
				.updateField(target, value)
			update[ 'warning' ][ target ] = false
		} else {
			update[ 'warning' ][ target ] = true
			console.error('value over limit')
		}
		this.setState(update)
	}

	renderResumeField(title, label, require = true) {
		const name = "resume";
		return <div class={name}>
			{/*<div class="title">{title}{this.renderRequire(require)}</div>*/}
			<div class="resumelabel">Resume / Portfolio</div>
			<label for={name}>
				<span id="resumefile">{label}{this.renderRequire(require)}</span>
				<input
					id={name}
					type="file"
					accept=".pdf"
					onChange={this
						.handleResume
						.bind(this)}/>
			</label>
			<div class="title">{title}</div>
		</div>
	}

	handleResume(event) {
		event.preventDefault()
		if(event.target.files[0].size<=17124224){
			this
				.props
				.updateField('resume', event.target.files[ 0 ])

			const el = document.getElementById("resumefile");
			el.innerHTML = event.target.files[ 0 ].name
		}else{
			alert("ขนาดไฟล์เกินกว่าที่กำหนดไว้")
		}
	}

	handleQuizFocus(key,event){
		const {position,data} = this.props
		let answerPosition = position[data.position].name
		// console.log(answerPosition,key)
		GA.event({
			category: "application",
			action: "answer#"+key,
			label: answerPosition
		});
	}

	handleAgreePolicy(e){
		this.setState({
			agreepolicy: e.target.checked
		}, () => {
			// console.log("This returned true or false", this.state.agreepolicy);
		});
	}

	renderQuiz(header) {
		const { position, data } = this.props
		if (data.position !== '') {
			const mapQuiz = position[ data.position ].quiz.map((quiz, i) => (
					<li class="quiz" key={i} order={i}>{this.renderRequire(true)}
						<p class="question">{quiz}</p>
						<Textarea
    					minRows={1}
							class={(data.answers[ i ] && data.answers[ i ].length > 0)
								? "answer focused"
								: "answer"}
							type="text"
							value={data.answers[ i ] || ''}
							placeholder="ตอบคำถามที่นี่"
							onFocus={this.handleQuizFocus.bind(this,i)}
							onChange={this.handleQuiz.bind(this, i)}/></li>
				))
			return <div class="wrapper answerquiz">
				<div id="quiz" job={position[ data.position ].code}>
					<h2 class="head"><div class="step">3</div>{header}</h2>
					<ol>{mapQuiz}</ol>
				</div>
				<div class="confirmarea">
					<input type="checkbox" id="agreepolicy" name="agreepolicy" onChange={this.handleAgreePolicy.bind(this)} />
					<div class="message">
						ยินยอมและรับทราบว่าการกรอกข้อมูลส่วนบุคคลลงในแบบฟอร์มนี้ ไว้ใช้เพื่อประกอบการพิจารณาการรับท่านเข้าทำงาน อนึ่ง ในกรณีที่ท่านไม่ผ่านการพิจารณารับเข้าทำงาน ข้อมูลในแบบฟอร์มนี้ จะถูกเก็บรักษาไว้อีกเป็นระยะเวลา 1 ปี เพื่อพิจารณาความเหมาะสมกับตำแหน่งงานอื่น หรือในกรณีที่ท่านผ่านการพิจารณารับเข้าทำงาน ข้อมูลในแบบฟอร์มนี้จะถูกเก็บรักษาไว้ตลอดระยะเวลาการเป็นพนักงาน/ลูกจ้าง และเก็บต่อเนื่องอีกเป็นระยะเวลา 10 ปี เพื่อกรณีต้องใช้เป็นหลักฐานประกอบการใช้สิทธิเรียกร้องตามกฎหมายพระราชบัญญัติคุ้มครองแรงงาน พ.ศ. ๒๕๔๑ และที่แก้ไขเพิ่มเติม
						<br /><br />หากไม่ประสงค์ให้บริษัทฯ เก็บข้อมูลส่วนบุคคลนี้ กรุณาติดต่อ Email : <a href="mailto:RECRUIT@LNW.CO.TH">RECRUIT@LNW.CO.TH</a>
					</div>
				</div>
				<div class="submitarea">
					{this.renderClear("ลบข้อมูล")}
					{this.renderSubmit("ส่งใบสมัคร")}
				</div>
			</div>
		}
	}

	handleQuiz(key, event) {
		event.preventDefault()
		this
			.props
			.updateAnswer(key, event.target.value)
	}

	renderClear(label) {
		const { data } = this.props
			return <button
				id="clear"
				onClick={this
					.handleClear
					.bind(this)}>{label}</button>
	}

	handleClear() {
		this
			.props
			.clearData()
	}

	renderPosition(label) {
		const { position, data } = this.props
		const mapPosition = position
			? position.map((each, i) => (
				<option value={i} key={i}>{each[ 'name' ]}</option>
			))
			: null
		const name = "selectPosition"
		return <div class={name}>
			<label for={name}>{label}</label>
			<select
				id={name}
				value={data.position}
				onChange={this
					.handlePosition
					.bind(this)}
				disabled={!position}>
				<option value='' disabled>{position
					? '===เลือกตำแหน่งที่สนใจ==='
					: '...กำลังค้นหาตำแหน่ง...'}</option>
				{mapPosition}
			</select>
		</div>
	}

	renderPositionButton(label) {
		const { position, data } = this.props
		const mapPosition = position
			? position.map((each, i) =>
				(<li class={"position " + (data.position !== '' && position[ data.position ].code == each[ 'code' ] ? 'active' : '')} job={each[ 'code' ]} title={each[ 'name' ]} value={i} key={i} onClick={this
					.handlePosition
					.bind(this)}
					disabled={!position}>
					{each[ 'name' ]}</li>)
			)
			: null
		const name = "selectPosition"

		return <div class={name}>
			{/* <label for={name}>{label}</label> */}
			<ul
				id={name}
				value={data.position}
				onChange={this
					.handlePosition
					.bind(this)}
				disabled={!position}>
				{ data.position !== '' ? <React.Fragment>
					<li class="position active" job={position[ data.position ].code} title={position[ data.position ].name}>{position[ data.position ].name}</li>
					<li class="position cancel" onClick={this.handleClearPosition.bind(this)}>แสดงตำแหน่งทั้งหมด &#9662;</li>
				</React.Fragment> : mapPosition}
			</ul>
		</div>
	}

	handleClearPosition() {

		let checkData = false
		if (this.props.data && this.props.data.answers && this.props.data.answers.length > 0){
			this.props.data.answers.map((each, k) => {
				if (each != ""){ //เช็คค่าเปล่า
					checkData = true
					return
				}
			})
		}

		if (checkData){
			if (confirm('เมื่อเปลี่ยนตำแหน่งสมัคร คำตอบในแบบทดสอบที่คุณกรอกจะถูกลบทิ้ง ยืนยันทำต่อหรือไม่ ?')){
				this.props.data.answers.map((each, k) => {
					this
						.props
						.updateAnswer(k, "")
				})
			} else { // ไม่ทำอะไร
				return false
			}
		}

		this
			.props
			.updateField('position', '')

		const scrollToTarget = ()=>{
			const target = document.getElementById("selectPosition");
			target.scrollIntoView({behavior:'smooth',block:'start'})
		}
		setTimeout(() => scrollToTarget(), 100);
	}

	handlePosition(event) {
		GA.event({
			category: "application",
			action: "Select position",
			label: this.props.position[event.target.value].name
		});

		this
			.props
			.updateField('position', event.target.value)

		const scrollToTarget = ()=>{
			const target = document.getElementById("quiz");
			target.scrollIntoView({behavior:'smooth',block:'start'})
		}
		setTimeout(() => scrollToTarget(), 100);
	}

	renderFeature() {
		return <div class="feature">
			<a href="https://blog.lnw.co.th/join-lnw/" target="_blank">ดูรายละเอียดตำแหน่งที่เปิดรับสมัคร</a>
		</div>
	}

	renderNoPosition(){
		return <div id="error" class="wrapper">
			<div class="content">
				<h2>ขออภัยค่ะ <img src="/assets/recruitment/icons/basketboy-hide.png"/></h2>
				<p>ขณะนี้ยังไม่มีตำแหน่งว่างที่เราเปิดรับสมัคร</p>
			</div>
		</div>
	}

	render() {
		const { position, data, error, submited, warning } = this.props

		let bodyApplication = 	<div></div>

		if (error) { // มี error
			if(error.includes('ไม่พบข้อมูลตำแหน่ง')){
				bodyApplication = this.renderNoPosition()
			}else{
				bodyApplication = <div id="error" class="wrapper">
					<div class="content">
						<h2>{error}</h2>
						{this.renderClear("ย้อนกลับ")}
					</div>
				</div>
			}
		}


		if (submited) { // กรอกฟอร์มสำเร็จแล้ว
			bodyApplication = <div id="submited" class="wrapper">
				<div class="content">
					<h2>เราได้รับข้อมูลการสมัครของคุณเรียบร้อยแล้ว</h2>
					<div dangerouslySetInnerHTML={{__html: submited}} /><br/>{this.renderClear("ย้อนกลับ")}
				</div>
			</div>
		}

		if(!data){ // ไม่มีข้อมูล
			bodyApplication = <div id="error">
				<div class="content">
					<WaitPlease/>
				</div>
			</div>
		}

		if (data && !submited && !error){ //แบบฟอร์มสมัคร + แบบทดสอบ
			bodyApplication = 	<React.Fragment>
				<div id="profile"
					 class={data.position !== ''
						 ? "wrapper fixed"
						 : "wrapper default"}>

					<div class="content">
						<div class="recruit_profile">
							<h2 class="head"><div class="step">1</div>กรอกประวัติ</h2>
							<div class="yourinfo">
								{this.renderTextField("ชื่อ-นามสกุล", 'fullname', 'fullname')}
								{this.renderTextField("ชื่อเล่น", 'nickname', 'nickname')}
							</div>
							<div class="yourcontact">
								{this.renderEmailField("E-mail")}
								{this.renderPhoneField("เบอร์มือถือ 10 หลัก")}
							</div>
							<div class="yourcareer">
								{this.renderTextField("เงินเดือนที่ต้องการ", 'salary', 'salary')}
								{this.renderResumeField("*อัพโหลดเฉพาะไฟล์ PDF จำนวน 1 ไฟล์เท่านั้น  (ขนาดไม่เกิน 15 mb)", "กดอัพโหลด Resume / Portfolio")}
							</div>
						</div>

						<div class="recruit_position">
							<h2 class="head"><div class="step">2</div>เลือกตำแหน่งที่สนใจ</h2>
							{/* {this.renderPosition("สนใจสมัครในตำแหน่ง")} */}
							{this.renderPositionButton()}
							{this.renderFeature()}
						</div>
					</div>
				</div>
				{this.renderQuiz("ทำแบบทดสอบ")}
			</React.Fragment>
		}

		return (
			<View className={styles.application}>
				<div class="recruit_front">
					<div class="header_bar">
						<nav class="wrapper flexbox">
							<a href="" class="logo"><img src="/assets/recruitment/img/lnw2.png"/></a>
							<a href="https://lnw.me/account/pdpa/privacy" target="_blank" class="nav nav_position nav_privacy">นโยบายคุ้มครองข้อมูล</a>
							<a href="https://blog.lnw.co.th/join-lnw/" target="_blank" class="nav nav_position">ตำแหน่งที่เปิดรับ</a>
						</nav>
						<div class="header_banner"></div>
					</div>
					{bodyApplication}
				</div>
			</View>
		);
	}
}

export default reactCommon({ server: { private: true} /*fix build bug*/ }, connect(state => ({
	position: state.recruitment.position,
	data: state.recruitment.application,
	error: state.recruitment.error,
	submited: state.recruitment.submited
}), { fetchPosition, updateField, updateAnswer, submitData, clearData })(Application));
