import React from 'react';
import { default as route } from '../../browser/Route'

// --- [ROUTE_IMPORT]
// --- [/ROUTE_IMPORT]
export default route;
//
// const Route = () => (
//   <View>
//     {/* <Module1 /> */}
//     {/* --- [ROUTE] */}
//     {/* --- [/ROUTE] */}
//   </View>
// );
//
// export default Route;
//
