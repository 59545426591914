import PropTypes from 'prop-types';
import React from 'react';

class Table extends React.Component {

	static propTypes = {
		data: PropTypes.array,
		caption: PropTypes.string,
		header: PropTypes.array,
	}

	render() {
		const { data, caption, header } = this.props
		const headerTag = header ? header.map((label, i) => <th key={i}>{label}</th>) : null
		return <table key={caption} class="manage">
			<caption>{caption}</caption>
			<thead>
			<tr>{headerTag}</tr>
			</thead>
			<tbody>{data}</tbody>
		</table>
	}
}

export default Table;