// https://reacttraining.com/react-router/
import React from 'react';
import { View } from 'browser/components';
import { Match, RouteSwitch } from 'common/components';

// Pages
import Application from 'recruitment/browser/_page/Application'
import Manage from 'recruitment/browser/_page/Manage'
import Viewer from 'recruitment/browser/_page/Viewer'

// --- [ROUTE_IMPORT]
// --- [/ROUTE_IMPORT]

const Route = () => {
  // const baseUrl = '/recruitment';
  const baseUrl = '';
  return (
    <RouteSwitch>
      {/* --- [ROUTE] */}
      {/* --- [/ROUTE] */}
      {/* <Match pattern={baseUrl} component={Page1} />
      <Match path={`${baseUrl}/page1`} component={Page1} /> */}
      <Match path='/manage/view/:index' component={Viewer}/>
      <Match path="/manage" component={Manage} />
      <Match path="/" component={Application} />
    </RouteSwitch>
  );
}

export default Route;
