/* @flow weak */


// import { FIREBASE_ON_AUTH } from '@lib/redux-firebase/actions';
import { combineReducers } from '@lib/base/redux';
import { routerReducer } from '@lib/redux-seamless-immutable';
import { fieldsReducer as fields } from '@lib/redux-fields';
// import { firebaseReducer as firebase } from '@lib/redux-firebase';
// import * as modules from '_config/common/reducers';

// --------------- Reducer

let reducersToUse = {
	fields,
	// firebase: firebaseReducer,

	routing: routerReducer,
	// ...modules,
};

//--------------------------------------------------------------------------------
//
// const resetStateOnSignOut = (reducer, initialState) => (state, action) => {
// 	// Reset app state on sign out, stackoverflow.com/q/35622588/233902.
// 	const userWasSignedOut =
// 		action.type === FIREBASE_ON_AUTH &&
// 		state.users.viewer && !action.payload.user;
// 	if (userWasSignedOut) {
// 		// Preserve state without sensitive data.
// 		state = {
// 			app: state.app,
// 			config: initialState.config || {},
// 			device: initialState.device || {},
// 			intl: initialState.intl || {},
// 		};
// 	}
// 	return reducer(state, action);
// };

const configureReducer = async (initialState: Object, mergeReducers = {}) => {
	// let modulesReduces = {}
	// if(process.env.IS_SERVER){
	// 	modulesReduces = require('_config/common/reducers')
	// }else{
	// const modulesReduces = await import(
	// 	/* webpackChunkName: "reducers" */
	// 	/* webpackPreload: true */
	// 	'_config/common/reducers'
	// )
	// }

	let modulesReduces = require('_config/common/reducers');
	if(modulesReduces.default){
		reducersToUse = { ...reducersToUse, ...modulesReduces.default, ...mergeReducers}
	}else{

		// reuse when hot reload
		reducersToUse = { ...reducersToUse, ...modulesReduces, ...mergeReducers}
	}

	let reducer = combineReducers(reducersToUse);

	reducer.initialState = initialState
	// The power of higher-order reducers, http://slides.com/omnidan/hor
	// reducer = resetStateOnSignOut(reducer, initialState);
	return reducer;
};

export default configureReducer;

