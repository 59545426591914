/* @flow weak */
import * as actions from './actions';
import { Record } from 'common/transit';

const State = Record({
	currentLocale: null,
	defaultLocale: null,
	initialNow: null,
	locales: null,
	messages: {},
}, 'intl');

const intlReducer = (state = new State(), action) => {
	switch (action.type) {

		case actions.SET_CURRENT_LOCALE: {
			const { locale } = action.payload;
			return state.set('currentLocale', locale);
		}

		default:
			return state;

	}
};

export default intlReducer;
