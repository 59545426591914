import React from 'react'
import PropTypes from 'prop-types';

import { Util } from 'common/helpers/Util'

export const loopFor = (from, to, render: (v, k) => any) => {
	let out = []
	for (let i = from; i <= to; i++) out[i] = render(i, i)
	return out;
}

export const loop = (object, render: (v, k) => any) => object && Util.map(object, render)

export const LoopDiv = ({ object, render, component, ...props }) => {
	if (!object) return null;
	const Component = component || 'div'
	return <div>{Util.map(object, (v, k) => <Component {...props}>{render(v, k)}</Component>)}</div>
}

LoopDiv.propTypes = {
	object: PropTypes.any.isRequired,
	render: PropTypes.func.isRequired,
	component: PropTypes.any,
}