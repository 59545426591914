import App from '_config/browser/App';

/* @flow */
import PropTypes from 'prop-types';

// import BrowserHistory from 'react-history/BrowserHistory';
import React from 'react';
import { connect, Provider as Redux } from '@lib/base/redux';
import { BrowserRouter } from 'react-router-dom';
// import { StaticRouter } from 'react-router';
import { setLocation } from 'common/app/actions';
import {Util} from "../../modules/lnwdropship/common/helpers";

// Track route location changed
class BrowserHistory extends React.Component {

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	unlisten = null

	componentDidCatch(error, info) {
		// Display fallback UI
		// this.setState({ hasError: true });
		// You can also log the error to an error reporting service
		console.error('React error', error, info)
		debug && debug(error, info)
	}

	componentWillMount() {
		history.set(this.context.router)
		// call update location when init
		this.updateLocation()
		this.unlisten = this.context.router.history.listen(() => {
			this.updateLocation()
		})

	}

	componentDidMount() {
		const self = this
		history.set(this.context.router)

		// if(typeof global.window == 'undefined') global.window = {
		// 	history: {}
		// }
		// global.router = currentHistory
		// if(window.history.pushState){
		//
		// 	// detect pushstate / replace state
		// 	var _wr = function(type) {
		// 		var orig = window.history[type];
		// 		return function() {
		// 			var rv = orig.apply(this, arguments);
		// 			var e = new Event(type);
		// 			e.arguments = arguments;
		// 			window.dispatchEvent(e);
		// 			return rv;
		// 		};
		// 	};
		// 	window.history.pushState = _wr('pushState'), window.history.replaceState = _wr('replaceState');
		//
		// 	window.addEventListener('replaceState', function(e) {
		// 		self.updateLocation()
		// 	});
		// 	window.addEventListener('pushState', function(e) {
		// 		// console.log(window.location, currentHistory.history.location, self.props)
		// 		// debugger
		// 		self.updateLocation()
		// 	});
		// }

		// if(!global.window.history.pushState) global.window.history.pushState = currentHistory.history.push
		// if(!global.window.history.replaceState) global.window.history.replaceState = currentHistory.history.replace

	}

	updateLocation() {
		const location = this.context.router.history.location
		// if (!this.props.inited)
		this.urlParams(location)
		// update query

		history._updateLocation(location)

		if (this.props.inited || location.pathname !== this.props.pathname || location.search !== this.props.search) {
			// this.props.dispatch(setLocation(location));
			setImmediate(() => {
				history.set(this.context.router)
				this.props.dispatch(setLocation(location));
			});
		}
	}

	urlParams(location) {
		const query_string = {};
		const query = location.search.substring(1);
		const vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=');
			// If first entry with this name
			if (typeof query_string[pair[0]] === 'undefined') {
				if(typeof pair[1] == 'undefined') continue;
				query_string[pair[0]] = decodeURIComponent(pair[1]);
				// If second entry with this name
			} else if (typeof query_string[pair[0]] === 'string') {
				const arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
				query_string[pair[0]] = arr;
				// If third or later entry with this name
			} else {
				query_string[pair[0]].push(decodeURIComponent(pair[1]));
			}
		}
		location.query = query_string
		return query_string;
	}

	componentWillUnmount() {
		this.unlisten && this.unlisten()
	}

	render() {
		return this.props.children
	}
}

let currentHistory = null
export const history = {
	set: (v) => {
		currentHistory = v || currentHistory
		global.currentHistory = currentHistory
	} ,
	get: () => history.getRoot().history,
	route: () => history.getRoot().route,
	location: () => history.getRoot().location,
	getRoot: () => currentHistory || global.currentHistory,
	_updateLocation: (location) => null, // console.log(location,'location'),
	push: (path, state, retainParams = false) => {
		// console.trace('push', path)
		const currentHistory = history.getRoot()
		if(!currentHistory) return console.error('currentHistory is null')
		if(retainParams){
			// state = { ...Util.urlParams(true), ...state}
			let params = Util.urlParams(true)
			params && Util.map(params, (v,k) => path = Util.urlParamUpdate(k,v, {replace: false}, path).url)
		}
		return currentHistory.history.push(path, state)
	},
	replace: (path, state, retainParams = false) => {
		// console.trace('replace', path)
		const currentHistory = history.getRoot()
		if(!currentHistory) return console.error('currentHistory is null')
		if(retainParams){
			const location = currentHistory.history.location
			// state = { ...Util.urlParams(true), ...state}
			let params = Util.urlParams(true)
			params && Util.map(params, (v,k) => path = Util.urlParamUpdate(k,v, {replace: false}, path).url)
			// retain hash
			if(location.hash && path.indexOf('#') == -1){
				path += location.hash
			}
			// console.log('replace path',path)
		}
		return currentHistory.history.replace(path, state)
	},
	reload: () => {
		const currentHistory = history.getRoot()
		if(!currentHistory) return console.error('currentHistory is null')
		const location = currentHistory.history.location
		// return currentHistory.history.go(0)
		let path = location.pathname
		let params = Util.urlParams(true)
		params && Util.map(params, (v,k) => path = Util.urlParamUpdate(k,v, {replace: false}, path).url)
		// retain hash
		if(location.hash && path.indexOf('#') == -1){
			path += location.hash
		}
		// console.log('reload', path)
		return currentHistory.history.replace(path)
	},
	getRouteParams: () => {
		const currentHistory = history.getRoot()
		if(!currentHistory) return console.error('currentHistory is null')
		// console.log(currentHistory.route.match, currentHistory.route.match.params,'params');
		return currentHistory.route.match.params
	},
	updateMatch: (match) => {
		// TODO: check multiple match
		// console.log(match, 'updateMatch');
		const currentHistory = history.getRoot()
		if(!currentHistory) return console.error('currentHistory is null') // bug <- Route Match
		return currentHistory.route.match = match
	}
}

global.router = history

//
// const Router = ({ dispatch, pathname }) => {
//   // baseurl
//   const CONST = require('common/helpers').CONST
//   const ROUTE_BASE_URL = CONST.ROUTE_BASE_URL ? CONST.ROUTE_BASE_URL.trimRight('/') : ''
//
//   return (
//     <BrowserRouter
//       key={pathname} // github.com/yahoo/react-intl/issues/234#issuecomment-163366518
//       basename={ROUTE_BASE_URL}
//     >
//       {/* inited={inited} */}
//       <BrowserHistory dispatch={dispatch}  pathname={pathname}>
//         {/* Not render when init path */}
//         { pathname != null ? <App key="app" /> : null }
//       </BrowserHistory>
//     </BrowserRouter>
//   );
// };
//
//
// const ConnectedRouter = connect(state => ({
//   pathname: state.app.location && state.app.location.pathname,
//   // inited: state.app.location && state.app.location.inited,
// }))(Router);

// // We needs such Root for vanilla hot reloading.
// const Root = ({ store }) => (
//   <Redux store={store}>
//     <ConnectedRouter />
//   </Redux>
// );


const Router = ({ dispatch, pathname, search, isDesktopTemplate }, { router }) => {
	let inited = false
	if (!pathname) {
		// first time = no pathname yet
		pathname = router.history.location.pathname
		search = router.history.location.search
		inited = true // init = dispatch setLocation
	}
	const key = "app";//isDesktopTemplate? "desktop" : "mobile"
	// const key = isDesktopTemplate? "desktop" : "mobile"
	// console.log('Router', pathname, search)
	// console.log('key',key)
	return (
		<BrowserHistory
			dispatch={dispatch}
			pathname={pathname}
			search={search}
			inited={inited}>
			{/* key={pathname} // github.com/yahoo/react-intl/issues/234#issuecomment-163366518 */}
			{/* Not render when init path */}
			{pathname != null ? <App key={key} pathname={pathname} search={search}/> : null}
		</BrowserHistory>
	);
};

Router.contextTypes = {
	router: PropTypes.object.isRequired,
};


const ConnectedRouter = connect(state => ({
	// pathname: state.app.location && state.app.location.pathname ,
	pathname: (state.app.location && state.app.location.pathname) ||
		(typeof window != 'undefined' && window.location.pathname),
	search: state.app.location && state.app.location.search,
	isDesktopTemplate:  state.app.global.ui ? state.app.global.ui.isDesktopTemplate : UI.isDesktopTemplate()
}))(Router);

// We needs such Root for vanilla hot reloading.
const Root = ({ store }) => {
	// baseurl
	const CONST = require('common/helpers/CONST').default
	const ROUTE_BASE_URL = CONST.ROUTE_BASE_URL ? CONST.ROUTE_BASE_URL.trimRight('/') : ''

	return (
		<Redux store={store}>
			<BrowserRouter basename={ROUTE_BASE_URL}>
				<ConnectedRouter/>
			</BrowserRouter>
		</Redux>
	)
};

export default Root;
