/* @flow */

// TODO: merge config with _config
// const { _config = {} } = require('../../modules/_config/common');

const config = {
	// appName: require('../../package.json').name + (process.env.MAIN_MODULE ? ("_" + process.env.MAIN_MODULE):""),
	appName:(process.env.MAIN_MODULE ? ("_" + process.env.MAIN_MODULE):""),
	// Use appVersion defined in gulp env task or Heroku dyno metadata.
	appVersion: process.env.appVersion || process.env.HEROKU_SLUG_COMMIT, // (build size) || require('../../package.json').version,
	defaultLocale: 'en',
	// firebase: {
	//   // To get the config, just click Add web app from the overview page.
	//   apiKey: "AIzaSyAZwQr-pnJQaGWZycSXriEvl79q3zw_0dA",
	//   authDomain: "test-76b1d.firebaseapp.com",
	//   databaseURL: "https://test-76b1d.firebaseio.com",
	//   storageBucket: "test-76b1d.appspot.com",
	//   messagingSenderId: "716576520463"
	// },
	// googleAnalyticsId: 'UA-XXXXXXX-X',
	isProduction: process.env.NODE_ENV === 'production',
	// locales: ['cs', 'de', 'en', 'es', 'fr', 'pt', 'ro'],
	locales: ['en'],
	port: global.PORT || process.env.PORT || 3000,
	// Enable hot reload on remote device. Note it prevents offline testing,
	// because it depends on ip.address(), which doesn't work with disabled wifi.
	// How do we access a website running on localhost from mobile browser?
	// stackoverflow.com/questions/3132105
	remoteHotReload: true,
	// sentryUrl: 'https://f70008e3d76441acb0d3af89af71f2e8@sentry.io/131945',
	// ..._config
}



export default config
//
// // www.andrewsouthpaw.com/2015/02/08/environment-variables/
// import nconf from '@lib/nconf/nconf.js';
//
// // Use less-terrible separator character, stackoverflow.com/questions/25017495
// nconf.env('__');
//
// // For local development, we can override defaults easily. Rename
// // src/common/_config.json to src/common/config.json and uncomment next line.
// // nconf.file('src/common/config.json');
//
// // Remember, never put secrets in the source code. Use environment variables for
// // production or src/common/config.json for development instead.
// nconf.defaults(config);
//
// export default nconf.get();
