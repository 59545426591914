import { getPlatformDep } from "common/helpers/platformDeps";
import {CONST} from "common/helpers";

export const trackAction = ({ category = "DEFAULT", action = null, label = null, value = null, gTagEnable = false,
																adwordsLeadType = null, adwordsLeadOptions = {}, pixelOptions = {}, gtagOptions = null,
															gtagConfigRun = false }) => {
	if(!isBrowser()) return;
	if(isLocalhost()) return; //เพิ่ม 16/2/2023 localhost ไม่ต้อง track event
	// debug('trackAction', arguments[0])
	// console.log('trackAction', arguments[0])

	if(gtagOptions && typeof window != 'undefined' && window.gtag){
		if(gtagConfigRun && window.gtag_config_run){
			window.gtag_config_run(gtagConfigRun)
		}
		// debugger
		if(gtagOptions[2] && gtagOptions[2].items){
			// ga4 cat format
			gtagOptions[2].items.map((item, k) => {
				//gtagOptions.items[k].item_category
				if(item.item_category && !item.item_category2){
					let cats = item.item_category.split("/")
					cats.map((cat, j) => {
						let key = "item_category" + (j==0?"":(j+1))
						gtagOptions[2].items[k][key] = cat
					})
				}
			})

		}

		window.gtag.apply(window, gtagOptions)
	}

	if(action){
		const GA = require('react-ga').default
		GA.event({
			category: category,
			action: action,
			label: label,
			// nonInteraction => fix bounce rate
			...(value ? { value: parseFloat(value) } : {})
		}, GA._defaultTrackers || global.GADefaultTrackers);

		if (CONST.LNWX_VERSION_INFO == 'demopremium'){ //สำหรับ test custom event ga4
			//using gtag for support ga4
			global.gtag && global.gtag('event', action, {
				'event_category': category,
				'event_label': label,
				'value': value,
				'currency': 'THB'
			});
		}

	}

	// facebook pixel
	if(action && typeof window != 'undefined' && window.fbq){
		let { isCustom, ...opts } = pixelOptions
		if(opts.content_ids && opts.content_ids.length === 1){
			// https://developers.facebook.com/docs/facebook-pixel/implementation/dynamic-ads/#content-ids
			// content ids bug
			opts.content_ids = opts.content_ids[0]
		}
		if(isCustom || typeof pixelOptions.isCustom == 'undefined'){// isCustom = true or not defined (default)
			window.fbq('trackCustom', action, { // custom event
				category: category,
				action: action,
				label: label,
				...(value ? { value: parseFloat(value) } : {}),
				...opts
			});
		}else{ // isCustom = false
			window.fbq('track', action, {
				...(value ? { value: parseFloat(value) } : {}),
				...opts
			});
		}

	}

	// adword lead
	if(adwordsLeadType && typeof window != 'undefined' && window.adwords_lead_track){
//		lead_types = [
//			'contactus',
//			'phone',
//			'facebook',
//			'twitter',
//			'instagram',
//			'line',
//		];
		window.adwords_lead_track(adwordsLeadType, adwordsLeadOptions)
	}

	// gtag
	if(gTagEnable && typeof window != 'undefined' && window.gtag_report_conversion){
		window.gtag_report_conversion()
	}

	//https://ads.tiktok.com/help/article?aid=10028
	if(action && window.ttq){

		let opt = {}

		if(gtagOptions && gtagOptions[2] && gtagOptions[2].items){
			//https://ads.tiktok.com/marketing_api/docs?rid=5ipocbxyw8v&id=1701890973258754
			// ga4 cat format
			opt.contents = []
			gtagOptions[2].items.map((item, k) => {
				//gtagOptions.items[k].item_category
				opt.contents.push({
					content_id: item.id,
					content_type: 'product',
					content_name: item.name,
					quantity: item.quantity,
					price: item.price,
				});
			})
			if(gtagOptions[2].value){
				opt.value = gtagOptions[2].value
			}
			if(gtagOptions[2].currency){
				opt.currency = gtagOptions[2].currency
			}
		}

		window.ttq.track(action,{
			...(value ? { value: parseFloat(value) } : {}),
			...opt
		})

		// ttq.track('AddToCart', {
		// 	contents: [
		// 		{
		// 			content_id: '301',
		// 			content_type: 'product',
		// 			content_name: 'dress',
		// 			quantity: 1,
		// 			price: 8,
		// 		},
		// 		{
		// 			content_id: '401',
		// 			content_type: 'product',
		// 			content_name: 'dress',
		// 			quantity: 1,
		// 			price: 1.2,
		// 		}],
		// 	value: 9.2,
		// 	currency: 'USD',
		// });
	}

	if(action && window.LINE_TAG_ID && window._lt){
		window._lt('send', 'cv', {
			type: action
		}, [ window.LINE_TAG_ID ])
	}



}


// init tracker
export default ({ ANALYTIC_ID, GTAGS, GTM_ID, PIXEL_ID, SENTRY_URL, ADWORDS_LEADS, GTAG_LINKER_DOMAINS, LNWSTAT, LINE_TAG_ID, TIKTOK_PIXEL_ID }) => {
	if(isBrowser() && !isLocalhost()){ //!isLocalhost() เพิ่งเพิ่ม 16/2/2023 ไม่ต้อง track ใน localhost

		let cookie_consent_enable = false
		let cookie_consent = {
			security_storage: true,
			functionality_storage: true,
			analytics_storage: true,
			personalization_storage: true,
			ad_storage: true,
		}

		// cookie consent
		if(CONST.COOKIE_CONSENT){
			cookie_consent_enable = true

			window._lckd = window._lckd || [];
			window._lck = function(){ window._lckd.push(arguments); }

			// ga / pixel
			window.dataLayer = window.dataLayer || [];
			window.gtag = function(){dataLayer.push(arguments);}
			gtag('consent', 'default', {
				'analytics_storage': 'granted',
				'ad_storage': 'granted',
				'wait_for_update': 500
			});


			cookie_consent = {
				security_storage: true,
				functionality_storage: true,
				analytics_storage: true,
				personalization_storage: true,
				ad_storage: true,
			}

			// read cookie
			if(CONST.COOKIE_CONSENT.config){
				let cookie_key = '_lnwck_' + CONST.COOKIE_CONSENT.config.web_ukey
				function getCookie(cname) {
					let name = cname + "=";
					let decodedCookie = decodeURIComponent(document.cookie);
					let ca = decodedCookie.split(';');
					for(let i = 0; i <ca.length; i++) {
						let c = ca[i];
						while (c.charAt(0) == ' ') {
							c = c.substring(1);
						}
						if (c.indexOf(name) == 0) {
							return c.substring(name.length, c.length);
						}
					}
					return "";
				}
				let tmp = getCookie(cookie_key)
				if(tmp !== '') {
					tmp = window.atob(tmp);
					var match = tmp.match(/^se(0|1)fu(0|1)an(0|1)pe(0|1)ad(0|1)$/);
					if(match) {
						cookie_consent = {
							security_storage: (match[1]=='1'),
							functionality_storage: (match[2]=='1'),
							analytics_storage: (match[3]=='1'),
							personalization_storage: (match[4]=='1'),
							ad_storage: (match[5]=='1'),
						}
					}
				}
			}
		}

// google analytic
		if(ANALYTIC_ID) {
			const GA = require('react-ga').default
			const ga = require('react-ga').ga

			//debug
			// ANALYTIC_ID = ["UA-169651945-1","UA-18903793-38"]

			if(typeof ANALYTIC_ID == "string"){
				GA._defaultTrackers = null
				GA.initialize(ANALYTIC_ID, {
					// debug: CONST.IS_TEST,
					// titleCase: false,
					gaOptions: {
					//	userId: 123
						allowLinker: true
					}
				})
				global.GADefaultTrackers = GA._defaultTrackers
			}else if(ANALYTIC_ID && ANALYTIC_ID.map)
			{
				GA._defaultTrackers = []
				let a = []
				ANALYTIC_ID.map((id,k) => {
					a.push({
						trackingId: id, gaOptions: { name: "tracker" + k,
							allowLinker: true }
					});
					GA._defaultTrackers.push("tracker" + k)
				})
				GA.initialize(a, { alwaysSendToDefaultTracker: false })
				global.GADefaultTrackers = GA._defaultTrackers
			}else{
				console.warn("analytic error", ANALYTIC_ID)
			}

			if(GTAG_LINKER_DOMAINS){
				ga('require', 'linker');
				ga('linker:autoLink', GTAG_LINKER_DOMAINS);
			}


			ga(function() {
				let trackers = window.ga.getAll();
				trackers.forEach(function (tracker) {
					window._trackerLinkerParam = tracker.get('linkerParam')
					// console.log('tracker',tracker.get('name'), tracker.get('trackingId'), tracker.get('linkerParam'));
				});
				// var linkerParam = tracker.get('linkerParam');
				// console.log('linkerParam',linkerParam)
			});

			// if (window.dataLayer) { // Yes
			// 		// obtain the tracking ID from the dataLayer
			// 		for (var i = 0; i < window.dataLayer.length; i++){
			// 			if (window.dataLayer[i][0] == 'config') {
			// 				// initialise the tracking with a create
			// 				console.log('trackingID='+window.dataLayer[i][1]);
			// 				initialiseAnalytics();
			// 				window.ga('create', window.dataLayer[i][1]);
			// 				break checker;
			// 			}
			// 		}
			// 	}
			// else {
			// 		// gtag.js is NOT installed
			// 		// check for analytics.js installed
			// 		if (window.GoogleAnalyticsObject){
			// 			// analytics.js is installed in it;s own right
			// 			// so the create should already be done.
			// 			break checker;
			// 		}
			// 	}
		}

			// gtag
		if(typeof window != 'undefined' && (GTAGS || ADWORDS_LEADS)){

			// add script tag
			var id = ""
			if(typeof GTAGS == "string"){
				id = GTAGS;
			}else if(GTAGS && GTAGS[0]){
				id = GTAGS[0];
			}
			var script = document.createElement('script');
			script.setAttribute('src','https://www.googletagmanager.com/gtag/js?id=' + id);
			script.setAttribute('async','true');
			document.head.appendChild(script);



			window.dataLayer = window.dataLayer || [];
			window.gtag = function(){dataLayer.push(arguments);}
			window.gtag('js', new Date());

			let allGtags = []
			let analytics = []

			if(ANALYTIC_ID){
				if(ANALYTIC_ID.map){
					analytics = ANALYTIC_ID
				}else{
					analytics.push(ANALYTIC_ID)
				}
			}

			if(typeof GTAGS == "string"){
				allGtags.push(allGtags)
				// window.gtag('config', GTAGS);
			}else if(GTAGS && GTAGS.map){
				GTAGS && GTAGS.map(GTAG => {
					allGtags.push(GTAG)
					// window.gtag('config', GTAG);
				})
			}else{
				console.warn("gtag error", GTAGS)
			}

			allGtags && allGtags.map(GTAG => {
				if(analytics.indexOf(GTAG) == -1){
					window.gtag('config', GTAG, GTAG_LINKER_DOMAINS ? {
						'linker':{
							//'domains': [ '', 'lnw.me','bill.lnw.me']
							'domains': GTAG_LINKER_DOMAINS
						}
					}: {});

				}

				//
				//
				window.gtag('get', GTAG, 'client_id', (client_id) => {
					// Store the client ID in a variable.
					if (window._trackerLinkerParam){
						if (client_id && window._trackerLinkerParam.indexOf("clientId") == -1){
							window._trackerLinkerParam += "&clientId=" + client_id
						}
					}
				});
				window.gtag('get', GTAG, 'session_id', (session_id) => {
					// Store the session ID in a variable.
					if (window._trackerLinkerParam){
						if (session_id && window._trackerLinkerParam.indexOf("sessionId") == -1){
							window._trackerLinkerParam += "&sessionId=" + session_id
						}
					}
				});
				// // window.gtag('get', GTAG, 'linkerParam', (linkerParam) => {
				// // 	// Store the session ID in a variable.
				// // 	console.log('linkerParam',GTAG,linkerParam)
				// // });

			})

			// let GTAG = 'G-L197275EKN'
			// window.gtag('get', GTAG, 'client_id', (client_id) => {
			// 	// Store the client ID in a variable.
			// 	console.log('client_id',GTAG,client_id)
			// });
			// window.gtag('get', GTAG, 'session_id', (session_id) => {
			// 	// Store the session ID in a variable.
			// 	console.log('session_id',GTAG,session_id)
			// });
			//

			analytics && analytics.map(GTAG => { // UA
				window.gtag('config', GTAG,{
					'send_page_view': false,
					'linker': GTAG_LINKER_DOMAINS ? {
						//'domains': [ '', 'lnw.me','bill.lnw.me']
						'domains': GTAG_LINKER_DOMAINS
					}: {}
				});

			})

			window.gtag_page = function(page_path){
				allGtags && allGtags.map(GTAG => {
					if(analytics.indexOf(GTAG) == -1){
						window.gtag('config', GTAG, { page_path  });
					}
				})
			}

			window.gtag_config_run = function(options){
				const { domains = true, ignore_referer = false, ignore_referrer = false } = typeof options === 'object' ? options : {}
				let config = {}
				if(domains && GTAG_LINKER_DOMAINS){
					config.linker = {
						//'domains': [ '', 'lnw.me','bill.lnw.me']
						'domains': GTAG_LINKER_DOMAINS
					}
				}

				if(ignore_referer){
						config.ignore_referrer = true
				}else if(ignore_referrer){
					config.ignore_referrer = true
				}

				allGtags && allGtags.map(GTAG => {
					window.gtag('config', GTAG, config);
				})


				// ga(function(tracker) {
				// 	var linkerParam = tracker.get('linkerParam');
				// });

			}

			global.gtag_config_run = window.gtag_config_run
			global.gtag_page = window.gtag_page
			global.gtag = window.gtag
		}

		if(ADWORDS_LEADS) {
			//		lead_types = [
//			'contactus',
//			'phone',
//			'facebook',
//			'twitter',
//			'instagram',
//			'line',
//		];
			window.adwords_lead_track = function(type, options = {}, callback_url = null) {

				const CONST = require("@lib/base/const").default

				if(CONST.ADS_SETTING){
					if(type == 'remarketing'){
						if(CONST.ADS_SETTING.remarketing_no_ecomm_prodid){
							if(options.ecomm_prodid == 0) delete options.ecomm_prodid
						}else	if(CONST.ADS_SETTING.remarketing_extra){
							options = { ...options, ...CONST.ADS_SETTING.remarketing_extra }
						}
					}
				}

				if(!ADWORDS_LEADS[type]){
					console.log('lead type ', type , 'not found')
				}


				const defaultValues = {
					"_calculate": (CONST && CONST.ADWORDS_LEADS_DEFAULT_VALUES_CALCULATE) || null, // function (value, type, options)
					"_default" : 0,
					//"remarketing": 0, // type
					...((CONST && CONST.ADWORDS_LEADS_DEFAULT_VALUES) || {} )
				}

				// auto calculate value
				let value = 0.0
				if(typeof defaultValues[type] != 'undefined') value = defaultValues[type]
				else value = defaultValues['_default']

				// TODO: get product data if currently on product page in CONST.ADWORDS_LEADS_DEFAULT_VALUES_CALCULATE
				if(typeof defaultValues["_calculate"] == 'function') value = defaultValues["_calculate"](value, type, options)

				var callback = function () {
					if (callback_url) {
						window.location = callback_url;
					}
				};
				const { type:gtagType , ..._options } = options;
				if(typeof ADWORDS_LEADS[type] === 'string'){
					window.gtag && window.gtag('event', gtagType || 'conversion', {
						'send_to': ADWORDS_LEADS[type],
						'event_callback': callback,
						'value': value,
						'currency': 'THB',
						..._options
						// 'transaction_id' => ($order->order_id)
					});
				}else{
					window.gtag && window.gtag('event', gtagType || 'conversion', {
						'event_callback': callback,
						'value': value,
						'currency': 'THB',
						...ADWORDS_LEADS[type],
						..._options
						// 'transaction_id' => ($order->order_id)
					});
				}
				// console.log('adwords_lead_track', type, value, _options)
				global.debug('adwords_lead_track', type, value, _options)

				return false;
			}

			global.adwords_lead_track = window.adwords_lead_track
		}

//tag manager
		if(GTM_ID){
			if(typeof GTM_ID == 'string') GTM_ID = [GTM_ID]
			GTM_ID.map(ID => (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer', ID));

/*      GTM_ID.map(ID => {
          var noscript = document.createElement('noscript');
          var noscript_iframe = document.createElement('iframe');
          noscript_iframe.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=" + ID);
          noscript_iframe.setAttribute('width', "0");
          noscript_iframe.setAttribute('height', "0");
          noscript_iframe.setAttribute('style', "display:none;visibility:hidden");
          noscript.appendChild(noscript_iframe);
          document.body.appendChild(noscript);
        }
      );*/
		}

//pixel
		if(PIXEL_ID){

			!function(f,b,e,v,n,t,s) {
				if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)
			}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');

			if(!cookie_consent.analytics_storage || !cookie_consent.ad_storage)
				fbq('consent', 'revoke');

			const ids = typeof PIXEL_ID == "string" ? [ PIXEL_ID ] : PIXEL_ID
			ids.map(id => {
				fbq('init', id);
			})
			if(typeof window.pixel_view_tracked == 'undefined' || !window.pixel_view_tracked){
				fbq('track', 'PageView');
			}
		}


		if(SENTRY_URL){
			if(!getPlatformDep('RavenInit', SENTRY_URL)){
				console.warn('Raven can\'t init')
			}
		}

		if(LNWSTAT){
			global._lnws = LNWSTAT._lnws;
			global._lnwp = LNWSTAT._lnwp;
			global._lnwd = LNWSTAT._lnwd;
			global._lnwm = LNWSTAT._lnwm;
			global._lnwk = LNWSTAT._lnwk;


			let sc = (function() {
				var sc = document.createElement('script'); sc.type = 'text/javascript'; sc.async = true;
				sc.src = 'https://' + _lnws + '/lnwstat.js';
				var sp = document.createElement('span'); sp.id=(LNWSTAT.ele_id || 'lnwstat-container-ebi93') + '-inner';
				// var e = document.getElementById("app"); e.appendChild(sc); e.appendChild(sp);
				var e = document.body; e.appendChild(sc); e.appendChild(sp);
			});

			// if(cookie_consent_enable && !cookie_consent.analytics_storage){
			// 	_lck && _lck('analytics_storage', 1, sc)
			// }else{
			// TODO: ถือว่าเปนจำเป็น
				sc()
			// }
		}

		if(LINE_TAG_ID){
			window.LINE_TAG_ID = LINE_TAG_ID;

			let sc = function(){
				(function(g,d,o){
					g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
					var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
					var s=d.createElement('script');s.async=1;
					s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
					var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
				})(window, document);
				_lt('init', {
					customerType: 'lap',
					tagId: LINE_TAG_ID
				});
				_lt('send', 'pv', [LINE_TAG_ID]);
			}
			if(cookie_consent_enable && !cookie_consent.ad_storage){
				_lck && _lck('ad_storage', 1, sc)
			}else{
				sc()
			}
		}


		if(TIKTOK_PIXEL_ID){
			let sc = function(){
				!function (w, d, t) {
					w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

					ttq.load(TIKTOK_PIXEL_ID);
					ttq.page();
				}(window, document, 'ttq')
			}

			if(cookie_consent_enable && !cookie_consent.ad_storage){
				_lck && _lck('ad_storage', 1, sc)
			}else{
				sc()
			}
		}

	}

}
