// import { OrderedMap, Map } from 'immutable';
import uniqueId from 'lodash/uniqueId';

const uniqueEventId = uniqueId.bind(null, 'active_event_');

if (typeof document != 'undefined') {
	document.addEventListener('click', onEvent.bind(null, 'click'), true);
	document.addEventListener('keydown', onEvent.bind(null, 'keydown'));
	document.addEventListener('keyup', onEvent.bind(null, 'keyup'));
}

// let listenables = OrderedMap();
const listenables = {
	data: {},
	ids: [],
	last: () => {
		return listenables.data[listenables.ids[listenables.ids.length-1]];
	},
	set: (id, row) => {
		if(!listenables.data[id]){
			listenables.ids.push(id)
		}
		listenables.data[id] = row
		return listenables
	},
	delete: (id) => {
		if(listenables.data[id]){
			const did = listenables.ids.indexOf(id)
			if (did!==-1) {
				listenables.ids.splice(did, 1)
			}
			delete listenables.data[id]
		}
		return listenables
	}
}

function onEvent(type, event) {
	const list = listenables.last();
	if (list) {
		/* ex: [['click', clickHandler], ['keydown', keydownHandler]] */
		list.map(([_type, handler]) => {
			if (type == _type && typeof handler == 'function') {
				handler(event);
			}
		})
	}
};

const EventStack = {
	addListenable(listenArray) {
		/* ex: [['click', clickHandler], ['keydown', keydownHandler]] */
		const id = uniqueEventId();
		// const listenable = Map(listenArray);
		// const listenable = listenArray;
		//listenables =
		listenables.set(id, listenArray);
		return id;
	},
	removeListenable(id) {
		listenables.delete(id);
	}
};

export default EventStack;