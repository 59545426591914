/**
 *
 * Viewer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Viewer.css';
import { View } from 'browser/components';
// import { getModuleState } from 'recruitment/common/helpers'
// import {} from 'recruitment/browser/components'
// import { action1, action2 } from 'recruitment/common/actions';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import Linkify from 'react-linkify';
import {
manageActions
}
from 'recruitment/common/actions';

const {updateOpinion,sendResult} = manageActions;
import axios from 'axios';

const version = 'manage_v2/';

class Viewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      comment: {
        fetching: false,
        data: null,
        error: null
      },
      interview: '',
      opinion: '',
      memo:"",
      sent: false
    };
  }

  handleOpinion(event) {
    this.setState({opinion: event.target.value, sent: false})
  }

  handleInterview(event) {
    this.setState({interview: event.target.value, sent: false})
  }

  handleSubmit() {
    const {opinion, interview} = this.state
    const {updateOpinion, params, manager} = this.props
    if (interview || manager.department.type === 'commentor') {
      this.setState({sent: true})
      updateOpinion(manager.login.google_id,
              manager.department.applicant[ params.index ],
              opinion,
              interview || manager.department.type)
    }
  }

  listComment(data) {
    const {params, manager} = this.props
    const comment = manager.department.comment.filter((each) => each.code === data.position && each.row === data.row)
    if (comment.length > 0) {
      return <div class="review"><h4>ความคิดเห็นเพิ่มเติม</h4>
          {comment.map((each, k) =>
                <div key={k}>
                    <h5>{each.commentor}@{each.timestamp}</h5>
                    <p class="comment">: {each.comment}</p>
                </div>)}
      </div>
    } else
      return null
  }

  handleSendResult(result){
    const {sendResult, params, manager} = this.props
    let message
    if(result){
      message = "ต้องการรับผู้สมัครเข้าทำงานใช่หรือไม่?"
    }else{
      message = "ไม่ต้องการรับผู้สมัครเข้าทำงานใช่หรือไม่?"
    }
    if(confirm(message)){
      const {memo} = this.state
      this.setState({sent: true})
      sendResult(manager.login.google_id,manager.department.applicant[ params.index ],result,memo)
      alert("Yes")
    }
  }

  handleMemo(event){
    this.setState({memo: event.target.value, sent: false})
  }

  renderResult(active){
    if(active){
      return <div>
      <span>under construction</span>
      <h2>ผลการสัมภาษณ์</h2>
      <div class="memo">
        <textarea rows={3} value={this.state.memo} onChange={this.handleMemo.bind(this)} placeholder="หมายเหตุ"></textarea>
      </div>
      <button class="button button-accept" onClick={this.handleSendResult.bind(this,true)} disabled={this.state.sent}>รับเข้าทำงาน</button>
      <button class="button button-reject" onClick={this.handleSendResult.bind(this,false)} disabled={this.state.sent}>ไม่รับเข้าทำงาน</button>
      </div>
    }
  }

  renderInput(interview, superDepartment,data) {
    const {manager} = this.props
      const oldOpinion = (data && data.opinion) ? (data.opinion.split(": ")[1] || data.opinion)   : ""

    if (manager.department.type === 'manager' || superDepartment) {
      return <div class="review"><h4>ความคิดเห็นหัวหน้าทีม</h4>
		  { this.state.sent ? <div class="comment_success">
			  ส่งความเห็น "{this.state.opinion}" เรียบร้อยแล้ว
		  </div> : <div>
			  <div class="comments"><Textarea rows={3} value={this.state.opinion || oldOpinion} onChange={this.handleOpinion.bind(this)} placeholder="ใส่ความคิดเห็น"></Textarea></div>
			  <ul class="statusAction">
				  <li><input type="radio" checked={interview === 'เรียกสัมภาษณ์'} value="เรียกสัมภาษณ์" id="interestSet" onClick={this.handleInterview.bind(this)}/><label for="interestSet">เรียกสัมภาษณ์</label></li>
				  <li><input type="radio" checked={interview === 'ไม่เรียกสัมภาษณ์'} value="ไม่เรียกสัมภาษณ์"  id="rejectSet" onClick={this.handleInterview.bind(this)}/><label for="rejectSet">ไม่เรียกสัมภาษณ์</label></li>
				  <li><input type="radio" checked={interview === 'เอาไว้เป็นตัวเลือก'} value="เอาไว้เป็นตัวเลือก"  id="reserveSet" onClick={this.handleInterview.bind(this)}/><label for="reserveSet">เอาไว้เป็นตัวเลือก</label></li>
			  </ul>
			  <button class="button button-submit" onClick={this.handleSubmit.bind(this)} disabled={this.state.sent}>ส่งความคิดเห็น</button>
		  </div> }
      </div>
    } else {
      return <div class="opinion"><h4>ความคิดเห็น</h4>
		  { this.state.sent ? <div class="comment_success">
			  ส่งความเห็น "{this.state.opinion}" เรียบร้อยแล้ว
		  </div> : <div>
        		<div class="comments"><Textarea rows={3} value={this.state.opinion} onChange={this.handleOpinion.bind(this)} placeholder="ใส่ความคิดเห็น"></Textarea></div>
          		<button class="button button-submit" onClick={this.handleSubmit.bind(this)} disabled={this.state.sent}>ส่งความคิดเห็น</button>
              {this.renderResult(false)}
		  </div> }
      </div>
    }
  }

  render() {
    const {params, manager} = this.props
    const {comment, interview} = this.state
    const {department} = manager
    if (!department) {
      return <View class={styles.local}>
          Loading
      </View>
    }
    if (!(params.index in department.applicant)) {
      return <View class={styles.local}>
          No Data found
      </View>
    }
    const data = department.applicant[ params.index ]
    const info = department.info[ data.position ]
    const quiz = info.quiz.map((question, i) => <li class='quiz' key={i}><span class="question">{question}</span><span class="answer"><Linkify
			componentDecorator={(decoratedHref, decoratedText, key) => (
				<a target="_blank" href={decoratedHref} key={key}>{decoratedText}</a>
			)}
		>{data.answer[ i ]}</Linkify></span></li>)
    return (
      <View class={styles.local}>
        <div class="viewer-view">
			<div id="application">
				<div class="header"><h1><span>ผู้สมัครตำแหน่ง {info.position}</span></h1></div>
				<div class="flex">
					<div id="profile_review">
						{/* <h2 class="position">ตำแหน่ง<span>{info.position}</span></h2> */}
						<div class="profile">
							<div class="fullname"><h3 class="data">{data.fullname}</h3></div>
							<div class="nickname"><span class="title">ชื่อเล่น</span><span class="data">{data.nickname}</span></div>
							<div class="e-mail"><span class="title">E-mail</span><span class="data"><Linkify properties={{target: '_blank'}}>{data[ 'e-mail' ]}</Linkify></span></div>
							<div class="expect-salary"><span class="title">เงินเดือนที่ต้องการ</span><span class="data">{data.salary_request}</span></div>
							<div class="resume"><span class="title">Resume</span><span class="data"><a href={data.resume} target="_blank"><img src="/assets/recruitment/img/Adobe_PDF_file_icon_32x32.png"/></a></span></div>
						</div>

						{data.opinion ? <div class="review"><h4>ความคิดเห็นหัวหน้าทีม</h4><p>{data.opinion}</p></div> : null}
						{this.listComment(data)}

						{this.renderInput(interview, department.type === 'super' && department.super.includes(data.position),data)}
					</div>
					<div id="quiz_review">
						<h2>แบบทดสอบ</h2>
						<ol>{quiz}</ol>
					</div>
				</div>
			</div>
        </div>
      </View>
    );
  }
}

export default connect(state => ({
    manager: state.recruitment.manager
            // state: getModuleState(state),
  }), {updateOpinion,sendResult})(Viewer);
