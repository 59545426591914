
import { isCacheDisabled } from "@lib/base/helper";
// import { fromJSON } from 'common/transit';
import { Map } from 'common/immutable';

import config from 'common/config';
// default config
// TODO: load from file
// const config = {
//   appName: '',
//   appVersion: '',
//   firebase: null,
//   sentryUrl: '',
//   defaultLocale: 'en',
//   locales: {},
// }
//let initialState = null

// Transit code removed (slow)
let initialState = window.__INITIAL_STATE__ ? window.__INITIAL_STATE__ : null // eslint-disable-line no-underscore-dangle

if(!isCacheDisabled()){ // ?nc = ignore init state from server?
	if(!initialState && window.__LZ_INITIAL_STATE__){
		const LZString = require('@lib/base/lz-string')
		initialState = JSON.parse(LZString.decompressFromBase64(window.__LZ_INITIAL_STATE__))// eslint-disable-line no-underscore-dangle
		debug('initialState', initialState)
	}
}

// --- default config if initial state not provided from server

if (!initialState || !initialState.app) initialState = {}

if (!initialState.app) {
	initialState.app = Map({
		error: null,
		location: null,
		menuShown: false,
		online: false,
		storageLoaded: false,
		global: {}
	})
}

if (!initialState.config) {
	initialState.config = Map({
		appName: config.appName,
		appVersion: config.appVersion,
		firebase: config.firebase,
		sentryUrl: config.sentryUrl,
	})
}

if (!initialState.device) {
	initialState.device = Map({
		host: typeof window != 'undefined' ? window.location.host : '',
		isReactNative: false,
		platform: '', // iOS or Android in React Native.
	})
}

if (!initialState.intl || !initialState.intl.currentLocale) {
	initialState.intl = Map({
		currentLocale: config.defaultLocale,
		defaultLocale: config.defaultLocale,
		locales: config.locales,
		messages: {},
	})
}

export default initialState
