
// require('./Cache');
// require('./Dialog');
// require('./Toast');


// react 16
// import 'core-js/es6/map';
// import 'core-js/es6/set';

import { setPlatformDep } from 'common/helpers/platformDeps';

require('./Cache');
require('./Dialog');
require('./Toast');

setPlatformDep('ReactRouter', require('react-router-dom'))

let Sentry = null
let RewriteFrames  = null
import(/* webpackChunkName: "sentry" */ '@sentry/browser').then(out => Sentry = out);
import(/* webpackChunkName: "sentry" */ '@sentry/integrations').then(out => RewriteFrames = out.RewriteFrames);

// sentry debug
setPlatformDep('RavenInit', (SENTRY_URL) => {
	// if(typeof Raven !== "undefined"){
	// 	SENTRY_URL && Raven.config(SENTRY_URL).install();
	// 	return Raven;
	// }
	// const Sentry = await import(/* webpackChunkName: "sentry" */ '@sentry/browser');
	// const { RewriteFrames } = await import(/* webpackChunkName: "sentry" */ '@sentry/integrations');

	const init = () => {
		if(!SENTRY_URL) SENTRY_URL = require('@lib/base/const').default.SENTRY_URL
		SENTRY_URL && Sentry.init({
			dsn: SENTRY_URL,
			// integrations: [new RewriteFrames()],
			release: process.env.RELEASE || 'unknown'
		});

		Sentry.configureScope(function(scope) {
			scope.setTag('TARGET', 'browser');

			const { isTest, isLocalhost } = require('@lib/base/helper')
			scope.setTag('IS_LOCALHOST', isLocalhost());
			scope.setTag('IS_TEST', isTest());
		});

		global.debug('sentry inited', SENTRY_URL)
		Sentry.inited = true
	}

	if(!Sentry){
		return import(/* webpackChunkName: "sentry" */ '@sentry/browser').then(out => {
			Sentry = out
			init()
		});
	}else if(!Sentry.inited){
		init()
	}

	return Sentry
	// return false;
})


setPlatformDep.Async('nprogress', () => {
	import(/* webpackChunkName: "nprogress" */'nprogress/nprogress.css')
	return import("nprogress")
})