import { _l } from "common/app/lang";
import Toast from "common/helpers/Toast";
import {purgePersistStore} from "./store/configureStore.lib";
import { Util } from '@lnw/util';

/* @flow weak */
// import Raven from 'raven-js';
// import { firebaseActions } from '@lib/redux-firebase';

let localStorageLimitErrorCount = 0

// bluebirdjs.com/docs/api/error-management-configuration.html#global-rejection-events
const register = unhandledRejection => unhandledRejection(async event => {
	event.preventDefault();
	// http://bluebirdjs.com/docs/api/error-management-configuration.html
	const { reason: error } = event.detail || event;
	const errorCode = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000
	// error.errorCode = errorCode

	// let ignoreError = false
	// if(ignoreError !== true) return;

	if(error.shouldThrow !== false || process.env.NODE_ENV === 'production') {
		const { getPlatformDep } = require('common/helpers/platformDeps');
		let Raven = getPlatformDep('RavenInit')
		if(Raven){
			// Raven.setTagsContext({ errorCode, error });
			if(Raven.then) Raven = await Raven
			Raven.withScope(function(scope) {
				//https://docs.sentry.io/enriching-error-data/context/?platform=browser
				scope.setExtra("errorCode", errorCode);
				scope.setExtra("errorType", 'unhandledRejection');
				scope.setExtra("error", error);
				if(error){
					scope.setExtra("errStringLong", ((error.message || error.name) + ' (configureReporting.js) ' + (error.source ? (' :: ' + error.source.url) : '')));
				}

				//TODO: grouping & fingerprint
				//https://docs.sentry.io/data-management/event-grouping/
				// scope.setFingerprint([method, path, err.statusCode]);

				if(error instanceof Error){
					Raven.captureException(error)
				}else if(typeof error === 'string'){
					Raven.captureException(new Error(error))
				}else{
					const errStringLong = ((error.message || error.name) + ' ' + (error.source ? (' :: ' + error.source.url) : ''))
					Raven.captureException(new Error(errStringLong))
				}
			});
		}
	}

	let shouldToastError = error.shouldToastError
	let errString = error.message ||error.name || (error.toString && error.toString())

	if(errString.length > 200){
		// strip html
		errString = Util.stripHtml(errString).substr(0, 200)
	}

	// black list toast
	if(errString.indexOf("exceeded the quota") !== -1){
		//
		shouldToastError = false
		// purge store?
		try{
			console.log('storage exceeded the quota = try to purge', localStorageLimitErrorCount)
			purgePersistStore()
			localStorageLimitErrorCount++
			// size check
			// const remain = (1024 * 1024 * 5 - unescape(encodeURIComponent(JSON.stringify(localStorage))).length);
			localStorage.setItem('test', 'test')
			if(localStorageLimitErrorCount > 5){
				console.log('storage exceeded the quota = try to clear', localStorageLimitErrorCount)
				localStorage.clear()
			}
		}catch(e){
			try{
				console.log('storage exceeded the quota = try to clear', localStorageLimitErrorCount)
				localStorage.clear()
			}catch(e){
				console.error(e)
			}
		}
	}


	// TODO: repeat? or check for connection
	if(errString.indexOf("Network Error") !== -1) {
		//
		shouldToastError = false
	}

	const stack = error.stack || ""
	if(stack.indexOf('analytics.tiktok.com') !== -1) {
		shouldToastError = false
	}


	if(stack.indexOf('doubleclick.net') !== -1) {
		shouldToastError = false
	}



	// Toast
	if(shouldToastError !== false) {
		const errStringLong = ((error.message || error.name) + ' ' + (error.source ? (' :: ' + error.source.url) : ''))
		// Display error
		// fix google crawl bug => dont' toast resource error
		if(errString && errString.indexOf('/assets/') !== -1 && (errString.indexOf('.css') !== -1 || errString.indexOf('.js') !== -1) ){

		}else{
			// if (!isLocalhost()){ //TBF Error in localhost
				Toast.error(`#${errorCode} ` + _l('error_' + errString.substr(0, 30), `<b> พบข้อผิดพลาด กรุณาลองใหม่ </b> - กดเพื่อดูรายละเอียด \n<br> ${errString}`), null
					, {
						timeOut: 0,
						extendedTimeOut: 0,
						onclick: () => alert(`ERROR: #${errorCode}\n ${errStringLong} \n ${JSON.stringify(error)} ${error.filename} ${error.stack}`)
					})
			// }
		}

	}

	if (process.env.NODE_ENV !== 'production') {
		/* eslint-disable no-console */
		// console.warn(error);
		console.error('[[ Unhandled promise rejection. Fix it or it will be reported. ]]', error, error && error.filename, error && error.stack);
		/* eslint-enable no-console */
	}
});

const setRavenUserContext = user => {
	// if (!user) {
	// 	Raven.setUserContext();
	// 	return;
	// }
	// Raven.setUserContext({
	// 	email: user.email,
	// 	id: user.id,
	// });
};

const reportingMiddleware = () => next => action => {
	// if (action && action.type === firebaseActions.FIREBASE_ON_AUTH) {
	// 	setRavenUserContext(action.payload.user);
	// }
	// // TODO: Use Raven.setExtraContext for last 10 actions and limited app state.
	return next(action);
};

const configureReporting = (options) => {
	const { appVersion, sentryUrl, unhandledRejection } = options;
	// move to platformDeps
	// Raven.config(sentryUrl, {
	// 	// gist.github.com/impressiver/5092952
	// 	ignoreErrors: [
	// 		'top.GLOBALS',
	// 		'originalCreateNotification',
	// 		'canvas.contentDocument',
	// 		'MyApp_RemoveAllHighlights',
	// 		'http://tt.epicplay.com',
	// 		'Can\'t find variable: ZiteReader',
	// 		'jigsaw is not defined',
	// 		'ComboSearch is not defined',
	// 		'http://loading.retry.widdit.com/',
	// 		'atomicFindClose',
	// 		'fb_xd_fragment',
	// 		'bmi_SafeAddOnload',
	// 		'EBCallBackMessageReceived',
	// 		'conduitPage',
	// 		// Firebase
	// 		'Access is denied.',
	// 		'An internal error has occurred.',
	// 		'PERMISSION_DENIED: Permission denied',
	// 		'A network error (such as timeout, interrupted connection or unreachable host) has occurred',
	// 	],
	// 	ignoreUrls: [
	// 		// Facebook flakiness
	// 		/graph\.facebook\.com/i,
	// 		// Facebook blocked
	// 		/connect\.facebook\.net\/en_US\/all\.js/i,
	// 		// Woopra flakiness
	// 		/eatdifferent\.com\.woopra-ns\.com/i,
	// 		/static\.woopra\.com\/js\/woopra\.js/i,
	// 		// Chrome extensions
	// 		/extensions\//i,
	// 		/^chrome:\/\//i,
	// 		// Other plugins
	// 		/127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
	// 		/webappstoolbarba\.texthelp\.com\//i,
	// 		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
	// 	],
	// 	release: appVersion,
	// 	// TODO: serverName: device.uuid
	// 	// TODO: Add list of common ignore rules from
	// 	// docs.getsentry.com/hosted/clients/javascript/tips/#decluttering-sentry
	// }).install();
	if(process.env.NODE_ENV === 'production')
	{
		// pre init
		const {getPlatformDep} = require('common/helpers/platformDeps');
		getPlatformDep('RavenInit')
	}
	register(unhandledRejection);
	return reportingMiddleware;
};

export default configureReporting;
