import React from 'react';

import {
	manageActions
}
	from 'recruitment/common/actions';

const { updateOpinion } = manageActions;
import {
	connect
}
	from 'react-redux';

class ApplicationView extends React.Component {

	constructor(props) {
		super(props)
		this.state = { index: 0, opinion: "", interview: null, sent: [] }
	}

	handleNextButton() {
		if (this.props.manager.department.contact_list.application[ this.state.index + 1 ]) {
			this.setState({ index: this.state.index + 1, opinion: "", interview: null })
		}
	}

	handlePrevButton() {
		if (this.props.manager.department.contact_list.application[ this.state.index - 1 ]) {
			this.setState({ index: this.state.index - 1, opinion: "", interview: null })
		}
	}

	handleOpinion(event) {
		this.setState({ opinion: event.target.value })
	}

	handleInterview(event) {
		this.setState({ interview: event.target.value })
	}

	handleSubmit() {
		const { index, opinion, interview } = this.state
		const { updateOpinion, manager } = this.props
		if (interview) {
			const data = manager.department.contact_list.application[ index ]
			updateOpinion(manager.google_id, data, opinion, interview)
			this.setState({ sent: [ ...this.state.sent, index ] })
		}
	}

	submitLabel() {
		const { sent, index } = this.state
		let ret = "ส่งความคิดเห็น"
		if (sent.includes(index)) {
			ret += "ซ้ำ"
		}
		return ret
	}

	render() {
		const { department } = this.props.manager
		const { interview, index } = this.state
		const data = department.contact_list.application[ index ]
		const info = department.info[ data.code ]
		const quiz = info.quiz.map((question, i) => <li key={i}>{question}<br/><span>{data.answer[ i ]}</span></li>)
		return <div id="application">
			<div id="profile">
				<h2>ใบสมัครที่ {index + 1} จาก {department.contact_list.application.length} </h2>
				<label>ตำแหน่ง<span>{info.position}</span></label><br/>
				<label>ชื่อ-นามสกุล<span>{data.fullname}</span></label><br/>
				<label>ชื่อเล่น<span>{data.nickname}</span></label><br/>
				<label>E-mail address<span>{data[ 'e-mail' ]}</span></label><br/>
				<label>เงินเดือนที่ต้องการ<span>{data.salary}</span></label><br/>
				<label>Resume<span><a href={data.resume} target="_blank"><img
					src="/assets/recruitment/img/Adobe_PDF_file_icon_32x32.png"/></a></span></label><br/>
				<textarea value={this.state.opinion} onChange={this.handleOpinion.bind(this)}
									placeholder="ความคิดเห็นหัวหน้าทีม"></textarea><br/>
				<label><input type="radio" checked={interview == 'เรียกสัมภาษณ์'} value="เรียกสัมภาษณ์"
											onClick={this.handleInterview.bind(this)}/>เรียกสัมภาษณ์</label><br/>
				<label><input type="radio" checked={interview == 'ไม่เรียกสัมภาษณ์'} value="ไม่เรียกสัมภาษณ์"
											onClick={this.handleInterview.bind(this)}/>ไม่เรียกสัมภาษณ์</label><br/>
				<label><input type="radio" checked={interview == 'เอาไว้เป็นตัวเลือก'} value="เอาไว้เป็นตัวเลือก"
											onClick={this.handleInterview.bind(this)}/>เอาไว้เป็นตัวเลือก</label><br/>
				<button onClick={this.handleSubmit.bind(this)}>{this.submitLabel()}</button>
				<br/>
				<button onClick={this.handlePrevButton.bind(this)}>ย้อนกลับ</button>
				<button onClick={this.handleNextButton.bind(this)}>ถัดไป</button>
			</div>
			<div id="quiz">
				<h2>แบบทดสอบ</h2>
				<ol>{quiz}</ol>
			</div>
		</div>
	}
}

export default connect(state => ({ manager: state.recruitment.manager }), { updateOpinion })(ApplicationView);