/* @flow */
export { default as Match } from './Match';
export { default as Route } from './Match';
export { Switch as RouteSwitch } from 'react-router';

// TODO
// export { default as If } from './If';
// export { default as IfDiv } from './IfDiv';
export linkState from './linkState'
export linkRedux from './linkRedux'
export * from './Loop';

// https://reacttraining.com/react-router/examples/route-config
// import Route from 'react-router';
// export const RouteWithSubRoutes = (route) => (
//   <Route path={route.path} render={props => (
//     // pass the sub-routes down to keep nesting
//     <route.component {...props} routes={route.routes}/>
//   )}/>
// )

export { default as Redirect } from './Redirect';
// export { default as ThemeProvider } from './ThemeProvider';
export { default as ThemeProvider } from './ThemeProvider';

