/* @flow */
import PropTypes from 'prop-types';

import React from 'react';

import Waypoint_ from 'react-waypoint';

import _ from 'common/helpers/_';

export class Waypoint extends React.PureComponent {

	static propTypes = {
		throttle: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	}

	componentWilUnmount() {
		if (this.throttledHandler) this.throttledHandler.cancel();
	}

	throttledHandler = null

	render() {
		const props = this.props
		const throttle = props.throttle || 100
		if (!throttle) return <Waypoint {...props} />
		return (
			<Waypoint_
				onEnter={() => null}
				onLeave={() => null}
				{...props}
				throttleHandler={(scrollHandler) => {
					this.throttledHandler = _.throttle(scrollHandler, throttle);
					return this.throttledHandler || scrollHandler;
				}}
				ref={(component) => {
					if (!component) {
						this.throttledHandler && this.throttledHandler.cancel();
					}
				}}
			/>
		);
	}
}

export default Waypoint;
