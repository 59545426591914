/* @flow */

import { isCacheDisabled } from "@lib/base/helper";
import { getPlatformDep } from "common/helpers";
import React from 'react';
import ReactDOM from 'react-dom';
import Root, { history } from './Root';
import configureReporting from 'common/configure/configureReporting';
import configureStore from 'common/configure/configureStoreAsync';
// import localforage from 'localforage';
// import localforage from 'localforage/dist/localforage.min';
import uuid from 'uuid';

import initialState from './getInitialState';

import 'common/lib'
import Loadable from '@lib/react-loadable'

//
// import injectTapEventPlugin from 'react-tap-event-plugin';
// // Needed for onTouchTap
// // http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin();

global._inited = false
const init = async () => {
	if(global._inited ) return console.log('already inited')
	global._inited = true
	const appElement = document.getElementById('app');

	const reportingMiddleware = configureReporting({
		appVersion: initialState.config.appVersion,
		sentryUrl: initialState.config.sentryUrl,
		unhandledRejection: fn => window.addEventListener('unhandledrejection', fn),
	});


	// update const from api (local)
	// server render mode will already provide window._CONST
	if(typeof window !== 'undefined' ){
		const { default:CONST, getConst } = require('_config/common/const')
		const { getUrl } = require("common/helpers");

		// local dev = cache _CONST
		if(isLocalhost() && isBrowser() && !window._CONST){
			if(!isCacheDisabled()){
				// load cache _CONST
				const cached = sessionStorage.getItem('_cached_CONST')
				if(cached){
					try{
						window._CONST = JSON.parse(cached);
						getConst(getUrl(), false)
					}catch(e){
						console.error(e)
					}
				}
			}
		}

		// update const from api
		if(!window._CONST){
			// no server render mode
			const { api } = require("@lnw/api");

			if(CONST && CONST.API.CONST_API_PATH){
				const { Url } = require("@lib/base/Url")

				getPlatformDep.Async('nprogress').then(NProgress => NProgress.start())
				// NProgress && NProgress.start();

				try{
					const ret = await api({
						method: 'GET',
						url: Url.api(CONST.API.CONST_API_PATH, CONST),
						params: {
							fullUrl: window.location
						},
						dataType: 'json'
					});

					if(ret  && ret.data ){
						window._CONST = ret.data
						getConst(getUrl(), false)
					}
				}catch(e){
					if(e && e.errorObject && e.errorObject.data){
						window._CONST = e.errorObject.data
						getConst(getUrl(), false)
					}

					console.error(e)
				}

				getPlatformDep.Async('nprogress').then(NProgress => NProgress.done())

				// const getConst = require('_config/common/const').getConst
			}
		}

		// save cache
		if(isLocalhost() && isBrowser() && window._CONST){
			sessionStorage.setItem('_cached_CONST', JSON.stringify(window._CONST));
		}
	}

	const store = await configureStore({
		initialState,
		platformDeps: { uuid,  history, storageEngine: null /*localStorage*/ },
		platformMiddleware: [reportingMiddleware],
	});

// TODO: history middleware


// ReactDOM.hydrate
// Hydrating a server-rendered container now has an explicit API. If you’re reviving server-rendered HTML, use ReactDOM.hydrate instead of ReactDOM.render. Keep using ReactDOM.render if you’re just doing client-side rendering.

// Initial render.
	if(typeof window !== 'undefined' && (window.TARGET === 'server-production' || window.TARGET === 'server')){
		// https://github.com/jamiebuilds/react-loadable

		// const div = document.createElement("div");
		// ReactDOM.render(
		// 	// <Loadable.Capture report={moduleName => console.log('moduleName1',moduleName)}>
		// 		<Root store={store}/>
		// 	// </Loadable.Capture>
		// , div);

		// setTimeout(() => Loadable.preloadReady().then( () => {ReactDOM.hydrate(<Root store={store}/>, appElement);debugger;}, 10000))

		// Loadable.preloadAll().then( () => { // preload everything
		// Loadable.preloadReady().then( () => {
		// 	console.log('rehydrate')
		// 	ReactDOM.hydrate(<Root store={store}/>, appElement)
		// });
		function promiseTimeout(ms, promise) {
			return new Promise((resolve, reject) => {
				setTimeout(() => { resolve(new Error("promise timeout")) }, ms);
				promise.then(resolve).catch(reject);
			});
		}

		debug('wait for preload ready')
		setTimeout(() => // wait js to load
			promiseTimeout(3000, Loadable.preloadReady()).then( () => {
				debug('react hydrate')

				const CONST = require('_config/common/const').default
				if(CONST.__DISABLE_HYDRATE){

					ReactDOM.render(<Root store={store}/>, appElement);
				}else{

					ReactDOM.hydrate(<Root store={store}/>, appElement);
				}
			})
		, 1)


		// Loadable.preloadAll().then( () => ReactDOM.hydrate(
		// 	<Root store={store}/>
		// 	, appElement))

	}else{

		ReactDOM.render(
			<Root store={store}/>
			, appElement);
	}


// Hot reload render.
// gist.github.com/gaearon/06bd9e2223556cb0d841#file-naive-js
	if (module.hot && typeof module.hot.accept === 'function') {
		global.__HOTRELOAD__ = () => {
			if(module.hot.__callback) {
				for(let key in module.hot.__callback){
					if(module.hot.__callback.hasOwnProperty(key)){
						console.log('hot reload => run', key)
						module.hot.__callback[key]()
					}
				}
			}

			const NextRoot = require('./Root').default;
			ReactDOM.render(
				<NextRoot store={store}/>
				, appElement);

			console.log('hot reload => re-render React')
		}
		module.hot.__callback = {}
		module.hot.accept(['./Root', '_config/browser/Route'], global.__HOTRELOAD__);
		// module.hot.accept('./Root', global.__HOTRELOAD__);


		module.hot.accept(["@lib/reduxable/src/Reduxable", "common/configure/configureStoreAsync", 'common/configure/store/configureReducer'], async () => {
			const configureReducer = require('common/configure/store/configureReducer').default;
			const Reduxable = require("@lib/reduxable/src/Reduxable").default;
			Reduxable._setStore(store)
			const reducer = await configureReducer(initialState);
			store.replaceReducer(reducer)
		});


	}



}

init()
