let _urlGetParams = null
export const urlParams = (skipCache) => {
	if (_urlGetParams && !skipCache) return _urlGetParams
	var query_string = {};
	if (typeof window == 'undefined') return {}
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		// If first entry with this name
		if (typeof query_string[pair[0]] === "undefined") {
			query_string[pair[0]] = decodeURIComponent(pair[1]);
			// If second entry with this name
		} else if (typeof query_string[pair[0]] === "string") {
			var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
			query_string[pair[0]] = arr;
			// If third or later entry with this name
		} else {
			query_string[pair[0]].push(decodeURIComponent(pair[1]));
		}
	}
	_urlGetParams = query_string
	return query_string
}

let _isMobileTemplate = null

export function isMobileTemplate() {
	if (_isMobileTemplate === null) {
		if (typeof window === 'undefined' || !window.matchMedia) return false;
		_isMobileTemplate = !window.matchMedia('(min-width: 750px)').matches;
	}
	return _isMobileTemplate
}

export const animationDisable = () => {
	return !isMobileTemplate() && urlParams().animationDisable
}