/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import { trackAction } from "@lib/base/tracker";
import React, { Component } from 'react';
// import ReactGA from 'react-ga';

const trackPage = (page, options = {}) => {
	import(/* webpackChunkName: "ga" */ "react-ga").then(GA => {
		GA.set({
			page,
			...options
		});
		//GA._defaultTrackers not working
		// console.log('global.GADefaultTrackers',GA._defaultTrackers , global.GADefaultTrackers)
		GA.pageview(page, GA._defaultTrackers  || global.GADefaultTrackers);
	});

	//gtag pageview
	if(typeof window !== 'undefined' && window.gtag_page)
		window.gtag_page(page)
};

class GATracker extends Component {

	componentDidMount() {
		//comment 29/5/2566 เนื่องจากมีการยิง event pageview ซ้ำในการโหลดครั้งแรก
		/*const page = this.props.location.pathname;
		trackPage(page);
		this.action();*/
	}

	componentWillReceiveProps(nextProps) {
		const currentPage = this.props.location.pathname;
		const nextPage = nextProps.location.pathname;

		//todo track title ไม่เปลี่ยน

		if (currentPage !== nextPage) {
			trackPage(nextPage);
			this.action(); // TODO:?
		}
	}

	action(){
		// trackAction
		let track = this.props.track
		if(!track) return;
		if(typeof track == 'function') track = track(this.props)

		const { gaCategory , gaAction, gaLabel, gaValue, gTagEnable,
			adwordsLeadType, adwordsLeadOptions, pixelOptions }  = track

		trackAction({ category:gaCategory, action:gaAction, label:gaLabel, value:gaValue, gTagEnable,
			adwordsLeadType, adwordsLeadOptions, pixelOptions
		})
	}

	render() {
		return null;
	}
}

const withTracker = function (WrappedComponent, options = {}) {

	const HOC = class extends Component {
		componentDidMount() {
			const page = this.props.location.pathname;
			trackPage(page, options);
		}

		componentWillReceiveProps(nextProps) {
			const currentPage = this.props.location.pathname;
			const nextPage = nextProps.location.pathname;

			if (currentPage !== nextPage) {
				trackPage(nextPage, options);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
}

export { GATracker, withTracker, withTracker as default }
