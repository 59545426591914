import * as themes from 'base/browser/app/themes';

/* @flow */
import PropTypes from 'prop-types';

import React from 'react';
import { Button, View } from 'browser/components';
import { connect } from '@lib/base/redux';
import { setTheme } from 'base/common/themes/actions';

const getSortedThemeKeys = () => {
	const customThemesKeys = Object.keys(themes)
		.filter(key => key !== 'initial')
		.sort();
	return ['initial', ...customThemesKeys];
};

export const SwitchTheme = ({ currentTheme, setTheme }) => (
	<View>
		{getSortedThemeKeys().map(themeKey =>
			<Button
				key={themeKey}
				mr={1}
				onClick={() => setTheme(themeKey)}
				theme={themeKey === currentTheme ? 'primary' : 'secondary'}
			>
				{themeKey} theme
			</Button>
		)}
	</View>
);

SwitchTheme.propTypes = {
	currentTheme: PropTypes.string,
	setTheme: PropTypes.func.isRequired,
};

export default connect(state => ({
	currentTheme: state.themes.currentTheme || 'initial',
}), { setTheme })(SwitchTheme);
