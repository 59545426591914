import PropTypes from 'prop-types';

import { animationDisable } from './util'

// Object.defineProperty(exports, '__esModule', {
// 	value: true,
// });

const _extends = Object.assign || function (target) {
	for (let i = 1; i < arguments.length; i++) {
		const source = arguments[i];
		for (const key in source) {
			if (Object.prototype.hasOwnProperty.call(source, key)) {
				target[key] = source[key];
			}
		}
	}
	return target;
};

const _createClass = (function () {
	function defineProperties(target, props) {
		for (let i = 0; i < props.length; i++) {
			const descriptor = props[i];
			descriptor.enumerable = descriptor.enumerable || false;
			descriptor.configurable = true;
			if ('value' in descriptor) descriptor.writable = true;
			Object.defineProperty(target, descriptor.key, descriptor);
		}
	}

	return function (Constructor, protoProps, staticProps) {
		if (protoProps) defineProperties(Constructor.prototype, protoProps);
		if (staticProps) defineProperties(Constructor, staticProps);
		return Constructor;
	};
}());

const _get = function get(_x, _x2, _x3) {
	let _again = true;
	while (_again) {
		let object = _x,
			property = _x2,
			receiver = _x3;
		_again = false;
		if (object === null) object = Function.prototype;
		let desc = Object.getOwnPropertyDescriptor(object, property);
		if (desc === undefined) {
			let parent = Object.getPrototypeOf(object);
			if (parent === null) {
				return undefined;
			}
			_x = parent;
			_x2 = property;
			_x3 = receiver;
			_again = true;
			desc = parent = undefined;
			continue;
		} else if ('value' in desc) {
			return desc.value;
		} else {
			const getter = desc.get;
			if (getter === undefined) {
				return undefined;
			}
			return getter.call(receiver);
		}
	}
};

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : { default: obj };
}

function _objectWithoutProperties(obj, keys) {
	const target = {};
	for (const i in obj) {
		if (keys.indexOf(i) >= 0) continue;
		if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
		target[i] = obj[i];
	}
	return target;
}

function _classCallCheck(instance, Constructor) {
	if (!(instance instanceof Constructor)) {
		throw new TypeError('Cannot call a class as a function');
	}
}

function _inherits(subClass, superClass) {
	if (typeof superClass !== 'function' && superClass !== null) {
		throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
	}
	subClass.prototype = Object.create(superClass && superClass.prototype, {
		constructor: {
			value: subClass,
			enumerable: false,
			writable: true,
			configurable: true
		}
	});
	if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

const _react = require('react');

const _react2 = _interopRequireDefault(_react);

const _reactDom = require('react-dom');

const _reactDom2 = _interopRequireDefault(_reactDom);

// var _classnames = require('classnames');

// var _classnames2 = _interopRequireDefault(_classnames);

// const _dynamicsJs = require('dynamics.js');
//
// const _dynamicsJs2 = _interopRequireDefault(_dynamicsJs);

const _reactCenterComponent = require('./ReactCenterComponent');

const _reactCenterComponent2 = _interopRequireDefault(_reactCenterComponent);

const _CloseCircle = require('./CloseCircle');

const _CloseCircle2 = _interopRequireDefault(_CloseCircle);

const _activeEventStack = require('./activeEventStack');

const _activeEventStack2 = _interopRequireDefault(_activeEventStack);

const _keycode = require('keycode');

const _keycode2 = _interopRequireDefault(_keycode);

// var _narcissus = require('narcissus');

const defaultStyles = {
	dialog: {
		// boxSizing: 'border-box',
		// position: 'relative',
		// background: 'white',
		// padding: 20,
		// color: '#333',
		// boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.4)',
		// borderRadius: 10,
	},
	closeButton: {
		// position: 'absolute',
		// top: -15,
		// left: 'auto',
		// right: -15,
		// cursor: 'pointer',
		// display: 'block',
		// width: 40,
		// height: 40,
		// transition: 'transform 0.1s',
		// backgroundImage: require('../images/modal-dialog-close.png'),
		// backgroundRepeat: 'no-repeat',
		// backgroundSize: '40px 40px',
		'&&:hover': {
			transform: 'scale(1.1)',
		},
	},
};


// This decorator centers the dialog

export const ModalDialog = (function (_React$Component) {
	_inherits(ModalDialog, _React$Component);

	function ModalDialog() {
		const _this = this;

		_classCallCheck(this, _ModalDialog);

		_get(Object.getPrototypeOf(_ModalDialog.prototype), 'constructor', this).apply(this, arguments);

		this.componentWillMount = function () {
			/**
			 * This is done in the componentWillMount instead of the componentDidMount
			 * because this way, a modal that is a child of another will have register
			 * for events after its parent
			 */
			_this.eventToken = _activeEventStack2.default.addListenable([['click', _this.handleGlobalClick], ['keydown', _this.handleGlobalKeydown]]);
		};

		this.componentWillReceiveProps = function (nextProps) {
			// if (nextProps.topOffset !== null && _this.props.topOffset === null) {
			// 	// This means we are getting top information for the first time
			// 	if (!_this.didAnimateInAlready) {
			// 		// Double check we have not animated in yet
			// 		_this.animateIn();
			// 	}
			// }
			//
			// if (nextProps.componentIsLeaving && !_this.props.componentIsLeaving) {
			// 	const node = _reactDom2.default.findDOMNode(_this);
			// 	_dynamicsJs2.default.animate(node, {
			// 		scale: 1.2,
			// 		opacity: 0,
			// 	}, {
			// 		duration: animationDisable() ? 1 : 200,
			// 		type: _dynamicsJs2.default.easeIn,
			// 	});
			// }
		};

		this.componentWillUnmount = function () {
			_activeEventStack2.default.removeListenable(_this.eventToken);
		};

		this.didAnimateInAlready = false;

		this.shouldClickDismiss = function (event) {
			const target = event.target;

			// This piece of code isolates targets which are fake clicked by things
			// like file-drop handlers
			if (target.tagName === 'INPUT' && target.type === 'file') {
				return false;
			}
			if (!_this.props.dismissOnBackgroundClick) {
				if (target !== _this.refs.self || _this.refs.self.contains(target)) return false;
			} else if (target === _this.refs.self || _this.refs.self.contains(target)) return false;
			return true;
		};

		this.handleGlobalClick = function (event) {
			if (_this.shouldClickDismiss(event)) {
				if (typeof _this.props.onClose == 'function') {
					_this.props.onClose();
				}
			}
		};

		this.handleGlobalKeydown = function (event) {
			if ((0, _keycode2.default)(event) === 'esc') {
				if (typeof _this.props.onClose == 'function') {
					_this.props.onClose();
				}
			}
		};

		// this.animateIn = function () {
		// 	_this.didAnimateInAlready = true;
		//
		// 	// Animate this node once it is mounted
		// 	const node = _reactDom2.default.findDOMNode(_this);
		//
		// 	// This sets the scale...
		// 	if (document.body.style.transform == null) {
		// 		node.style.WebkitTransform = 'scale(0.5)';
		// 	} else {
		// 		node.style.transform = 'scale(0.5)';
		// 	}
		//
		// 	_dynamicsJs2.default.animate(node, {
		// 		scale: 1,
		// 	}, {
		// 		type: _dynamicsJs2.default.spring,
		// 		duration: animationDisable() ? 1 : 400,
		// 		friction: 400,
		// 	});
		// };

		this.render = function () {
			const _props = _this.props;
			const children = _props.children;
			const className = _props.className || _props.class;
			const componentIsLeaving = _props.componentIsLeaving;
			const // eslint-disable-line no-unused-vars, this line is used to remove parameters from rest
				left = _props.left;
			const // eslint-disable-line no-unused-vars, this line is used to remove parameters from rest
				leftOffset = _props.leftOffset;
			const margin = _props.margin;
			const onClose = _props.onClose;
			const recenter = _props.recenter;
			const // eslint-disable-line no-unused-vars, this line is used to remove parameters from rest
				style = _props.style || {};
			const closeButtonStyle = _props.closeButtonStyle || {}
			const top = _props.top;
			const // eslint-disable-line no-unused-vars, this line is used to remove parameters from rest
				topOffset = _props.topOffset;
			const width = _props.width;
			const dismissOnBackgroundClick = _props.dismissOnBackgroundClick;

			const rest = _objectWithoutProperties(_props, ['children', 'class', 'className', 'componentIsLeaving', 'left', 'leftOffset', 'margin', 'onClose', 'recenter', 'style', 'top', 'topOffset', 'width', 'dismissOnBackgroundClick']);

			const dialogStyle = '';
			// const dialogStyle = _extends(
			//   defaultStyles.dialog, {
			//     position: 'absolute',
			//     marginBottom: margin,
			//     width,
			//     top: Math.max(topOffset, margin),
			//     left: leftOffset,
			//   }, style);

			// var divClassName = (0, _classnames2['default'])((0, _narcissus.inject)(styles.dialog), className);

			return _react2.default.createElement(
				'div',
				_extends({}, rest, {
					ref: 'self',
					className,
					// className: divClassName,
					style: { ...dialogStyle },
				}),
				onClose ? _react2.default.createElement(
					'a',
					{ className: 'closeButton', style: { ...defaultStyles.closeButton, ...closeButtonStyle }, onClick: onClose },
					_react2.default.createElement(_CloseCircle2.default, { diameter: 40 })
				) : null,
				children
			);
		};
	}

	_createClass(ModalDialog, null, [{
		key: 'propTypes',
		value: {
			onClose: PropTypes.func, // required for the close button
			className: PropTypes.string, // css class in addition to .ReactModalDialog
			width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // width
			topOffset: PropTypes.number, // injected by @centerComponent
			leftOffset: PropTypes.number, // injected by @centerComponent
			margin: PropTypes.number.isRequired, // the margin around the dialog
			children: PropTypes.node,
			componentIsLeaving: PropTypes.bool,
			style: PropTypes.object,
			closeButtonStyle: PropTypes.object,
			left: PropTypes.number,
			recenter: PropTypes.func.isRequired,
			top: PropTypes.number,
			dismissOnBackgroundClick: PropTypes.bool,
		},
		enumerable: true,
	}, {
		key: 'defaultProps',
		value: {
			width: 'auto',
			margin: 20,
			dismissOnBackgroundClick: true,
		},
		enumerable: true,
	}]);

	var _ModalDialog = ModalDialog;
	ModalDialog = (0, _reactCenterComponent2.default)(ModalDialog) || ModalDialog;
	return ModalDialog;
}(_react2.default.Component));

export default ModalDialog
