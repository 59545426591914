import React from 'react';

import { manageActions } from 'recruitment/common/actions';
import Clipboard from 'react-clipboard.js';

const { logout, updateAppointment, updateApplicant, refuseInterview,absentInterview,updateField, updateAnnouce, fetchManager,confirmResult ,updateContact} = manageActions;
import { connect } from 'react-redux';

import { ButtonLogout, Table } from 'recruitment/browser/components';
import Appointment from './Appointment';
import Textarea from 'react-textarea-autosize';

class HR extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			currentContentTab: 0,
			changed: false,
			editing: null
		}
	}

	changeContentTab(index) {
		this.setState({ currentContentTab: index })
	}

	handleFetch(){
		const { login, fetchManager } = this.props

		fetchManager()
	}

	refuseButton(code, index) {
		return <button class="hrbutton refusebutton" title="ติดต่อแล้วปฏิเสธ" onClick={this.handleRefuseButton.bind(this, code, index)}><span
			class="glyphicon glyphicon-remove"></span></button>
	}

	handleRefuseButton(code, index) {
		const { manager, refuseInterview } = this.props
		const { data_row } = manager.department.contact[ code ][ 'list' ][ 'รอนัดสัมภาษณ์' ][ index ]
		if (confirm("ติดต่อผู้สมัครแล้ว ผู้สมัครปฏิเสธใช่หรือไม่?")) {
			refuseInterview(manager.login.google_id, code, data_row)
		}
	}

  calendarButton(text){
    return <Clipboard data-clipboard-text={text} class='hrbutton copybutton'>
       <span class="glyphicon glyphicon-download-alt"></span>
      </Clipboard>
  }

  absentButton(code,index){
    return <button class="hrbutton absentbutton" title="ไม่มาสัมภาษณ์" onClick={this.handleAbsentButton.bind(this, code, index)}>
      <span class="glyphicon glyphicon-thumbs-down"></span>
    </button>
  }

  handleAbsentButton(code,index){
    const { manager, absentInterview } = this.props
		const { data_row } = manager.department.contact[ code ][ 'list' ][ 'รอนัดสัมภาษณ์' ][ index ]
		if (confirm("ติดต่อผู้สมัครแล้ว ผู้สมัครปฏิเสธใช่หรือไม่?")) {
			absentInterview(manager.login.google_id, code, data_row)
		}
  }

  updateMemo(memo,position,row,event){//this,each['memo'],code,each['data_row']
    const { manager, updateField } = this.props
    if(memo!=event.target.value){
      try{
        updateField(manager.login.google_id, {position,row}, 'memo', event.target.value)
      }catch(e){
        console.log(e)
        event.target.value = memo
      }
    }
  }

	handleAnnouceClick(type, code, row, event) {
		if (event.button != 0){ return } //only left mouse click
		console.log(type, code, row, event)
		const { manager, updateAnnouce } = this.props
		updateAnnouce( code, row, type)//manager.login.google_id,
	}

	annouceButton(type, code, row) {
		let icon = 'glyphicon '
    var type_title = '';
		switch (type) {
      case 'tel':
        type_title = 'โทรแจ้งแล้ว'
				icon += 'glyphicon-phone'
				break
      case 'email':
        type_title ='แจ้งทางเมล์แล้ว';
				icon += 'glyphicon-envelope'
				break
			default:
				return null
		}
		//using onClick is interfered by onBlur
		return <button title={type_title} onMouseDown={this.handleAnnouceClick.bind(this, type, code, row)}>
			<span class={icon}/>
		</button>
	}

	confirmJobButton(position,row,index){
		return <button class="hrbutton confirmbutton" title="ยืนยันเข้าร่วมงาน" onClick={this.handleConfirmJobButton.bind(this, position, index,row)}>
			<span class="glyphicon glyphicon-thumbs-up"></span>
		</button>
	}

	handleConfirmJobButton(code,index,row,event){ //todo P'Mike
    const { manager, confirmResult } = this.props
		// const { data_row } = manager.department.contact[ code ][ 'list' ][ 'แจ้งผลการสัมภาษณ์' ][ index ]
		if (confirm("ติดต่อผู้สมัครแล้ว ผู้ยืนยันเข้าร่วมงานใช่หรือไม่?")) {
			// absentInterview(manager.login.google_id, code, data_row)
     		 confirmResult(manager.login.google_id ,code,row ,'ยืนยัน');
			event.currentTarget.className += " active" //add active class
		}
	}

	refuseJobButton(position,row,index){
		return <button class="hrbutton refusebutton" title="ปฏิเสธเข้าร่วมงาน" onClick={this.handleRefuseJobButton.bind(this, position, index,row)}>
			<span class="glyphicon glyphicon-thumbs-down"></span>
		</button>
	}

	handleRefuseJobButton(code,index,row,event){ //todo P'Mike
		const { manager, confirmResult } = this.props
		// const { data_row } = manager.department.contact[ code ][ 'list' ][ 'แจ้งผลการสัมภาษณ์' ][ index ]
		if (confirm("ติดต่อผู้สมัครแล้ว ผู้สมัครปฏิเสธเข้าร่วมงานใช่หรือไม่?")) {
			// absentInterview(manager.login.google_id, code, data_row)
      		confirmResult(manager.login.google_id ,code,row ,'ปฏิเสธ');
			event.currentTarget.className += " active" //add active class
		}
	}

	//Todo : move to component
	handleChange(index, field, event) {
		this.props.updateApplicant(index, field, event.target.value)
		this.setState({ changed: true })
	}
  handleContactChange(index, field,group,code, event) {
	  //for local change , server change use onBlur
    //now result only memo
    this.props.updateContact(index, field, group,code,event.target.value)
    this.setState({ changed: true })//to upload to server
  }
	handleUpdate(index,position,row, field, event) {//code,each['data_row']
		//plug concept
    // on change = set to applicant (problem = applicant not apply to UI)
    // on blur = update applicant to server
	  const { manager, updateField } = this.props
		if (this.state.changed) {//prevent multiple load on 1st run
			this.setState({ editing: { index, field, position } })
      //manager.department.applicant[ index ] old
			updateField(manager.login.google_id, {position,row} , field, event.target.value)
			this.setState({  changed: false })//editing: null,
		}
	}
	checkUpdate(index, field, position) {
		const { editing } = this.state
		if (editing) {
			if (editing.index === index && editing.field === field && editing.position === position) {
				return field + " updating"
			}
		}
		return field
	}

	formatDate(date, withTime = false){

		let d = new Date(date)

		if(withTime){
			// format : วัน / เดือน / ปี เวลา ชั่วโมง : นาที น.
			return ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear() + "  " + ("0"+d.getHours()).slice(-2) + ":" + ("0"+d.getMinutes()).slice(-2) + " น."
		}else{
			// format : วัน / เดือน / ปี
			return ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear()
		}
	}

	waitList() {
		const { contact } = this.props.manager.department
		let ret = [];
		let sum_count = 0;
		const header = [
			{ text: 'ชื่อ - นามสกุล', class: 'fullname' },
			{ text: 'ชื่อเล่น', class: 'nickname' },
			{ text: 'E-mail', class: 'e-mail' },
			{ text: 'โทรศัพท์', class: 'phone' },
			{ text: 'ปฏิเสธ?', class: 'refuse' },
			{ text: 'นัดสัมภาษณ์', class: 'appointment' },
			{ text: 'copy', class: 'copy' },
			{ text: 'ไม่มาสัมภาษณ์', class: 'absent' },
			{ text: 'หมายเหตุ', class: 'memo' }
		]

		for (var code in contact) {
			if (!contact[ code ][ 'list' ][ 'รอนัดสัมภาษณ์' ]) {
				continue;
			}
			const caption = contact[ code ][ 'position' ]
			const headerTag = header ? header.map((label, i) => <div class={label.class} key={i}>{label.text}</div>) : null
			const classposition = 'positions pos_'+code
			const data = contact[ code ][ 'list' ][ 'รอนัดสัมภาษณ์' ].map((each, i) => {
				sum_count++
				return (<div class={'applicant ' + i + (each['appoint_date'] ? " has_date" : "")} key={i}><div class="arow" key={code + i} title={each['opinion']}>
					<div class="fullname">{each[ 'fullname' ]}</div>
					<div class="nickname">{each[ 'nickname' ]}</div>
					<div class="e-mail">{each[ 'e-mail' ]}</div>
					<div class="phone">{each[ 'phone' ]}</div>
					<div class="refuse">{this.refuseButton(code, i)}</div>
					<div class="appointment"><Appointment step='900' zone='รอนัดสัมภาษณ์' code={code} index={i}/></div>
					<div class="copy">{this.calendarButton(each['calendar'])}</div>
					<div class="absent">{this.absentButton(code, i)}</div>
					<div class="memo"><Textarea defaultValue={each['memo']} onBlur={this.updateMemo.bind(this,each['memo'],code,each['data_row'])}/></div>
				</div><div class="arow"><div class="opinion" title={each['opinion']}><span class="glyphicon glyphicon-comment"></span> {each['opinion']}</div></div>
				</div>)
			} )

			ret.push(<div class={classposition}><h3 class="position_header">{caption}</h3><div class="arow tablehead">{headerTag}</div>{data}</div>)
		}

		if (ret.length > 0) {
			return <div class="waitlist"><h2 class="header">รอนัดสัมภาษณ์ ({sum_count} คน)</h2>
				{ret}
			</div>
		} else {
			return <div class="waitlist"><h2 class="header">รอนัดสัมภาษณ์ ({sum_count} คน)</h2>
				<div class="center">ไม่มีรายชื่อรอนัดสัมภาษณ์</div>
			</div>
		}
	}

	choiceList() {
		const { contact } = this.props.manager.department
		let ret = [];
		let sum_count = 0;
		const header = [
			{ text: 'ชื่อ - นามสกุล', class: 'fullname' },
			{ text: 'ชื่อเล่น', class: 'nickname' },
			{ text: 'E-mail', class: 'e-mail' },
			{ text: 'โทรศัพท์', class: 'phone' },
			{ text: 'ปฏิเสธ?', class: 'refuse' },
			{ text: 'นัดสัมภาษณ์', class: 'appointment' }
		]
		for (var code in contact) {
			if (!contact[ code ][ 'list' ][ 'เอาไว้เป็นตัวเลือก' ]) {
				continue
			}
			const caption = contact[ code ][ 'position' ]
			const headerTag = header ? header.map((label, i) => <div class={label.class} key={i}>{label.text}</div>) : null
			const classposition = 'positions pos_'+code
			const data = contact[ code ][ 'list' ][ 'เอาไว้เป็นตัวเลือก' ].map((each, i) => {
				sum_count++
				return (<div class='applicant' key={i}><div class="arow" key={code + i} title={each['opinion']}>
					<div class="fullname">{each[ 'fullname' ]}</div>
					<div class="nickname">{each[ 'nickname' ]}</div>
					<div class="e-mail">{each[ 'e-mail' ]}</div>
					<div class="phone">{each[ 'phone' ]}</div>
					<div class="refuse">{this.refuseButton(code, i)}</div>
					<div class="appointment"><Appointment step='900' zone='เอาไว้เป็นตัวเลือก' code={code} index={i}/></div>
				</div>
					<div class="arow"><div class="opinion" title={each['opinion']}><span class="glyphicon glyphicon-comment"></span> {each['opinion']}</div></div></div>)
			})

			ret.push(<div class={classposition}><h3 class="position_header">{caption}</h3><div class="arow tablehead">{headerTag}</div>{data}</div>)

		}
		if (ret.length > 0) {
			return <div class="choicelist"><h2 class="header">รายชื่อสำรอง ({sum_count} คน)</h2>
				{ret}
			</div>
		} else {
			return <div class="choicelist"><h2 class="header">รายชื่อสำรอง ({sum_count} คน)</h2>
				<div class="center">ไม่มีรายชื่อสำรอง</div>
			</div>
		}
	}

	resultList() { //Todo : For P'Mike
		const { contact } = this.props.manager.department
		let ret = [];
		let sum_count = 0;
		const header = [
			{ text: 'ชื่อ - นามสกุล', class: 'fullname' },
			{ text: 'ชื่อเล่น', class: 'nickname' },
			{ text: 'E-mail', class: 'e-mail' },
			{ text: 'โทรศัพท์', class: 'phone' },
			{ text: 'วันนัดสัมภาษณ์', class: 'appointment_text' },
			{ text: 'ผลสัมภาษณ์', class: 'result' },
			{ text: 'หมายเหตุ', class: 'memo' }
		]

		// console.log("contact", contact)

		for (var code in contact) {
			if (!contact[ code ][ 'list' ][ 'รอผลการสัมภาษณ์' ]) {
				continue;
			}
			const caption = contact[ code ][ 'position' ]
			const headerTag = header ? header.map((label, i) => <div class={label.class} key={i}>{label.text}</div>) : null
			const classposition = 'positions pos_'+code
			const data = contact[ code ][ 'list' ][ 'รอผลการสัมภาษณ์' ].map((each, i) => {
				sum_count++
				return (<div class={"applicant " + i + (each[ 'warning' ] ? ' warning' : '')} key={i}>
					<div class="arow" key={code + i} title={each[ 'warning' ] ? "ควรแจ้งผลสัมภาษณ์แล้ว เนื่องจากเกิน 14 วันจากวันที่นัดสัมภาษณ์" : ""}>
						<div class="fullname">{each[ 'fullname' ]}</div>
						<div class="nickname">{each[ 'nickname' ]}</div>
						<div class="e-mail">{each[ 'e-mail' ]}</div>
						<div class="phone">{each[ 'phone' ]}</div>
						<div class="appointment_text">{this.formatDate(each[ 'appoint_date' ], true)}</div>
{/*						<div class="result">component select เลือกทำงาน</div>*/}
						<div class={this.checkUpdate(i, 'result', code)}><select value={each.result} onChange={this.handleContactChange.bind(this, i, 'result', 'รอผลการสัมภาษณ์', code)}
													onBlur={this.handleUpdate.bind(this, i,code,each['data_row'], 'result')}>
							<option value=""></option>
							<option value="รับเข้าทำงาน">รับเข้าทำงาน</option>
							<option value="ไม่รับเข้าทำงาน">ไม่รับเข้าทำงาน</option>
							<option value="ไว้พิจารณาคราวหน้า">ไว้พิจารณาคราวหน้า</option>
							<option value="ไม่มาสัมภาษณ์">ไม่มาสัมภาษณ์</option>
						</select></div>
						<div class="memo"><Textarea defaultValue={each['memo']} onBlur={this.updateMemo.bind(this,each['memo'],code,each['data_row'])}/></div>
					</div>
					{/*<div class="arow"><div class="opinion" title={each['opinion']}><span class="glyphicon glyphicon-comment"></span> {each['opinion']}</div></div>*/}
				</div>)
			})

			ret.push(<div class={classposition}><h3 class="position_header">{caption}</h3><div class="arow tablehead">{headerTag}</div>{data}</div>)

		}
		if (ret.length > 0) {
			return <div class="resultlist"><h2 class="header">รอผลสัมภาษณ์ ({sum_count} คน)</h2>
				{ret}
			</div>
		} else {
			return <div class="resultlist"><h2 class="header">รอผลสัมภาษณ์ ({sum_count} คน)</h2>
				<div class="center">ไม่มีรายชื่อรอผลสัมภาษณ์</div>
			</div>
		}
	}

	rejectList() {
		const { contact } = this.props.manager.department
		let ret = [];
		let sum_count = 0;
		const header = [
			{ text: 'ชื่อ - นามสกุล', class: 'fullname' },
			{ text: 'ชื่อเล่น', class: 'nickname' },
			{ text: 'E-mail', class: 'e-mail' },
			{ text: 'โทรศัพท์', class: 'phone' },
			{ text: 'วันนัดสัมภาษณ์', class: 'appointment_text' },
			{ text: 'แจ้งผลทาง?', class: 'announce' },
			{ text: 'หมายเหตุ', class: 'memo' }
		]

		for (var code in contact) {
			if (!contact[ code ][ 'list' ][ 'แจ้งผลการสัมภาษณ์' ]) {
				continue
			}

			let count = 0;

			const caption = contact[ code ][ 'position' ]
			const headerTag = header ? header.map((label, i) => <div class={label.class} key={i}>{label.text}</div>) : null
			const classposition = 'positions pos_'+code
			const data = contact[ code ][ 'list' ][ 'แจ้งผลการสัมภาษณ์' ].map((each, i) => {
				if (each['result'] != "ไม่รับเข้าทำงาน"){
					return false
				}
				count++
				sum_count++
				return (<div class={"applicant " + i + (each[ 'warning' ] ? ' warning' : '')} key={i}><div class="arow" key={code + i} title={each[ 'warning' ] ? "ควรแจ้งผลสัมภาษณ์แล้ว เนื่องจากเกิน 14 วันจากวันที่นัดสัมภาษณ์" : ""}>
					<div class="fullname">{each[ 'fullname' ]}</div>
					<div class="nickname">{each[ 'nickname' ]}</div>
					<div class="e-mail">{each[ 'e-mail' ]}</div>
					<div class="phone">{each[ 'phone' ]}</div>
					<div class="appointment_text">{this.formatDate(each[ 'appoint_date' ], true)}</div>
					<div class={this.checkUpdate(i, 'announce', code)}>
						<div class="announce-mail">{this.annouceButton('email', code, each[ 'data_row' ])}</div>
						{/*<div class="announce-call">{this.annouceButton('โทรแจ้งแล้ว', code, each[ 'data_row' ])}</div>*/}
					</div>
					<div class="memo"><Textarea
            value={each['memo']}
            onChange={this.handleContactChange.bind(this, i, 'memo','แจ้งผลการสัมภาษณ์',code)}
            // onBlur={this.updateMemo.bind(this,each['memo'],code,each['data_row'])}
            onBlur={this.handleUpdate.bind(this, i,code,each['data_row'], 'memo')}

					/></div>
				</div>
					{/*<div class="arow"><div class="opinion" title={each['opinion']}><span class="glyphicon glyphicon-comment"></span> {each['opinion']}</div></div>*/}
				</div>)
			})

			if (count > 0){
				ret.push(<div class={classposition}><h3 class="position_header">{caption}</h3><div class="arow tablehead">{headerTag}</div>{data}</div>)
			}

		}
		if (ret.length > 0) {
			return <div class="rejectlist"><h2 class="header">รอแจ้งผลไม่ผ่าน ({sum_count} คน)</h2>
				{ret}
			</div>
		} else {
			return <div class="rejectlist"><h2 class="header">รอแจ้งผลไม่ผ่าน ({sum_count} คน)</h2>
				<div class="center">ไม่มีรายชื่อรอแจ้งผลไม่ผ่าน</div>
			</div>
		}
	}

	acceptList() {
		const { contact } = this.props.manager.department
		let ret = [];
		let sum_count = 0;
		const header = [
			{ text: 'ชื่อ - นามสกุล', class: 'fullname' },
			{ text: 'ชื่อเล่น', class: 'nickname' },
			{ text: 'E-mail', class: 'e-mail' },
			{ text: 'โทรศัพท์', class: 'phone' },
			{ text: 'วันเริ่มทำงาน', class: 'begin' },
			{ text: 'เงินเดือน', class: 'salary' },
			{ text: 'แจ้งผลทาง?', class: 'announce' },
			{ text: 'ยืนยัน?', class: 'confirm' },
			{ text: 'ปฏิเสธ?', class: 'refuse' },
			{ text: 'หมายเหตุ', class: 'memo' }
		]

		// console.log("contact", contact)

		for (var code in contact) {
			if (!contact[ code ][ 'list' ][ 'แจ้งผลการสัมภาษณ์' ]) {
				continue
			}

			let count = 0;

			const caption = contact[ code ][ 'position' ]
			const headerTag = header ? header.map((label, i) => <div class={label.class} key={i}>{label.text}</div>) : null
			const classposition = 'positions pos_'+code
			const data = contact[ code ][ 'list' ][ 'แจ้งผลการสัมภาษณ์' ].map((each, i) => {
				if (each['result'] != "รับเข้าทำงาน"){
					return false
				}
				count++
				sum_count++
				return (<div class={"applicant " + i + (each[ 'warning' ] ? ' warning' : '')} key={i}><div class="arow" key={code + i} title={each[ 'warning' ] ? "ควรแจ้งผลสัมภาษณ์แล้ว เนื่องจากเกิน 14 วันจากวันที่นัดสัมภาษณ์" : ""}>
					<div class="fullname">{each[ 'fullname' ]}</div>
					<div class="nickname">{each[ 'nickname' ]}</div>
					<div class="e-mail">{each[ 'e-mail' ]}</div>
					<div class="phone">{each[ 'phone' ]}</div>
					<div class={this.checkUpdate(i, 'begin', code)}><input type="date" defaultValue={each.begin} onChange={this.handleChange.bind(this, i, 'begin')} onBlur={this.handleUpdate.bind(this, i,code,each['data_row'], 'begin')}/></div>
					<div class={this.checkUpdate(i, 'salary', code)}><Textarea defaultValue={each.salary} onChange={this.handleChange.bind(this, i, 'salary')} onBlur={this.handleUpdate.bind(this, i,code,each['data_row'], 'salary')}></Textarea></div>
					<div class="announce">
						<div class="announce-call">{this.annouceButton('tel', code, each[ 'data_row' ])}</div>
						<div class="announce-mail">{this.annouceButton('email', code, each[ 'data_row' ])}</div>
					</div>
					<div class="confirm">{this.confirmJobButton(code,each['data_row'], i)}</div>
					<div class="refuse">{this.refuseJobButton(code,each['data_row'], i)}</div>
					<div class="memo"><Textarea value={each['memo']}
												onChange={this.handleContactChange.bind(this, i, 'memo','แจ้งผลการสัมภาษณ์',code)}
												// onBlur={this.updateMemo.bind(this,each['memo'],code,each['data_row'])}
												onBlur={this.handleUpdate.bind(this, i,code,each['data_row'], 'memo')}
							/>
					</div>
				</div>
					{/*<div class="arow"><div class="opinion" title={each['opinion']}><span class="glyphicon glyphicon-comment"></span> {each['opinion']}</div></div>*/}
				</div>)
			})

			if (count > 0) {
				ret.push(<div class={classposition}><h3 class="position_header">{caption}</h3><div class="arow tablehead">{headerTag}</div>{data}</div>)
			}

		}
		if (ret.length > 0) {
			return <div class="acceptlist"><h2 class="header">รอแจ้งผลรับเข้าทำงาน ({sum_count} คน)</h2>
				{ret}
			</div>
		} else {
			return <div class="acceptlist"><h2 class="header">รอแจ้งผลรับเข้าทำงาน ({sum_count} คน)</h2>
				<div class="center">ไม่มีรายชื่อรอแจ้งเข้ารับทำงาน</div>
			</div>
		}
	}

	employeeList() {
		const { contact } = this.props.manager.department
		let ret = [];
		let sum_count = 0;
		const header = [
			{ text: 'ชื่อ - นามสกุล', class: 'fullname' },
			{ text: 'ชื่อเล่น', class: 'nickname' },
			{ text: 'E-mail', class: 'e-mail' },
			{ text: 'โทรศัพท์', class: 'phone' },
			{ text: 'วันเริ่มทำงาน', class: 'begin' },
			{ text: 'เงินเดือน', class: 'salary' },
			{ text: 'หมายเหตุ', class: 'memo' }
		]

		// console.log("contact", contact)

		for (var code in contact) {
			if (!contact[ code ][ 'list' ][ 'ยืนยันร่วมงาน' ]) {
				continue
			}

			let count = 0;

			const caption = contact[ code ][ 'position' ]
			const headerTag = header ? header.map((label, i) => <div class={label.class} key={i}>{label.text}</div>) : null
			const classposition = 'positions pos_'+code
			const data = contact[ code ][ 'list' ][ 'ยืนยันร่วมงาน' ].map((each, i) => {
				count++
				sum_count++
				return (<div class={"applicant " + i} key={i}><div class="arow" key={code + i}>
					<div class="fullname">{each[ 'fullname' ]}</div>
					<div class="nickname">{each[ 'nickname' ]}</div>
					<div class="e-mail">{each[ 'e-mail' ]}</div>
					<div class="phone">{each[ 'phone' ]}</div>
					<div class="begin">{this.formatDate(each['begin'])}</div>
					<div class="salary">{each['salary']}</div>
					<div class="memo"><Textarea value={each['memo']}
												onChange={this.handleContactChange.bind(this, i, 'memo','ยืนยันร่วมงาน',code)}
												onBlur={this.handleUpdate.bind(this, i,code,each['data_row'], 'memo')}
					/>
					</div>
				</div>
					{/*<div class="arow"><div class="opinion" title={each['opinion']}><span class="glyphicon glyphicon-comment"></span> {each['opinion']}</div></div>*/}
				</div>)
			})

			if (count > 0) {
				ret.push(<div class={classposition}><h3 class="position_header">{caption}</h3><div class="arow tablehead">{headerTag}</div>{data}</div>)
			}

		}
		if (ret.length > 0) {
			return <div class="employeelist"><h2 class="header">รายชื่อยืนยันร่วมงาน ({sum_count} คน)</h2>
				{ret}
			</div>
		} else {
			return <div class="employeelist"><h2 class="header">รายชื่อยืนยันร่วมงาน ({sum_count} คน)</h2>
				<div class="center">ไม่มีรายชื่อยืนยันร่วมงาน</div>
			</div>
		}
	}

	render() {
		const { manager, logout } = this.props

		let { currentContentTab } = this.state

		let sumCountWait = 0
		let sumCountChoice = 0
		let sumCountResult = 0
		let sumCountReject = 0
		let sumCountAccept = 0
		let sumCountEmployee = 0

		const { contact } = this.props.manager.department
		for (var code in contact) {
			if (contact[code]['list']['รอนัดสัมภาษณ์']) {
				sumCountWait += contact[code]['list']['รอนัดสัมภาษณ์'].length
			}
			if (contact[code]['list']['เอาไว้เป็นตัวเลือก']) {
				sumCountChoice += contact[code]['list']['เอาไว้เป็นตัวเลือก'].length
			}
			if (contact[code]['list']['รอผลการสัมภาษณ์']) {
				sumCountResult += contact[code]['list']['รอผลการสัมภาษณ์'].length
			}
			if (contact[code]['list']['แจ้งผลการสัมภาษณ์']) {
				{contact[ code ][ 'list' ][ 'แจ้งผลการสัมภาษณ์' ].map((each, i) => {
					if (each && each['result'] == "รับเข้าทำงาน"){
						return sumCountAccept += 1
					}else if (each && each['result'] == "ไม่รับเข้าทำงาน"){
						return sumCountReject += 1
					}
				})}
			}
			if (contact[code]['list']['ยืนยันร่วมงาน']) {
				sumCountEmployee += contact[code]['list']['ยืนยันร่วมงาน'].length
			}
		}


		// console.log("manager", manager)

		return <div id="hr">
			{/*<a class="link" onClick={this.handleFetch.bind(this)}><span class="glyphicon glyphicon-refresh"></span> โหลดอีกครั้ง</a>*/}
			{/* <div class="header">Lnw HR</div> */}

			<div class="navlist">
				<a class={"link " + (currentContentTab == 0 ? "all" : "")} onClick={this.changeContentTab.bind(this,0)}>แสดงทั้งหมด</a>
				<a class={"link " + (currentContentTab == 1 ? "wait" : "")} onClick={this.changeContentTab.bind(this,1)}>รอนัดสัมภาษณ์{sumCountWait > 0 ? " ("+sumCountWait+")" : "" }</a>
				{/*<a class={"link " + (currentContentTab == 2 ? "choice" : "")} onClick={this.changeContentTab.bind(this,2)}>รายชื่อสำรอง{sumCountChoice > 0 ? " ("+sumCountChoice+")" : "" }</a>*/}
				<a class={"link " + (currentContentTab == 3 ? "result" : "")} onClick={this.changeContentTab.bind(this,3)}>รอผลสัมภาษณ์{sumCountResult > 0 ? " ("+sumCountResult+")" : "" }</a>
				<a class={"link " + (currentContentTab == 4 ? "reject" : "")} onClick={this.changeContentTab.bind(this,4)}>รอแจ้งผลไม่ผ่าน{sumCountReject > 0 ? " ("+sumCountReject+")" : "" }</a>
				<a class={"link " + (currentContentTab == 5 ? "accept" : "")} onClick={this.changeContentTab.bind(this,5)}>รอแจ้งผลรับเข้าทำงาน{sumCountAccept > 0 ? " ("+sumCountAccept+")" : "" }</a>
				<a class={"link " + (currentContentTab == 6 ? "employee" : "")} onClick={this.changeContentTab.bind(this,6)}>รายชื่อยืนยันร่วมงาน{sumCountEmployee > 0 ? " ("+sumCountEmployee+")" : "" }</a>
			</div>

			{ currentContentTab == 0 || currentContentTab == 1 ? this.waitList() : null }
			{ currentContentTab == 0 || currentContentTab == 1 ? this.choiceList() : null}
			{ currentContentTab == 0 || currentContentTab == 3 ? this.resultList() : null}
			{ currentContentTab == 0 || currentContentTab == 4 ? this.rejectList() : null}
			{ currentContentTab == 0 || currentContentTab == 5 ? this.acceptList() : null}
			{ currentContentTab == 0 || currentContentTab == 6 ? this.employeeList() : null}
{/*			<div style={{ color: 'white' }}>
				{JSON.stringify(manager.department)}
			</div>*/}
			<div class="logoutArea center"><ButtonLogout classname="button logoutbutton" label="ออกจากระบบ" handler={logout}/></div>
		</div>
	}
}

export default connect(state => ({
	manager: state.recruitment.manager
}), {
	logout,
	updateAppointment,
	updateApplicant,
	refuseInterview,
  	absentInterview,
  	updateField,
	updateAnnouce,
	fetchManager,
  confirmResult,
  updateContact
})(HR);
