/* @flow */
import React from 'react';
import { Footer, Link } from 'browser/components';
// import { FormattedMessage, defineMessages } from 'react-intl';

// const messages = defineMessages({
//   madeByHtml: {
//     defaultMessage: 'Made with love by',
//     id: 'footer.madeByHtml',
//   },
// });

const AppFooter = () => (
	<Footer>
	</Footer>
);

export default AppFooter;
