// https://blog.rescale.com/addressing-valuelink-deprecation-in-react-15/

/*
<input
  type="number"
  {...linkRedux(this, 'company.budget', (value,ele) => updateBudget(value) }
/>

<input
  type="checkbox"
  {...linkRedux(this, 'isChecked', (value,ele) => updateCheck(value), {valueFieldName:'checked'} }
/>
 */

import _ from 'common/helpers/_'

function linkRedux(component, propPath, changeCallback: ( value:any, ele:any ) => any/*result in debounce mode*/, options = {}) {
	options = { debounce: 300, valueFieldName: "value", ...options }
	options.valueFieldName = options.valueFieldName || 'value'
	let valueFieldName = options.valueFieldName;
	if(options.debounce && options.valueFieldName === 'value') {
		valueFieldName = 'defaultValue'
	}

	const getCurrentValue = () => typeof propPath == 'function' ? propPath(component) : _.get(component.props, propPath)

	let extras = {}

	if(valueFieldName == 'defaultValue'){
		const oldChangeCallback = changeCallback
		changeCallback = (value, ele) => {
			const result = oldChangeCallback(value, ele) // delay change value
			if(typeof result !== 'undefined' ){
				ele[options.valueFieldName] = result // update element
			}
		}
		extras.onBlur = (e) => {
			if(changeCallback.flush) changeCallback.flush() // flush debounce
			e.target[options.valueFieldName] = getCurrentValue()
		}
	}

	if(options.debounce) {
		changeCallback = _.debounce(changeCallback, options.debounce)
	}
	return {
		onChange: e => {
			const el = e.target;
			const value = el.type === 'checkbox' ? el.checked : el.value;

			return changeCallback(value, el)
		},
		[valueFieldName] :	getCurrentValue(),
		...extras
	}
}

export default linkRedux