import { purgePersistStore } from "common/configure/configureStore";
/* @flow weak */
import * as actions from './actions';
import { Record } from 'common/transit';
import { merge } from 'common/immutable';
import { REHYDRATE } from 'redux-persist/constants';

import { isBrowser } from "@lib/base/common";
// need basename
// import createBrowserHistory from 'history/createBrowserHistory'

const { serverDefaultRenderAsMobile } = require("common/helpers/UI")

const State = Record({
	error: null,
	errors: [],
	location: null, // createBrowserHistory().location,
	locationChangeCount: 0,
	menuShown: false,
	online: false,
	storageLoaded: false,
	global: {
		ui:{
			// init value for server render
			isMobileTemplate: serverDefaultRenderAsMobile,
			isTabletTemplate: false,
			isDesktopTemplate: !serverDefaultRenderAsMobile,
		}
	}
}, 'app');

const appReducer = (state = new State(), action) => {
	// This is how we can handle all async actions rejections.
	if (action.type && action.type.endsWith('_ERROR')) {
		let error = action.payload || {};
		if(typeof error == "object"){
			if(error.asMutable) error.asMutable = error.asMutable()
			error.type = action.type
			error.time = new Date().getTime()
		}else{
			error = { data: error , time: new Date().getTime() }
		}
		if(!state.errors) state = state.set('errors', [])
		return state.set('error', error).update('errors', errors => {
			if(!errors) errors = [];
			else if(errors.asMutable) errors = errors.asMutable()
			errors.push(error)
			return errors
		});

	}

	switch (action.type) {
		case actions.APP_START:
			return state.set('started', true);

		case actions.APP_OFFLINE:
			return state.set('online', false);

		case actions.APP_ONLINE:
			return state.set('online', true);

		case actions.APP_SET_LOCATION:
			if (!action.payload.location.url) {
				if (typeof window !== 'undefined' && window.location) {
					action.payload.location.url = window.location.href
				} else {
					action.payload.location.url = ''
				}
			}
			return state.set('location', action.payload.location)
				.update('locationChangeCount', l => l+1);

		case actions.APP_SHOW_MENU:
			return state.set('menuShown', action.payload.show);

		case actions.APP_GLOBAL_UPDATE:
			return state.update('global', value => merge(value || {}, action.payload.global, { deep: true }));

		case actions.CLEAR_CACHE:
			// use window.isCacheDisabled instead
			purgePersistStore()
			localStorage && localStorage.clear()
			if (isBrowser()) {
				window.addEventListener("beforeunload", () => {
					purgePersistStore()
					localStorage && localStorage.clear()
				})
				// $(window).off('beforeunload')
				setTimeout(() => window.location.reload(), 500)
			}
			return state

		// case actions.APP_STORAGE_LOAD:
		case REHYDRATE:
			return state.set('storageLoaded', true);

		// case actions.APP_STORAGE_RESET:
		//   return state;

		default:
			return state;

	}
};

export default appReducer;
