/* eslint-disable comma-dangle, quote-props, quotes */
import { Map } from "@lib/base/immutable";

export default new Map({
	"link": [
		{
			"rel": "apple-touch-icon",
			"sizes": "57x57",
			"href": "/assets/favicons/apple-touch-icon-57x57.png"
		},
		{
			"rel": "apple-touch-icon",
			"sizes": "60x60",
			"href": "/assets/favicons/apple-touch-icon-60x60.png"
		},
		{
			"rel": "apple-touch-icon",
			"sizes": "72x72",
			"href": "/assets/favicons/apple-touch-icon-72x72.png"
		},
		{
			"rel": "apple-touch-icon",
			"sizes": "76x76",
			"href": "/assets/favicons/apple-touch-icon-76x76.png"
		},
		{
			"rel": "apple-touch-icon",
			"sizes": "114x114",
			"href": "/assets/favicons/apple-touch-icon-114x114.png"
		},
		{
			"rel": "apple-touch-icon",
			"sizes": "120x120",
			"href": "/assets/favicons/apple-touch-icon-120x120.png"
		},
		{
			"rel": "apple-touch-icon",
			"sizes": "144x144",
			"href": "/assets/favicons/apple-touch-icon-144x144.png"
		},
		{
			"rel": "apple-touch-icon",
			"sizes": "152x152",
			"href": "/assets/favicons/apple-touch-icon-152x152.png"
		},
		{
			"rel": "apple-touch-icon",
			"sizes": "180x180",
			"href": "/assets/favicons/apple-touch-icon-180x180.png"
		},
		{
			"rel": "icon",
			"type": "image/png",
			"href": "/assets/favicons/favicon-32x32.png",
			"sizes": "32x32"
		},
		{
			"rel": "icon",
			"type": "image/png",
			"href": "/assets/favicons/android-chrome-192x192.png",
			"sizes": "192x192"
		},
		{
			"rel": "icon",
			"type": "image/png",
			"href": "/assets/favicons/favicon-16x16.png",
			"sizes": "16x16"
		},
		{
			"rel": "manifest",
			"href": "/assets/favicons/manifest.json"
		},
		{
			"rel": "mask-icon",
			"href": "/assets/favicons/safari-pinned-tab.svg",
			"color": "#5bbad5"
		},
		{
			"rel": "shortcut icon",
			"href": "/assets/favicons/favicon.ico"
		}
	],
	"meta": [
		{
			"name": "msapplication-TileColor",
			"content": "#2d89ef"
		},
		{
			"name": "msapplication-TileImage",
			"content": "/assets/favicons/mstile-144x144.png"
		},
		{
			"name": "msapplication-config",
			"content": "/assets/favicons/browserconfig.xml"
		},
		{
			"name": "theme-color",
			"content": "#ffffff"
		}
	]
});
