/* @flow */
import { Record } from 'common/transit';

export const application = {
	fullname: '',
	nickname: '',
	position: '',
	e_mail: '',
	phone_number: '',
	salary: '',
	resume: null,
	answers: []
}

export const manager = {
	department: null,
	login: null,
	fetch: false,
	error: null
}

const state = Record({
	application,
	manager,
	position: null,
	error: null,
	submited: null
}, 'recruitmentState');


export default state;
