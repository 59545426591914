import PropTypes from 'prop-types';
import React from 'react';

import { manageActions } from 'recruitment/common/actions';

const { logout } = manageActions;
import { connect } from 'react-redux';

import { ButtonLogout } from 'recruitment/browser/components';

class ManageError extends React.Component {

	static propTypes = {
		message: PropTypes.string,
	}

	render() {
		const { manager, logout } = this.props
		return <div class="wrapper">
			<div class="error">
				<h2>{manager.error}</h2>
				<ButtonLogout label="ย้อนกลับ" handler={logout}/>
			</div>
		</div>
	}
}

export default connect(state => ({ manager: state.recruitment.manager }), { logout })(ManageError);