import React from 'react';
import styles from './Manage.css';
import Moment from 'react-moment';
import 'moment-timezone';
import {
	View
}
	from 'browser/components';
import { Link } from 'react-router-dom';
import {
	connect
}
	from 'react-redux';
import { manageActions } from 'recruitment/common/actions';
import Textarea from 'react-textarea-autosize';

const { fetchManager, logout, sortApplicant, updateApplicant, updateField } = manageActions;
import { Error, Login, Fetching, HR, Manager, Commentor } from 'recruitment/browser/manage/';
import { is } from 'css-select';

class Manage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			filter: {
				position: '',
				interview: 'รอดำเนินการ',
				result: '',
			},
			order: {
				type: 'timestamp',
				reverse: false
			},
			changed: false,
			editing: null
		}
	}

	handleOrderChange(name) {
		this.setState({ order: { type: name, reverse: false } })
		const { sortApplicant, manager } = this.props
		sortApplicant(manager.department.applicant, this.state.order)
	}

	handleOrderReverse() {
		const { order } = this.state
		order.reverse = !order.reverse
		this.forceUpdate()
		const { sortApplicant, manager } = this.props
		sortApplicant(manager.department.applicant, this.state.order)
	}

	renderOrder(text, type) {
		const { order } = this.state
		if (type === order.type) {
			if (order.reverse) {
				return <a class="sortactive newest" key="reverse" onClick={this.handleOrderReverse.bind(this)}>
					{text}<span class="icon order up"/>
				</a>
			} else {
				return <a class="sortactive oldest" key="noreverse" onClick={this.handleOrderReverse.bind(this)}>
					{text}<span class="icon order down"/>
				</a>
			}
		} else {
			return <a onClick={this.handleOrderChange.bind(this, type)} key="notype">
				{text}<span class="icon order down"/>
			</a>
		}
	}

	handleFilter(id, event) {
		const { filter } = this.state
		filter[ id ] = event.target.value
		this.forceUpdate()
	}

	renderFilter(event, options) {
		return <select value={this.state.filter[ event ]} onChange={this.handleFilter.bind(this, event)}>
			{options[ event ].map((each, k) => <option event={event} key={k} value={each.value} >{each.text}</option>)}
		</select>
	}
	renderFilterTool(title,event, options){
		const classes = 'sortAction sort_'+event
		let ret
		if(event =='order'){
			ret = <div class="sortOptions"><span class="flex">
			{options[ event ].map((each, k) => this.renderOrder(each.text, each.class))}
			</span></div>
		}else{
			ret = <div class="sortOptions">{this.renderFilter(event, options)}</div>
		}
		return <div class={classes}><div class="flex">
			<span class="topic">{title}</span>
			{ret}</div>
		</div>
	}

	handleChange(index, field, event) {
		this.props.updateApplicant(index, field, event.target.value)
		this.setState({ changed: true })
	}

	handleUpdate(index, field, event) {
		const { manager, updateField } = this.props
		if (this.state.changed) {
			this.setState({ editing: { index, field } })
			updateField(manager.login.google_id, manager.department.applicant[ index ], field, event.target.value)
			this.setState({ editing: null, changed: false })
		}
	}

	checkUpdate(index, field) {
		const { editing } = this.state
		if (editing) {
			if (editing.index === index && editing.field === field) {
				return field + " updating"
			}
		}
		return field
	}

	handleFetch(){
		const { login, fetchManager } = this.props

		fetchManager()
	}

/*	renderApplicant() {
		const { department } = this.props.manager
		const { filter } = this.state
		const interviewClass = {
			เรียก: 'interest',
			ไม่เรียก: 'reject',
			รอไว้ก่อน: 'reserve'
		}
		return department.applicant.map((each, k) => {
				if (filter.position && each.position !== filter.position)
					return null
				if (filter.interview) {
					if (filter.interview === 'รอประเมิน') {
						if (each.interview) {
							return null
						}
					} else {
						if (each.interview !== filter.interview) {
							return null
						}
					}
				}
				if (filter.result && each.result !== filter.result)
					return null
				return <tr key={k} class={each.interview ? interviewClass[ each.interview ] : ""}>
					<td class="timestamp">{each.timestamp_text}</td>
					<td class="fullname">{each.fullname}</td>
					<td class="nickname">{each.nickname}</td>
					{department.type === 'super' ? <td class="phone">{each.phone}</td> : null}
					{department.type === 'super' ? <td class="e-mail">{each[ 'e-mail' ]}</td> : null}
					<td class="detail"><Link to={`manage/view/${k}`} target='_blank'>
						<button><span class="icon view"/></button>
					</Link></td>
					<td class="interview">{each.interview}</td>
					<td class={this.checkUpdate(k, 'appointment_text')}>
						{each.interview !== 'เรียก' ? null : <input type="datetime-local" value={each.appointment_text}
						onChange={this.handleChange.bind(this, k, 'appointment_text')}
						onBlur={this.handleUpdate.bind(this, k, 'appointment_text')}
						disabled={department.type !== 'super'}/>}
					</td>
					<td class={this.checkUpdate(k, 'result')}>
						{department.type !== 'super' || each.interview !== 'เรียก' ? each.result :
							<select defaultValue={each.result} onChange={this.handleChange.bind(this, k, 'result')}
											onBlur={this.handleUpdate.bind(this, k, 'result')}>
								<option value=""></option>
								<option value="ไม่มาสัมภาษณ์">ไม่มาสัมภาษณ์</option>
								<option value="ไม่รับเข้าทำงาน">ไม่รับเข้าทำงาน</option>
								<option value="รับเข้าทำงาน">รับเข้าทำงาน</option>
								<option value="ไว้พิจารณาคราวหน้า">ไว้พิจารณาคราวหน้า</option>
							</select>}
					</td>
					<td class={this.checkUpdate(k, 'begin')}>
						{department.type === 'super' && each.result === 'รับเข้าทำงาน' ?
							<input type="date" value={each.begin} onChange={this.handleChange.bind(this, k, 'begin')}
										 onBlur={this.handleUpdate.bind(this, k, 'begin')}/> : each.begin}
					</td>
					{department.type !== 'commentor' ?
						<td class={this.checkUpdate(k, 'salary')}>
							{department.type === 'super' && each.result === 'รับเข้าทำงาน' ?
								<input type="text" value={each.salary} onChange={this.handleChange.bind(this, k, 'salary')}
											 onBlur={this.handleUpdate.bind(this, k, 'salary')}/> : each.salary}
						</td>
						: null}
					{department.type === 'super' ? <td class={this.checkUpdate(k, 'memo')}><input type="text" value={each.memo} onChange={this.handleChange.bind(this, k, 'memo')} onBlur={this.handleUpdate.bind(this, k, 'memo')}/>
					</td> : null}
				</tr>
			}
		)
	}*/
	renderApplicantcard() {
		const { department } = this.props.manager
		const { filter } = this.state
		const interviewClass = {
			เรียก: 'interest',
			ไม่เรียก: 'reject',
			รอไว้ก่อน: 'reserve',
			รอประเมิน: 'waiting',
			รอดำเนินการ: 'ongoing'
		}
		const resultClass = {
			รับเข้าทำงาน: 'hired',
			ไม่รับเข้าทำงาน: 'non-hired',
			ไม่มาสัมภาษณ์: 'lost',
			ไว้พิจารณาคราวหน้า: 'hold'
		}
		// console.log("applicant", department.applicant);
		return department.applicant.map((each, k) => {
				if (filter.position && each.position !== filter.position)
					return null
				if (filter.interview) {
					if(filter.interview === 'รอดำเนินการ'){//TODO: Toggle control
						if(each.interview ==='ไม่เรียก'){
							return null
						}
						if(each.interview ==='ไม่มา'){
							return null
						}
						if(each.interview ==='ไม่มาสัมภาษณ์'){
							return null
						}
						if(each.result !==''){
							return null
						}
						// console.log(each.interview)
					}else if (filter.interview === 'รอประเมิน') {
						if (each.interview) {
							return null
						}
					} else {
						if (each.interview !== filter.interview) {
							return null
						}
					}
				}
				if (filter.result && each.result !== filter.result)
					return null
			const addresultclass = each.result ? " "+resultClass[ each.result ] : ''
			const interviewtype = (each.interview == null ? 'waiting' : interviewClass[ each.interview ])
			const classname = "applicant_row "+ interviewtype + addresultclass
			const atitle = "ดูข้อมูลของ "+each.nickname

			const _linkManager = department.type === 'super' ? "div" : Link
			const _linkSuper = department.type === 'super' ? Link : "div"
			const isSuper = department.type === 'super'

			return (<_linkManager key={k} to={`/manage/view/${k}`} title={atitle} target='_blank'>
					<div class={classname}>
						<div class="timestamp"><_linkSuper to={`/manage/view/${k}`} title={atitle} target='_blank'><span class="title">สมัครเมื่อ</span><span class="data">{each.timestamp_text}</span></_linkSuper></div>
						<div class="fullname"><_linkSuper to={`/manage/view/${k}`} title={atitle} target='_blank'><span class="title">ชื่อ</span><span class="data">{each.fullname}</span></_linkSuper></div>
						<div class="nickname"><_linkSuper to={`/manage/view/${k}`} title={atitle} target='_blank'><span class="title">ชื่อเล่น</span><span class="data">{each.nickname}</span></_linkSuper></div>
						{department.type === 'super' ? <div class="phone"><span class="title">Tel</span><span class="data">{each.phone}</span></div> : null}
						{department.type === 'super' ? <div class="e-mail"><span class="title">Email</span><span class="data">{each[ 'e-mail' ]}</span></div> : null}
						<div class="interview"><span class="title">status</span><span class="data">{each.interview}</span></div>
						<div class={this.checkUpdate(k, 'appointment_text')}>
							{each.interview !== 'เรียก' ? null : (
								each.appointment_text && !isSuper ? <span><span class="title">นัดสัมภาษณ์</span><span class="data"><Moment format="DD MMM YY, HH:mm" locale="th">{each.appointment_text}</Moment></span></span> :
								each.appointment_text && isSuper ? <span><span class="title">นัดสัมภาษณ์</span><span class="data"><input type="datetime-local" step="900" value={each.appointment_text} onChange={this.handleChange.bind(this, k, 'appointment_text')} onBlur={this.handleUpdate.bind(this, k, 'appointment_text')}/></span></span> :
								<span />
							)}
						</div>
						<div class={this.checkUpdate(k, 'result')}><span><span class="title">ผลสัมภาษณ์</span><span class="data">
							{department.type !== 'super' || each.interview !== 'เรียก' ? each.result :
								<select defaultValue={each.result} onChange={this.handleChange.bind(this, k, 'result')}
										onBlur={this.handleUpdate.bind(this, k, 'result')}>
									<option value=""></option>
									<option value="ไม่มาสัมภาษณ์">ไม่มาสัมภาษณ์</option>
									<option value="ไม่รับเข้าทำงาน">ไม่รับเข้าทำงาน</option>
									<option value="รับเข้าทำงาน">รับเข้าทำงาน</option>
									<option value="ไว้พิจารณาคราวหน้า">ไว้พิจารณาคราวหน้า</option>
								</select>}
						</span></span></div>
						<div class={this.checkUpdate(k, 'begin')}>
							{(department.type === 'super' && each.result === 'รับเข้าทำงาน') ?
								<span><span class="title">วันที่เริ่มงาน</span><span class="data"><input type="date" value={each.begin} onChange={this.handleChange.bind(this, k, 'begin')} onBlur={this.handleUpdate.bind(this, k, 'begin')}/></span></span> : <span><span class="title">วันที่เริ่มงาน</span><span class="data">{each.begin}</span></span>}
						</div>
						{department.type !== 'commentor' ?
							<div class={this.checkUpdate(k, 'salary')}><span><span class="title">เงินเดือน</span><span class="data">
								{(department.type === 'super') && each.result === 'รับเข้าทำงาน' ?
									<Textarea value={each.salary} onChange={this.handleChange.bind(this, k, 'salary')} onBlur={this.handleUpdate.bind(this, k, 'salary')}></Textarea> : each.salary}
							</span></span></div>
							: null}
						{department.type === 'super' ? <div class={this.checkUpdate(k, 'memo')}><span><span class="title">memo</span><span class="data"><Textarea rows={1} value={each.memo} onChange={this.handleChange.bind(this, k, 'memo')} onBlur={this.handleUpdate.bind(this, k, 'memo')}></Textarea></span></span>
						</div> : null}
						<div class="detail"><_linkSuper to={`/manage/view/${k}`} title={atitle} target='_blank'><span class="data">ดูข้อมูล > </span></_linkSuper></div>
					</div>
				</_linkManager>
			)
			}
		)
	}
	
/*	renderSuperApplicantcard() {
		const { department } = this.props.manager
		const { filter } = this.state
		const interviewClass = {
			เรียก: 'interest',
			ไม่เรียก: 'reject',
			รอไว้ก่อน: 'reserve',
			รอประเมิน: 'waiting',
			รอดำเนินการ: 'ongoing'
		}
		const resultClass = {
			รับเข้าทำงาน: 'hired',
			ไม่รับเข้าทำงาน: 'non-hired',
			ไม่มาสัมภาษณ์: 'lost',
			ไว้พิจารณาคราวหน้า: 'hold'
		}
		return department.applicant.map((each, k) => {
				if (filter.position && each.position !== filter.position)
					return null
				if (filter.interview) {
					if(filter.interview === 'รอดำเนินการ'){//TODO: Toggle control
						if(each.interview ==='ไม่เรียก'){
							return null
						}
						if(each.interview ==='ไม่มา'){
							return null
						}
						if(each.interview ==='ไม่มาสัมภาษณ์'){
							return null
						}
						if(each.result !==''){
							return null
						}
						console.log(each.interview)
					}else if (filter.interview === 'รอประเมิน') {
						if (each.interview) {
							return null
						}
					} else {
						if (each.interview !== filter.interview) {
							return null
						}
					}
				}
				if (filter.result && each.result !== filter.result)
					return null
				const addresultclass = each.result ? " "+resultClass[ each.result ] : ''
				const interviewtype = (each.interview == null ? 'waiting' : interviewClass[ each.interview ])
				const classname = "applicant_row "+ interviewtype + addresultclass
				const atitle = "ดูข้อมูลของ "+each.nickname
				console.log(interviewtype);

				// console.log(department.type);

				const _linkManager = department.type === 'super' ? "div" : Link
				const _linkSuper = department.type === 'super' ? Link : "div"

				return (<_linkManager key={k} to={`manage/view/${k}`} title={atitle} target='_blank'>
						<div class={classname}>
						<div class="timestamp"><_linkSuper to={`manage/view/${k}`} title={atitle} target='_blank'><span class="title">สมัครเมื่อ</span><span class="data">{each.timestamp_text}</span></_linkSuper></div>
						<div class="fullname"><_linkSuper to={`manage/view/${k}`} title={atitle} target='_blank'><span class="title">ชื่อ</span><span class="data">{each.fullname}</span></_linkSuper></div>
						<div class="nickname"><_linkSuper to={`manage/view/${k}`} title={atitle} target='_blank'><span class="title">ชื่อเล่น</span><span class="data">{each.nickname}</span></_linkSuper></div>
						{department.type === 'super' ? <div class="phone"><span class="title">Tel</span><span class="data">{each.phone}</span></div> : null}
						{department.type === 'super' ? <div class="e-mail"><span class="title">Email</span><span class="data">{each[ 'e-mail' ]}</span></div> : null}
						<div class="interview"><span class="title">status</span><span class="data">{each.interview}</span></div>
						<div class={this.checkUpdate(k, 'appointment_text')}>
							{each.interview !== 'เรียก' ? null : (each.result ? <span><span class="title">นัดสัมภาษณ์</span><span class="data"><Moment format="DD MMM YYYY, HH:mm" locale="th">{each.appointment_text}</Moment></span></span> : <span><span class="title">นัดสัมภาษณ์</span><span class="data"><input type="datetime-local" value={each.appointment_text} step="900" onChange={this.handleChange.bind(this, k, 'appointment_text')} onBlur={this.handleUpdate.bind(this, k, 'appointment_text')}/></span></span>)}
						</div>
						<div class={this.checkUpdate(k, 'result')}><span><span class="title">ผลสัมภาษณ์</span><span class="data">
							{department.type !== 'super' || each.interview !== 'เรียก' ? each.result :
								<select defaultValue={each.result} onChange={this.handleChange.bind(this, k, 'result')}
												onBlur={this.handleUpdate.bind(this, k, 'result')}>
									<option value=""></option>
									<option value="ไม่มาสัมภาษณ์">ไม่มาสัมภาษณ์</option>
									<option value="ไม่รับเข้าทำงาน">ไม่รับเข้าทำงาน</option>
									<option value="รับเข้าทำงาน">รับเข้าทำงาน</option>
									<option value="ไว้พิจารณาคราวหน้า">ไว้พิจารณาคราวหน้า</option>
								</select>}
						</span></span></div>
						<div class={this.checkUpdate(k, 'begin')}>
							{(department.type === 'super' && each.result === 'รับเข้าทำงาน') ?
									<span><span class="title">วันที่เริ่มงาน</span><span class="data"><input type="date" value={each.begin} onChange={this.handleChange.bind(this, k, 'begin')} onBlur={this.handleUpdate.bind(this, k, 'begin')}/></span></span> : <span><span class="title">วันที่เริ่มงาน</span><span class="data">{each.begin}</span></span>}
						</div>
						{department.type !== 'commentor' ?
							<div class={this.checkUpdate(k, 'salary')}><span><span class="title">เงินเดือน</span><span class="data">
								{(department.type === 'super') && each.result === 'รับเข้าทำงาน' ?
									<input type="text" value={each.salary} onChange={this.handleChange.bind(this, k, 'salary')}
												onBlur={this.handleUpdate.bind(this, k, 'salary')}/> : each.salary}
							</span></span></div>
							: null}
						{department.type === 'super' ? <div class={this.checkUpdate(k, 'memo')}><span><span class="title">memo</span><span class="data"><input type="text" value={each.memo} onChange={this.handleChange.bind(this, k, 'memo')} onBlur={this.handleUpdate.bind(this, k, 'memo')}/></span></span>
						</div> : null}
						<div class="detail"><_linkSuper to={`manage/view/${k}`} title={atitle} target='_blank'><span class="data">ดูข้อมูล > </span></_linkSuper></div>
						</div>
					</_linkManager>
				)
			}
		)
	}*/
	render() {
		const { manager } = this.props
		let ret
		if (!manager.login) {
			ret = <Login title="ระบบจัดการข้อมูลผู้สมัคร"/>
		} else if (manager.fetch) {
			ret = <Fetching message="กำลังดึงข้อมูลจากระบบ"/>
		} else if (manager.error) {
			ret = <Error/>
		} else if (!manager.department) {
			ret = <Fetching message="กำลังดึงข้อมูลจากระบบ"/>
		} else if (manager.department.type === 'hr') {
			ret = <HR />
		} else {
			const { department } = manager
			// console.log("department", department)
			const options = {
				position: [ {
					text: 'ทุกตำแหน่ง',
					value: ''
				}, ...Object.keys(department.info).map((code) => ({
					text: department.info[ code ].status ? `${department.info[ code ].position}[${department.info[ code ].status}]` : manager.department.info[ code ].position,
					value: code
				})) ],
				interview: [
					{ text: 'ทั้งหมด', value: '' },
					{ text: 'รอประเมิน', value: 'รอประเมิน' },
					{ text: 'เรียก', value: 'เรียก' },
					{ text: 'ไม่เรียก', value: 'ไม่เรียก' },
					{ text: 'รอไว้ก่อน', value: 'รอไว้ก่อน' }
				],
				result: [
					{ text: 'ทั้งหมด', value: '' },
					{ text: 'ไม่มาสัมภาษณ์', value: 'ไม่มาสัมภาษณ์' },
					{ text: 'ไม่รับเข้าทำงาน', value: 'ไม่รับเข้าทำงาน' },
					{ text: 'รับเข้าทำงาน', value: 'รับเข้าทำงาน' },
					{ text: 'ไว้พิจารณาคราวหน้า', value: 'ไว้พิจารณาคราวหน้า' },
				]
			}
			const sorts = {
				order: [
					{ text: 'วันที่สมัคร', class: 'timestamp', value: 'วันที่สมัคร' },
					{ text: 'วันที่นัดสัมภาษณ์', class: 'appointment', value: 'วันที่นัดสัมภาษณ์' },
				],
				interview: [
					{ text: 'ทั้งหมด', class: 'all', value: '' },
					{ text: 'รอประเมิน', class: 'waiting', value: 'รอประเมิน' },
					{ text: 'เรียก', class: 'interest', value: 'เรียก' },
					{ text: 'ไม่เรียก', class: 'reject', value: 'ไม่เรียก' },
					{ text: 'รอไว้ก่อน', class: 'reserve', value: 'รอไว้ก่อน' },
					{ text: 'รอดำเนินการ',class: 'ongoing', value: 'รอดำเนินการ'}
				],
				result: [
					{ text: 'ทั้งหมด', class: 'all', value: '' },
					{ text: 'ไม่มาสัมภาษณ์', class: 'lost', value: 'ไม่มาสัมภาษณ์' },
					{ text: 'ไม่รับเข้าทำงาน', class: 'non-hired', value: 'ไม่รับเข้าทำงาน' },
					{ text: 'รับเข้าทำงาน', class: 'hired', value: 'รับเข้าทำงาน' },
					{ text: 'ไว้พิจารณาคราวหน้า', class: 'hold', value: 'ไว้พิจารณาคราวหน้า' },
				]
			}

			const classSuper = (department.type === 'super' ? "tablelike superview" : "tablelike normalview")

			ret = <div class="recruit_manage">
				<div class="header">
					<h1><img class="logo" src="/assets/recruitment/img/lnw2.png"/><span>รายชื่อผู้สมัครงาน</span></h1>
				</div>
				<div class="subheader">
					<div class="infoBar">
						<div><b>Login As</b> : {manager.login.e_mail} ({manager.department.type})</div>
						<div><b>ข้อมูลล่าสุด</b> : {/*15 ก.ค. 63 18.30 น.*/} <a class="link" onClick={this.handleFetch.bind(this)}><span class="glyphicon glyphicon-refresh"></span> โหลดอีกครั้ง</a></div>
					</div>
				</div>
				<div class={"wrapper report " + (department.type === 'super' ? "superWrapper" : "")}>
					<h3>ตำแหน่ง : {this.renderFilter('position', options)}</h3>
					<div class="filterBar">
						{this.renderFilterTool('Sort','order', sorts)}
						{this.renderFilterTool('สัมภาษณ์','interview', sorts)}
						{this.renderFilterTool('ผลสัมภาษณ์','result', sorts)}
					</div>
					<div class={classSuper}>
						<div class="applicant_row tablehead">
							<div class="timestamp">วันที่สมัคร</div>
							<div class="fullname">ชื่อ - นามสกุล</div>
							<div class="nickname">ชื่อเล่น</div>
							{department.type === 'super' ? <div class="phone">#โทรศัพท์#</div> : null}
							{department.type === 'super' ? <div class="e-mail">e-mail</div> : null}
							<div class="interview">สัมภาษณ์?</div>
							<div class="appointment">นัดสัมภาษณ์</div>
							<div class="result">ผลสัมภาษณ์</div>
							<div class="begin">วันที่เริ่มงาน</div>
							{department.type !== 'commentor' ? <div class="salary">เงินเดือน</div> : null}
							{department.type === 'super' ? <div class="memo">หมายเหตุ</div> : null}
							<div class="detail"></div>
						</div>
						{this.renderApplicantcard()}
						{/*{department.type === 'super' ? this.renderSuperApplicantcard() : this.renderApplicantcard()}*/}
					</div>
				</div>

				{/* <table>
					<thead>
					<tr>
						<th class="timestamp">{this.renderOrder('วันที่สมัคร', "timestamp")}</th>
						<th class="fullname">ชื่อ - นามสกุล</th>
						<th class="nickname">ชื่อเล่น</th>
						{department.type === 'super' ? <th class="phone">#โทรศัพท์#</th> : null}
						{department.type === 'super' ? <th class="e-mail">e-mail</th> : null}
						<th class="detail">รายละเอียด</th>
						<th class="interview">เรียกสัมภาษณ์?<br/>{this.renderFilter('interview', options)}</th>
						<th class="appointment">{this.renderOrder('นัดสัมภาษณ์', "appointment")}</th>
						<th class="result">ผลการสัมภาษณ์<br/>{this.renderFilter('result', options)}</th>
						<th class="begin">วันที่เริ่มงาน</th>
						{department.type !== 'commentor' ? <th class="salary">เงินเดือน</th> : null}
						{department.type === 'super' ? <th class="memo">หมายเหตุ</th> : null}
					</tr>
					</thead>
					<tbody>
					{this.renderApplicant()}
					</tbody>
				</table> */}
			</div>
		}
		return (<div class={styles.manage}><div class="manage-view">{ret}</div></div>)
	}
}

export default connect(state => ({
	manager: state.recruitment.manager
}), { logout, fetchManager, sortApplicant, updateApplicant, updateField })(Manage);
