import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'browser/components';

import { manageActions } from 'recruitment/common/actions';

const { logout } = manageActions;
import { connect } from 'react-redux';

import { ButtonLogout } from 'recruitment/browser/components';

class Fetching extends React.Component {

	static propTypes = {
		message: PropTypes.string,
	}

	render() {
		const { message, logout } = this.props
		return <div class="wrapper">
			<div class="fetching">
				<h2>{message}</h2>
				<div class="marquee" data-marquee="Tiny text"></div>
				<ButtonLogout label="ยกเลิก" handler={logout}/>
			</div>
		</div>
	}
}

export default connect(state => ({ manager: state.recruitment.manager }), { logout })(Fetching);