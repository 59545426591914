/* @flow */
import PropTypes from 'prop-types';
import React from 'react';
// import { Redirect, Route as ReactRouterRoute } from 'react-router';
// import { Redirect, Route as ReactRouterRoute } from 'react-router';
// import { connect } from '@lib/base/redux';

import withTracker, { GATracker } from './withTracker'


// TODO:
// const haveAccess = (viewer, authorized) => authorized ? viewer : true;

//TODO: platformDeps
// const { Redirect, Route } = (typeof IS_NATIVE != 'undefined' && IS_NATIVE) ? require('react-router-native') : require('react-router-dom')
import { getPlatformDep } from 'common/helpers/platformDeps';

const Match = ({
								 authorized,
								 component: Component,
								 render,
								 viewer,
								 baseUrl,
								 track,
								 convertPropsFunc,
								 // key,
								 ...props
							 }) => {

	const { Redirect, Route } = getPlatformDep('ReactRouter')

	// Change props name
	if (props.pattern && !props.path) {
		props.path = props.pattern
		// } else {
		//   props.pattern = props.path
	}
	if (props.exactly) props.exact = props.exactly

	// if(Component) //ga tracker
	// Component = withTracker(Component)

	const _render = render || (renderProps => {
		typeof global.router != 'undefined' && global.router.updateMatch(renderProps.match)
		if(convertPropsFunc) renderProps = convertPropsFunc(renderProps)
		renderProps.params = renderProps.match.params
		return (
			// haveAccess(viewer, authorized) ?
				render ? render(renderProps) : <React.Fragment>
					<Component {...renderProps} {...props.renderProps} />
					<GATracker {...renderProps} {...props.renderProps} track={track}/>
				</React.Fragment>

				// :
				// <Redirect
				// 	to={{
				// 		to: '/signin',
				// 		state: { from: renderProps.location },
				// 	}}
				// />
		)
	})
	return (
		<Route
			{...props}
			render={_render}
		/>
	);
}

Match.propTypes = {
	authorized: PropTypes.bool,
	component: PropTypes.func,
	render: PropTypes.func,
	viewer: PropTypes.object,
};

// Match.contextTypes = {
//   router: React.PropTypes.object.isRequired,
// };

export default Match

// TODO: user system
// export default connect(state => ({
//   viewer: state.users.viewer,
// }))(Match);
