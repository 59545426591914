/* @flow */


type Options = {
	initialState: Object,
	platformDeps?: Object,
	platformMiddleware?: Array<Function>,
};

import { _configureStore, _getStore, _getInitialState } from "common/configure/store/configureStore.lib";

const configureStore = async (options: Options) => {
	const {
		initialState
	} = options;

	const configureReducer = require('./store/configureReducer').default;
	await new Promise(resolve => setTimeout(resolve, 0)) // break long task (after require('./store/configureReducer'))
	const reducer = await configureReducer(initialState);
	return _configureStore(reducer, options)
}

const reloadReducers = async (mergeReducers = {}, name = 'reducers') => {
	const configureReducer = require('./store/configureReducer').default;
	if(mergeReducers && mergeReducers.then)
		mergeReducers = await mergeReducers
	const reducers = await configureReducer(_getInitialState() || {}, mergeReducers)
	const _store =_getStore()
	_store.replaceReducer(reducers);
	_store.dispatch({
		type: 'RELOAD_REDUCER' ,
		payload: { name, reducers: mergeReducers }
	})
	return true
}

export { configureStore as default , reloadReducers };
export * from "common/configure/store/configureStore.lib";
