/* @flow weak */

import * as actions from './actions';
import { manager } from 'recruitment/common/records/State';
// import { Map, List } from '/common/immutable';
// import {} from 'recruitment/common/helpers'
const reducer = (state, action) => {
//  console.log(action.type,state)
	switch (action.type) {
// --- [ACTION]
		case actions.CLEAR_MANAGER:
			return state.set('manager', manager)
		case actions.LOGIN:
			return state.setIn([ 'manager', 'login' ], action.payload)
		case actions.FETCH_MANAGER:
			return state
				.setIn([ 'manager', 'department' ], null)
				.setIn([ 'manager', 'error' ], null)
				.setIn([ 'manager', 'fetch' ], true)
		case actions.FETCH_MANAGER + '_SUCCESS':
			return state.setIn([ 'manager', 'fetch' ], false).setIn([ 'manager', 'department' ], action.payload)
		case actions.FETCH_MANAGER + '_ERROR':
			return state.setIn([ 'manager', 'fetch' ], false).setIn([ 'manager', 'error' ], action.payload)
		case actions.SORT_APPLICANT:
			return state.setIn([ 'manager', 'department', 'applicant' ], action.payload)
		case actions.UPDATE_APPLICANT:
			return state.setIn([ 'manager', 'department', 'applicant', action.payload.index, action.payload.field ], action.payload.value)
    case actions.UPDATE_CONTACT:
      return state.setIn([ 'manager', 'department', 'contact', action.payload.code, 'list' , action.payload.group ,action.payload.index , action.payload.field ], action.payload.value)
    case actions.CHANGE_APPOINTMENT:
			return state.setIn([ 'manager', 'department', 'contact', action.payload.code, 'list', 'รอนัดสัมภาษณ์', action.payload.key, 'appoint_date' ], action.payload.date)
		case actions.REMOVE_APPOINTMENT:
			return state.setIn([ 'manager', 'department', 'contact', action.payload.code, 'list', 'รอนัดสัมภาษณ์' ], state.manager.department.contact[ action.payload.code ].list[ 'รอนัดสัมภาษณ์' ].filter(item => item.data_row !== action.payload.data_row))
    case actions.ANNOUNCE_UPDATE + '_SUCCESS':
      //find position to set
      let arr = state.manager.department.contact[ action.payload.code ].list[ 'แจ้งผลการสัมภาษณ์' ];
      let i = 0;
      for(;i<arr.length;i++)
        if(arr[i].data_row == parseInt(action.payload.key))
          break;
      return state.setIn([ 'manager', 'department', 'contact', action.payload.code, 'list', 'แจ้งผลการสัมภาษณ์' , i, 'memo'],action.payload.value)
    case actions.DEPARTMENT_UPDATE:
      return state;
      //return state.setIn([ 'manager', 'department', 'contact', action.payload.code, 'list'],action.payload.value)
// --- [/ACTION]
	}
//  console.error('undefined case:'+action.type)
	return state;
};

export default reducer;
