/* @flow */
import React from 'react';
import View from './View'
import { pure } from 'recompose'
export const Space = pure((props: Object) => {
	return (
		<View {...props} is="span">&nbsp;</View>
	);
})
//<Base {...props} is="div" />
export default Space;
