
/* @flow */
import PropTypes from 'prop-types';

import React from 'react';
import _ from 'common/helpers/_';
// import { Helmet } from "react-helmet";
import Url from "@lib/base/Url";

import LazyLoad from 'react-lazyload'
import Util from '@lnw/util'

// TODO: Enforce height and width with invariant check.

// const Image = (props: Object) => (
//   <Base {...props} tagName="img" />
// );


// const LOADING_SRC_DEFAULT = '/assets/images/loading/preload_16x16.gif'
// const LOADING_SRC_DEFAULT = Url.asset('/images/fallback_clean.jpg')
const LOADING_SRC_DEFAULT = Url.asset('/images/loading/preload_32x32.gif')
const MISSING_SRC_DEFAULT = Url.asset('/images/no-img.jpg')

// lazy load image

// let lazyLoadInited = false
// function lazyLoadInit(){
// 	if(lazyLoadInited || isServer()) return null
// 	// import('responsively-lazy/responsivelyLazy.min.css')
// 	// import('responsively-lazy/responsivelyLazy.min.js')
// 	lazyLoadInited = true
// 	// https://github.com/ivopetkov/responsively-lazy
// 	return <Helmet>
// 		<link rel="stylesheet" href={Url.asset('/styles/responsivelyLazy.min.css')}/>,
// 		<script defer="true" src={Url.asset('/scripts/responsivelyLazy.min.js')} type="text/javascript"/>
// 	</Helmet>
// }

export class Image extends React.PureComponent {

	static propTypes = {
		src: PropTypes.string.isRequired,
		lazy: PropTypes.bool,
		loadingSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
		fallbackSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
		hoverSrc: PropTypes.string,
		imgRefCallback: PropTypes.func,
	}


	componentDidMount() {

		// if(this.props.lazy){
		// 	this.setupFallbackSrc()
		// }else{
		if (!this.setupLoadingSrc()) {
			this.setupFallbackSrc()
		}

		// console.log('lazy',this.img,this.props.lazy)

		// }
		// this.setupLoadingSrc()
		// this.setupFallbackSrc()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.loadingSrc != this.props.loadingSrc || prevProps.fallbackSrc != this.props.fallbackSrc) {
			this.imgLoaded = false
			if (!this.setupLoadingSrc()) {
				this.setupFallbackSrc()
			}
		}
	}

	setupLoadingSrc() {
		if(Util.isSafari()) return false;// bug safari 15+
		if (typeof this.props.loadingSrc !== 'undefined') {
			// Init loading src

			if (!this.imgLoaded && this.img) {
				this.img.className = this.img.className + " loading_src_image"
				this.img.src = typeof this.props.loadingSrc === 'string' ? this.props.loadingSrc : LOADING_SRC_DEFAULT;
			}

			// Take over the ng-src attribute to stop it from loading the image
			if (this.img && this.imgSrc && this.img.src !== this.imgSrc) {
				// Loading image in background
				let imgLoading = new window.Image();
				imgLoading.onload = () => {
					// replace loading img with real one
					if (this.img && this.img.src !== imgLoading.src) {
						this.imgLoaded = true
						this.img.src = imgLoading.src;
						this.img.className = imgLoading.className;
						if (imgLoading) imgLoading.onload = null;
						imgLoading = null
					}
				};
				imgLoading.onerror = () => {
					if(this.img){
						this.img.src = this.imgSrc
					}
					this.setupFallbackSrc()
				}
				imgLoading.src = this.imgSrc;
				imgLoading.className = this.imgClassSrc
			}
			return true;
		}
		return false;
	}

	setupFallbackSrc() {
		if(Util.isSafari()) return false;// bug safari 15+
		if (typeof this.props.fallbackSrc !== 'undefined') {
			if (this.img && this.imgSrc && !this.img.onerror) {
				// Fallback to default image on error
				this.img.fallbackCount = 0
				this.img.onerror = () => {
					if (this.img) {
						const fallbackSrcs = this.getFallbackSrcs()

						let fallbackSrc = null;
						if (fallbackSrcs && fallbackSrcs[this.img.fallbackCount]) {
							do {
								fallbackSrc = fallbackSrcs[this.img.fallbackCount]
								this.img.fallbackCount++
							} while (fallbackSrcs[this.img.fallbackCount] && fallbackSrcs[this.img.fallbackCount] == this.img.src)
						} else {
							fallbackSrc = fallbackSrcs
						}
						// console.log(fallbackSrc, fallbackSrcs)
						if (fallbackSrc == null || this.img.fallbackCount <= 0 || !fallbackSrcs[this.img.fallbackCount]) {
							this.img.onerror = null
						}

						if (fallbackSrc && this.img && this.img.src !== fallbackSrc) {
							this.img.src = fallbackSrc;
						}
					}
				}
				return true
			}
		}
		return false
	}

	getFallbackSrcs() {
		if (typeof this.props.fallbackSrc === 'string') return [this.props.fallbackSrc]
		if (this.props.fallbackSrc === true) return [MISSING_SRC_DEFAULT]
		if (this.props.fallbackSrc === null || this.props.fallbackSrc === false) return null
		return this.props.fallbackSrc
	}

	componentWillUnmount() {
		if (this.img) {
			// # willmake img src= '/' and load page for image
			// this.img.src = '#' // null -> will mean http://..../null
			this.img.onerror = null
			this.img = null
		}
	}

	img = null;
	imgSrc = null;
	imgClassSrc = null;
	imgLoaded = false;


	render() {
		let imgProps = _.clone(this.props)

		delete imgProps.loadingSrc
		delete imgProps.fallbackSrc
		delete imgProps.mobileSrc
		delete imgProps.hoverSrc
		delete imgProps.lazy
		delete imgProps.imgRefCallBack

		this.imgSrc = imgProps.src
		this.imgClassSrc = imgProps.className
		if(this.imgSrc == false || this.imgSrc === "false") this.imgSrc = null
		this.img = null

// 		// lazyload test
// //data-srcset="images/400.jpg 400w, images/400.webp 400w, images/600.jpg 600w, images/1000.jpg 1000w"
// 			//srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
		// TODO: fix server render bug
		// if(this.props.lazy){
		// 	// TODO:
		// 	// const Url = require('@lnw/base/Url').default;
		// 	//data-srcset={`${Url.img(this.imgSrc, 200)} 200w, ${Url.img(this.imgSrc, 300)} 300w, ${Url.img(this.imgSrc, 600)} 600w`}
		// 	// const loadingSrc = typeof this.props.loadingSrc === 'string' ? this.props.loadingSrc : LOADING_SRC_DEFAULT
		// 	// this.imgSrc = '1.jpg'
		// 	// return <React.Fragment>
		// 	// 	{lazyLoadInit()}
		// 	// 	<img ref={(img) => this.img = img}
		// 	// 			 {...imgProps} className={"responsively-lazy " + (imgProps.class || imgProps.className || '')}
		// 	// 			 src={this.imgSrc}
		// 	// 			 data-srcset={`${this.imgSrc} 1000w`}
		// 	// 			 srcSet={loadingSrc || Url.asset("/images/blank.gif")}
		// 	// 	/>
		// 	// </React.Fragment>
		//
		// 	//https://github.com/twobin/react-lazyload
		// 	return <LazyLoad once>
		// 		<img ref={(img) => this.img = img} {...imgProps} />
		// 	</LazyLoad>
		// }

		// <Base ref={(img) => { this.img = img; }} {...props} tagName="img" />

		if(this.props.lazy){ //native img attribute loading lazy
			imgProps.loading = "lazy"
		}

		if(this.props.hoverSrc){
			imgProps.onMouseOver=(e) => {
				this.img.src = this.props.hoverSrc;
				e.preventDefault(); e.stopPropagation();
			}
			imgProps.onMouseOut=(e) => {
				//e.currentTarget
				this.img.src = this.props.src;
				e.preventDefault(); e.stopPropagation();
			}
		}
		let imgRefCallBack = null
		if(this.props.imgRefCallBack) imgRefCallBack = this.props.imgRefCallBack

		if(this.props.mobileSrc){
			// TODO: more srcset feature
			//https://builtvisible.com/responsive-images-for-busy-people-a-quick-primer/
			//https://www.sitepoint.com/how-to-build-responsive-images-with-srcset/
			return <picture>
				<source media="(max-width: 750px)" srcset={this.props.mobileSrc}/>
				<img ref={(img) => { this.img = img; imgRefCallBack && imgRefCallBack(img)} }  {...imgProps} loading="lazy" />
			</picture>
		}

		return (
			/* eslint-disable jsx-a11y/img-has-alt */
			<img ref={(img) => { this.img = img; imgRefCallBack && imgRefCallBack(img)} }  {...imgProps} loading="lazy" />
		);
	}
}

Image.Lazy = (props) => <LazyLoad once height={props.height} offset={props.offset || 1000}>
	<Image {...props} lazy={true}/>
</LazyLoad>

export default Image;
