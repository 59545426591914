/* @flow weak */
// import { Map, List } from '/common/immutable';
// import {} from 'recruitment/common/helpers'
// --- [ACTION_IMPORT]
// --- [/ACTION_IMPORT]
import axios from 'axios';
axios.defaults.baseURL = 'https://recruit.lnw.co.th/api'

import * as manageActions from './manage/actions';

export {
// --- [ACTION_EXPORT]
 manageActions
// --- [/ACTION_EXPORT]
}

// --- [ACTION_CONST]
export const FETCH_POSITION = 'RECRUITMENT_FETCH_POSITION';
export const UPDATE_FIELD = 'RECRUITMENT_UPDATE_FIELD';
export const UPDATE_ANSWER = 'RECRUITMENT_UPDATE_ANSWER';
export const SUBMIT_DATA = 'RECRUITMENT_SUBMIT_DATA';
export const CLEAR_DATA = 'RECRUITMENT_CLEAR_DATA';
// --- [/ACTION_CONST]


// --- [ACTION_CREATORS]
export const fetchPosition = () => {
  return (d) => {
	d.dispatch({type: FETCH_POSITION, payload: null})
	axios.get('/position.php').then((response) => {
	  d.dispatch({type: FETCH_POSITION + '_SUCCESS', payload: response.data})
	}).catch((err) => {
	  d.dispatch({type: FETCH_POSITION + '_ERROR', payload: err.response.data})
	})
  }
}

export const updateField = (field, value) => ({
	type: UPDATE_FIELD,
	payload: {
	  field, value
	},
  });

export const updateAnswer = (key, value) => ({
	type: UPDATE_ANSWER,
	payload: {
	  key, value
	},
  });

export const submitData = (code, application, message) => {
  let data = new FormData()
  data.append('code', code)
  data.append('application', JSON.stringify(application))
  data.append('message', message)
  data.append('resume', application.resume, code + application.phone_number + ".pdf")
  console.log("before")
  return (d) => {
	axios.post('/register.php', data)
			.then((response) => {
			  d.dispatch({type: SUBMIT_DATA + '_SUCCESS', payload: response.data})
			})
			.catch((err) => {
			  console.error(err)
        if(err.response){
          d.dispatch({type: SUBMIT_DATA + '_ERROR', payload: err.response.data})
        }else{
          d.dispatch({type: SUBMIT_DATA + '_ERROR', payload: "ไม่สามารถส่งข้อมูลได้ กรุณาตรวจสอบการเชื่อมต่อกับอินเตอร์เน็ต"})
        }
			})
  }
}

export function clearData() {
  return {
	type: CLEAR_DATA,
	payload: null
  }
}

// --- [/ACTION_CREATORS]
