import PropTypes from 'prop-types';
import React from 'react';

import { GoogleLogin } from 'react-google-login-component';

import { login, fetchManager } from 'recruitment/common/manage/actions';
import { connect } from 'react-redux';

class Login extends React.Component {

	static propTypes = {
		title: PropTypes.string,
	}

	handler(googleUser) {
		const { login, manager, fetchManager } = this.props
		const profile = googleUser.getBasicProfile()
		let id = null;
		while (!id) {
			id = profile.getId();
		}
		// ที่จริงต้องเอา id_token ไปตรวจสอบใน backend (PHP) กับ  Google โดยตรง
    // const id_token = googleUser.getAuthResponse().id_token
    // login(id, profile, id_token)

    login(id, profile)

		fetchManager()
	}

	render() {
		const { title } = this.props
		return <div class="wrapper">
			<div class="login">
				<h2>{title}</h2>
				<GoogleLogin socialId="101702972156-m7c8imjgisdi9lh82catk7lbnfvjs5fb.apps.googleusercontent.com"
										 className="google-login"
										 scope="profile email"
										 responseHandler={this.handler.bind(this)}
										 buttonText={<div class="buttonText"><div class="logo"></div>เข้าสู่ระบบด้วย Google</div>} />
			</div>
		</div>
	}
}

export default connect(state => ({ manager: state.recruitment.manager }), { login, fetchManager })(Login)
