import React from 'react';

import {
	manageActions
}
	from 'recruitment/common/actions';

const { updateInterview, refuseInterview } = manageActions;
import {
	connect
}
	from 'react-redux';

class Interview extends React.Component {
	constructor(props) {
		super(props)
		this.state = { index: 0, interview: "", memo: "", sent: [] }
	}

	handleNextButton() {
		if (this.props.manager.department.contact_list.interview[ this.state.index + 1 ]) {
			this.setState({ index: this.state.index + 1, interview: "" })
		}
	}

	handlePrevButton() {
		if (this.props.manager.department.contact_list.interview[ this.state.index - 1 ]) {
			this.setState({ index: this.state.index - 1, interview: "" })
		}
	}

	submitLabel() {
		const { sent, index } = this.state
		let ret = "ส่งผลการสัมภาษณ์"
		if (sent.includes(index)) {
			ret += "ซ้ำ"
		}
		return ret
	}

	handleSubmit() {
		const { index, interview, memo } = this.state
		const { updateResult, manager } = this.props
		if (interview) {
			const data = manager.department.contact_list.interview[ index ]
			updateInterview(manager.login.google_id, data, interview, memo)
			this.setState({ sent: [ ...this.state.sent, index ] })
		}
	}

	handleRefuse(data, event) {
		const { index } = this.state
		const { refuseInterview, manager } = this.props
		refuseInterview(manager.login.google_id, data.code, data.row, false)
		this.setState({ sent: [ ...this.state.sent, index ] })
	}

	handleInterview(event) {
		this.setState({ interview: event.target.value })
	}

	handleMemo(event) {
		this.setState({ memo: event.target.value })
	}

	resultRadio(value, label) {
		return <label><input type="radio" checked={this.state.result == value} value={value}
												 onClick={this.handleInterview.bind(this)}/>{label || value}</label>
	}

	render() {
		const { result, index } = this.state
		const { department } = this.props.manager
		const { interview } = department.contact_list
		const data = interview[ index ]
		const info = department.info[ data.code ]
		return <div id="interview">
			<div id="profile">
				<h2>การสัมภาษณ์ที่ {index + 1} จาก {interview.length} </h2>
				<label>ตำแหน่ง<span>{info.position}</span></label><br/>
				<label>ชื่อ-นามสกุล<span>{data.fullname}</span></label><br/>
				<label>ชื่อเล่น<span>{data.nickname}</span></label><br/>
				<label>E-mail address<span>{data[ 'e-mail' ]}</span></label><br/>
				<label>เงินเดือนที่ต้องการ<span>{data.salary}</span></label><br/>
				<label>Resume<span><a href={data.resume} target="_blank"><img
					src="/assets/recruitment/img/Adobe_PDF_file_icon_32x32.png"/></a></span></label><br/>
			</div>
			<div id="result">
				<h2>ผลการสัมภาษณ์</h2>
				<h3>วันเวลาที่สัมภาษณ์</h3><span>{data.appoint_date}</span>
				<h3>ความคิดเห็นการตอบคำถาม</h3><span>{data.opinion}</span>
				<textarea value={this.state.interview} onChange={this.handleInterview.bind(this)}
									placeholder="ความคิดเห็นหลังการสัมภาษณ์"></textarea><br/>
				<textarea value={this.state.memo} onChange={this.handleMemo.bind(this)} placeholder="หมายเหตุ"></textarea><br/>
				<button onClick={this.handleRefuse.bind(this, data)}>ไม่มาสัมภาษณ์</button>
				<button onClick={this.handleSubmit.bind(this)}>{this.submitLabel()}</button>
				<br/>
				<button onClick={this.handlePrevButton.bind(this)}>ย้อนกลับ</button>
				<button onClick={this.handleNextButton.bind(this)}>ถัดไป</button>
			</div>
		</div>
	}
}

export default connect(state => ({ manager: state.recruitment.manager }), {
	updateInterview,
	refuseInterview
})(Interview);