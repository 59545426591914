/* @flow */
import PropTypes from 'prop-types';

import React from 'react';
import { Redirect as ReactRouterRedirect, Route as ReactRouterMatch } from 'react-router';
// import generatePath from 'react-router/generatePath';
import pathToRegexp from "@lib/base/path-to-regexp";

import { updateServerContext } from '@lnw/base/serverContext'


const Redirect = ({
										pathname, statusCode,
										...props
									}, { serverContext }) => (
	<ReactRouterMatch
		{...props}
		render={renderProps => {
			let _pathname = pathToRegexp.compile(pathname)(renderProps.match.params, {pretty: true})
			// console.log('###redirect to ', _pathname, statusCode)
			serverContext = updateServerContext({
				redirectUrl: _pathname,
				redirectUrlStatusCode: statusCode || null,
			}, serverContext)

			const split = _pathname.split("?")

			// console.log('redirectReactRouterMatch',_pathname)
			return <ReactRouterRedirect
				to={{
					pathname: split[0] || "",
					search: split[1] || null,
					state: {from: renderProps.location},
				}}
				pathname={{_pathname}}
				statusCode={statusCode}
			/>
		}}
	/>
);

Redirect.propTypes = {
	pathname: PropTypes.string.isRequired,
};

Redirect.contextTypes = {
	// serverFetchPromises: PropTypes.array,
	serverContext: PropTypes.object
};

export default Redirect
